import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const HardReloadModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Define your app version (use process.env in production)
  const APP_VERSION = '1.0.1'; // Change this for every deployment

  useEffect(() => {
    // Check local storage for the last seen version
    const savedVersion = localStorage.getItem('appVersion');

    if (savedVersion !== APP_VERSION) {
      // Version mismatch, show the modal
      setShowModal(true);
    }
  }, [APP_VERSION]);

  const handleReload = () => {
    // Update the stored version in local storage
    localStorage.setItem('appVersion', APP_VERSION);
    // Perform a hard reload
    window.location.reload();
  };

  const handleClose = () => {
    // Update the stored version even if the user closes the modal
    localStorage.setItem('appVersion', APP_VERSION);
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Hard Reload Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        A new version of the application is available. Please reload to update to the latest version.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleReload}>
          Hard Reload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default HardReloadModal;
