import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useCreateCatagoryBudgetAllocationMutation, useUpdateCatagoryBudgetAllocationMutation } from '../../../../services/BudgetApi';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import Select from "react-dropdown-select";
import { KTSVG } from '../../../../_metronic/helpers';
import TextField from '../../../../components/textFields/TextField';
import { selectAuth } from '../../../../features/authSlice';
import { useAppSelector } from '../../../../hooks';
import { useManagerUsersQuery } from '../../../../services/authApi';
import { useExpenseCategoryLookupQuery } from '../../../../services/GeneralApi';
// import Multiselect from 'multiselect-react-dropdown';
import { MultiSelect } from "react-multi-select-component";


type Props = {
    show: boolean
    handleClose: () => void
    budgetData?: any
}
type User = {
    value: string;
    label: string;
};
const AddUpdateCategoryBudgetModal = ({ show, handleClose, budgetData }: Props) => {
    const { userAccountID } = useAppSelector(selectAuth);
    const { data } = useManagerUsersQuery({ managerId: userAccountID });
    const { data: expenseCategory } = useExpenseCategoryLookupQuery('')

    const [addCategoryBudget, { data: addCategoryBudgetData, isLoading, isSuccess, isError, error }] = useCreateCatagoryBudgetAllocationMutation()
    const [updateCategoryBudget, { data: updateCategoryBudgetData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateCatagoryBudgetAllocationMutation()
    const initialValues = {
        catagoryBudgetId: 0,
        name: '',
        startDate: '',
        endDate: '',
        isActive: true,
        userAccountIds: [
            0
        ],
        catagoryBudgetAllocation: expenseCategory?.result?.map((category: any) => ({
            catagoryBudgetAllocationId: 0,
            catagoryBudgetId: 0,
            catagoryId: category.id,
            catagoryName: category.value,
            budget: 0
        })) || []
    }
    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        if (show && !budgetData && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        if (budgetData) {
            setUpdateId(budgetData?.catagoryBudgetId);
            formik.setValues({ ...budgetData });
        } else if (!show) {
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, budgetData]);

    const formik = useFormik({
        initialValues,
        // validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (!values.name) {
                toast.error("Name is required");
                return;
            } else if (!values.startDate) {
                toast.error("Start date is required");
                return;
            } else if (!values.endDate) {
                toast.error("End date is required");
                return;
            } else if (!values.userAccountIds || values.userAccountIds?.length === 0) {
                toast.error("At least one user must be selected");
                return;
            }
            // else if (!values.catagoryBudgetAllocation || values.catagoryBudgetAllocation.length === 0) {
            //     toast.error("At least one category must be specified");
            //     return;
            // } else if (!values.catagoryBudgetAllocation.some((cat: any) => cat.budget !== undefined && cat.budget !== null && cat.budget !== "")) {
            //     toast.error("Budget must be specified for at least one category");
            //     return;
            // }
            const catagoryBudgetAllocation = values.catagoryBudgetAllocation?.filter((cat: any) => cat?.budget !== undefined && cat?.budget !== "");

            // Create payload with filtered categories
            const payload = {
                ...values,
                catagoryBudgetAllocation
            };

            if (updateId) {
                updateCategoryBudget(payload)
            } else {
                addCategoryBudget({ ...payload, isActive: true })
            }
            // console.log(payload);
        }
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            const responseData = (addCategoryBudgetData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
            resetForm()
        }
    }, [isSuccess, addCategoryBudgetData]);
    useEffect(() => {

        if (updateIsSuccess) {
            const responseData = (updateCategoryBudgetData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
        }
    }, [updateIsSuccess, updateCategoryBudgetData]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);

    const [selected, setSelected] = useState<User[]>([]);

    let options: User[] = [];

    if (data?.result && Array.isArray(data.result)) {
        options = data.result.map((user: any) => ({
            value: user.id,
            label: user.value.trim(),
        }));
    }

    useEffect(() => {
        if (formik?.values?.userAccountIds && Array.isArray(formik?.values?.userAccountIds)) {
            setSelected(
                formik.values.userAccountIds
                    .map((id: any) => {
                        // console.log("Current ID:", id); // Log the current ID

                        const selectedOption = options.find((option) => {
                            return option?.value === id;
                        });

                        if (selectedOption) {
                            // console.log('selectedOption',selectedOption);

                            return {
                                value: selectedOption.value,
                                label: selectedOption.label,
                            };
                        }
                        return null; // Return null for values that don't match any option
                    })
                    .filter((option): option is User => option !== null) // Use type assertion to ensure no null values
            );
        } else {
            setSelected([]); // Set an empty array if userAccountIds is undefined or not an array
        }
    }, [options]);


    const [searchQuery, setSearchQuery] = useState('');

    // Filtered categories based on the search query
    const filteredCategories = expenseCategory?.result?.filter((category: any) =>
        category.value.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        setSelected([]);
        handleClose();
    };
    useEffect(() => {
        if (!show) {
            setSelected([]);
            setSearchQuery('')
        }
    }, [show]);

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-xl modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateCategoryBudgetAllocation}</> : <>{labelKey.addCategoryBudgetAllocation}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">

                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.name}
                                    required={true}
                                    placeholder='Enter Name'
                                    type='text'
                                    {...formik.getFieldProps('name')}
                                    value={formik.values.name || ''}
                                />
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.startDate}
                                    required={true}
                                    type='date'
                                    {...formik.getFieldProps('startDate')}
                                    value={formik.values.startDate ? moment(formik.values.startDate).format('YYYY-MM-DD') : ''}
                                    max="9999-12-31"
                                />
                            </div>
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.endDate}
                                    required={true}
                                    type='date'
                                    {...formik.getFieldProps('endDate')}
                                    value={formik.values.endDate ? moment(formik.values.endDate).format('YYYY-MM-DD') : ''}
                                    min={formik.values.startDate ? moment(formik.values.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                    max="9999-12-31"
                                />
                            </div>
                            <div className='col-md-12 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.users}</label>
                                <div className='react-multi-select'>
                                    {/* <Select
                                        options={options}
                                        multi={true}
                                        selectAll={true}
                                        value={selected}
                                        // className="multi-select"
                                        // placeholder="Select Users..."
                                        onChange={(selectedList: any) => {
                                            setSelected(selectedList);
                                            formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                        }}
                                        // value={selected.map((item) => ({ value: item.value, label: item.label }))}
                                    /> */}
                                    {/* <Multiselect
                                        options={options}
                                        selectedValues={selected}
                                        onSelect={(selectedList) => {
                                            setSelected(selectedList);
                                            formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                        }}
                                        onRemove={(selectedList) => {
                                            setSelected(selectedList);
                                            formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                        }}
                                        displayValue="label"
                                        showArrow={true}
                                        className='default-input rounded'
                                    /> */}
                                    <MultiSelect
                                        options={options}
                                        value={selected}
                                        onChange={(selectedList: any) => {
                                            setSelected(selectedList);
                                            formik.setFieldValue('userAccountIds', selectedList.map((option: any) => option.value));
                                        }}
                                        labelledBy="Select"
                                        // overrideStrings={"allItemsAreSelected": "All users are selected.",}
                                        overrideStrings={{
                                            allItemsAreSelected: "All users are selected",
                                            selectSomeItems: "Select Users...",
                                            selectAll: "Select All Users"
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextField
                                    label='Search Category'
                                    type="text"
                                    placeholder='search'
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className='table-responsive' style={{ height: '30vh' }}>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='ps-4 rounded-start'>{labelKey.categories}</th>
                                            <th className='rounded-end pe-4'>{labelKey.budget}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {filteredCategories?.length > 0 ? (
                                                    <>
                                                        {filteredCategories?.map((category: any, index: any) => (
                                                            <tr key={index}>
                                                                <td className='ps-4'>{category?.value}</td>
                                                                <td>
                                                                    <TextField
                                                                        placeholder='Enter Budget'
                                                                        type='number'
                                                                        min={0}
                                                                        fieldClass='w-50'
                                                                        value={
                                                                            formik.values.catagoryBudgetAllocation?.find(
                                                                                (cat: any) => cat?.catagoryId === category?.id
                                                                            )?.budget || ''
                                                                        }
                                                                        onChange={(e) => {
                                                                            const newBudget = e.target.value;
                                                                            const categoryId = category.id;
                                                                            // Log the changes
                                                                            // console.log('New budget:', newBudget);
                                                                            // console.log('Category ID:', categoryId);

                                                                            // Initialize newcatagoryBudgetAllocation as an array of any type
                                                                            let newcatagoryBudgetAllocation: any[] = [];
                                                                            // If catagoryBudgetAllocation is defined, assign its value, otherwise, initialize it as an empty array
                                                                            if (formik.values.catagoryBudgetAllocation) {
                                                                                newcatagoryBudgetAllocation = [...formik.values.catagoryBudgetAllocation];
                                                                            }
                                                                            // Find the index of the category
                                                                            const categoryIndex = newcatagoryBudgetAllocation.findIndex((cat: any) => cat.catagoryId === category.id);

                                                                            // Check if the category exists before updating its budget
                                                                            if (categoryIndex !== -1) {
                                                                                // Update the budget for the category at the found index
                                                                                newcatagoryBudgetAllocation[categoryIndex] = {
                                                                                    ...newcatagoryBudgetAllocation[categoryIndex],
                                                                                    budget: newBudget
                                                                                };
                                                                            } else {
                                                                                // If the category doesn't exist, create a new entry
                                                                                newcatagoryBudgetAllocation.push({
                                                                                    catagoryId: categoryId,
                                                                                    budget: newBudget
                                                                                });
                                                                            }
                                                                            // Update formik values with the modified array
                                                                            formik.setFieldValue('catagoryBudgetAllocation', newcatagoryBudgetAllocation);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>

                                                ) :

                                                    (
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                    {labelKey.noMatchingRecordsFound}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={7} className="text-center">{labelKey.loading} ....</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <Button variant='light' className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</Button>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading &&
                                !updateIsLoading &&
                                <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}
                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateCategoryBudgetModal
