import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa6'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { KTSVG } from '../../../_metronic/helpers'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../components/dateComponent/DateComp'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
import ConfirmationExpenseModal from './ConfirmationExpenseModal'
import ExpenseLogModal from './ExpenseLogModal'
import { Tooltip } from 'react-tooltip'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'

type Props = {
  show: boolean
  handleClose: () => void
  data: any
  expenseItems?: any
  isFetching?: any
}
interface LightboxData {
  imageURL: string;
}
const ExpenseDetailModal = ({ show, handleClose, data, expenseItems, isFetching }: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
  const { token, roleID, userAccountID, baseURL } = useAppSelector(selectAuth);
  const [getExpenseLog, setGetExpenseLog] = useState<any>(null); // Initialize with null
  const [showExpenseConfirmationModal, setShowExpenseConfirmationModal] = useState(false)
  const [expenseConfirmationData, setExpenseConfirmationData] = useState({});
  const isInitialRender = useRef(true);

  const fetchData = async () => {
    if (show && data?.expenseId) {
      try {
        const headers = {
          Authorization: `Bearer ${token}`, // Add your authorization token here
        };
        const getExpenseLogResponse = await axios.get(`${baseURL}/api/Expense${API_END_POINTS.getExpenselogDetail}?expenseId=${data?.expenseId}`, {
          headers, // Include the headers in the request
        });
        const getExpenseLog = getExpenseLogResponse.data;
        // Set the fetched data to the state
        setGetExpenseLog(getExpenseLog);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }
  useEffect(() => {
    if (isInitialRender.current) {
      fetchData();
    } else {
      isInitialRender.current = false;
    }
    // if (showExpenseConfirmationModal === false) {
    //   fetchData();
    // }
  }, [show, data?.expenseId, token]); // Include token in the dependency array


  const isPdfFile = (filePath: string | undefined) => {
    if (!filePath) return false;
    const fileExtension = filePath.toLowerCase().split('.').pop();
    return fileExtension === 'pdf';
  };
  const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
    if (attachmentFile) {
      if (isPdfFile(attachmentFile)) {
        // For PDF files, open in the browser
        window.open(attachmentFile, '_blank');
      } else {
        // For image files, open in the Lightbox
        setLightboxData({ imageURL: attachmentFile });
        setLightboxOpen(true);
      }
    }
  };


  const expenseConfirmationModalHandler = () => {
    setShowExpenseConfirmationModal((prev) => !prev);
  };
  const handleCloseModals = () => {
    setShowExpenseConfirmationModal(false);
    handleClose(); // Close the parent modal
  };
  const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
  const [expenseLogData, setExpenseLogData] = useState({});
  const expenseLogHandler = () => {
    setShowExpenseLogModal((prev) => !prev);
  };

  const isDarkGreen = data?.expenseStatus?.id === constraintConfig.expenseStatus.apApproved;

  return (
    <>
      <ConfirmationExpenseModal show={showExpenseConfirmationModal}
        handleClose={() => setShowExpenseConfirmationModal(false)}
        data={expenseConfirmationData}
        handleCloseModals={handleCloseModals} />
      <ExpenseLogModal show={showExpenseLogModal}
        handleClose={() => setShowExpenseLogModal(false)}
        data={expenseLogData} />

      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog custom-modal-size modal-dialog-centered'
        show={show}
        onHide={handleClose}
      >
        <style>
          {` .inner-circle.dark-green:before {
             background-color: #60e08e !important;
           }
           .inner-circle.dark-danger:before {
            background-color: #ff003e !important;
          }
          .inner-circle.bg-warning:before {
            background-color: #f0ad4e !important;
          }
            .timeline-step:not(:last-child):after,
            .timeline-steps .timeline-step:not(:first-child):before
           {    border-top: 0.25rem dotted #60e08e !important;
           }
           `}
        </style>
        <div className='modal-header py-4'>
          <h2 className='mb-0'>{labelKey.expenseDetails} - ({data?.title})</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
                {/* <div className="timeline-step">
                  <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="" data-original-title="2003">
                    <div className={`inner-circle dark-green `}
                    ></div>
                    <p className="h6 mt-3 mb-1">{labelKey.submitted}</p>
                  </div>
                </div> */}
                {getExpenseLog?.result?.map((logData: any, index: any) => (
                  <div className="timeline-step" key={index}>
                    <div className="timeline-content" data-toggle="popover" data-trigger="hover" data-placement="top" title="" data-content="" data-original-title="2005">
                      {/* <div className={`inner-circle 
                      ${data?.expenseStatus?.id === constraintConfig.expenseStatus.pendingForApproval ? "dark-green" :
                          data?.expenseStatus?.id === constraintConfig.expenseStatus.clarification ? "dark-green" :
                            data?.expenseStatus?.id === constraintConfig.expenseStatus.approved ? "dark-green" :
                              data?.expenseStatus?.id === constraintConfig.expenseStatus.rejected ? "dark-green" :
                                isDarkGreen ? "dark-green" :
                                  ""}
                            `}></div> */}
                      <div className={`inner-circle 
                      ${logData?.status?.id === constraintConfig.approverStatus.submitted ? "dark-green" :
                          logData?.status?.id === constraintConfig.approverStatus.pending ? "" :
                            logData?.status?.id === constraintConfig.approverStatus.userRecall ? "" :
                              // logData?.status?.title === "Clarification" ? "bg-warning" :
                              logData?.status?.id === constraintConfig.approverStatus.approved ? "dark-green" :
                                logData?.status?.id === constraintConfig.approverStatus.rejected ? "dark-danger" :
                                  logData?.status?.id === constraintConfig.approverStatus.accountsPayable ? "dark-green" :
                                    isDarkGreen ? "dark-green" :
                                      ""}
                            `}></div>
                      <p className="h6 mt-3 mb-1">
                        {logData?.status?.id === constraintConfig.approverStatus.submitted ? <>{logData?.status?.title}</> :
                          logData?.status?.id === constraintConfig.approverStatus.pending ? <>{logData?.status?.title}</> :
                            logData?.status?.id === constraintConfig.approverStatus.userRecall ? <>{logData?.status?.title}</> :

                              // logData?.status?.title === "Clarification" ? <>{logData?.status?.title}</> :
                              logData?.status?.id === constraintConfig.approverStatus.approved ? <>{logData?.status?.title}</> :
                                logData?.status?.id === constraintConfig.approverStatus.rejected ? <>{logData?.status?.title}</> :
                                  logData?.status?.id === constraintConfig.approverStatus.accountsPayable ? <>{labelKey.accountsPayable}</> : ""
                        }
                        <p>[{logData?.approver?.firstName} {logData?.approver?.middleName} {logData?.approver?.lastName}]</p>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-9">
              {(roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) && (
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-50px me-5'>
                    <div className="symbol-label fs-3 bg-light-info text-info text-capitalize"
                    >
                      {data?.userAccount?.firstName.charAt(0)}
                    </div>

                  </div>
                  <div className='d-flex justify-content-start flex-column'>
                    <p className='text-dark fw-bold mb-0 fs-6 text-capitalize'>
                      {data?.userAccount?.firstName} {data?.userAccount?.lastName}
                    </p>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {data?.userAccount?.email}
                    </span>
                  </div>
                </div>
              )}
              <div>
                <p className='fw-bold mt-3 mb-1'>{labelKey.reportId}: <span className='fw-semibold'>{data?.trackingId}</span></p>
                <p className='fw-bold'>{labelKey.totalAmount}: <span className='fw-semibold'>
                  <CurrencyComp amount={data?.amount} /></span></p>
              </div>
            </div>

            <div className="col-md-3">
              <p className='fw-bold'>{labelKey.month}: <span className='fw-semibold'>
                <DateComp formattedDate={data?.reportDate} /></span></p>
              <p className='fw-bold'>{labelKey.status}: {data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted ?
                <span className="badge badge-light-primary fs-7 fw-semibold">{labelKey.draft}</span> :
                data?.expenseStatus?.id === constraintConfig.expenseStatus.pendingForApproval ?
                  <span className="badge badge-light-info fs-7 fw-semibold">{labelKey.pendingApproval}</span> :
                  // data?.expenseStatus?.id === constraintConfig.expenseStatus.clarification ?
                  //   <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                  data?.expenseStatus?.id === constraintConfig.expenseStatus.approved ?
                    <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.managerApproved}</span> :
                    data?.expenseStatus?.id === constraintConfig.expenseStatus.rejected ?
                      <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.rejected}</span> :
                      data?.expenseStatus?.id === constraintConfig.expenseStatus.apApproved ?
                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.aPApproved}</span> : ""
              }</p>

            </div>

            <div className="col-md-8 mt-3">
              <p className='fw-bold mb-0'>{labelKey.description}:</p>
              <p className='mh-100px overflow-auto'>{data?.expenseDetail}</p>
            </div>

            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr>
                    <th className='ps-4 rounded-start w-50px'>{labelKey.sr}</th>
                    <th>{labelKey.category}</th>
                    <th>{labelKey.currency}</th>
                    <th className='text-end'>{labelKey.amount}</th>
                    <th className='w-150px text-end'>{labelKey.reimbursableDistance}</th>
                    <th>{labelKey.vendor}</th>
                    <th>{labelKey.paymentMethod}</th>
                    <th className='w-100px'>{labelKey.itemDate}	</th>
                    <th>{labelKey.chargeLocation}</th>
                    <th className='w-100px'>{labelKey.attachment}</th>
                    <th>{labelKey.source}</th>
                    <th className='w-200px rounded-end'>{labelKey.description}</th>
                  </tr>
                </thead>
                <tbody>
                  {isFetching ?
                    <tr>
                      <td colSpan={22}>
                        <LoadingComponent />
                      </td>
                    </tr>
                    :
                    <>
                      {expenseItems?.map((itemData: any, index: any) => (
                        <>
                          <tr key={index}>
                            <td className=' ps-4 w-50px'>
                              {index + 1}
                            </td>
                            <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                            <td>{itemData?.currency?.title}</td>
                            <td className='text-end'> <CurrencyComp amount={itemData?.amount} /></td>
                            <td className='text-end'>
                              {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                <>
                                  {itemData?.reimbursableDistance}
                                </>
                                : null
                              }
                            </td>
                            <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                            <td>{itemData?.paymentMethod?.title}</td>
                            <td><DateComp formattedDate={itemData.itemDate} /></td>
                            <td>{itemData?.expItemLocation?.title ? itemData?.expItemLocation?.title : <span>N/A</span>}</td>
                            <td className="text-center">
                              {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null ?
                                <span>N/A</span> :
                                <FaEye onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                              }
                              {lightboxOpen && (
                                <Lightbox
                                  mainSrc={lightboxData.imageURL}
                                  onCloseRequest={() => setLightboxOpen(false)}
                                  imageCaption="Attachment"
                                  enableZoom={true}
                                  imagePadding={50}
                                />
                              )}
                            </td>
                            <td>
                              <Tooltip id="web-source" place="top" />
                              <Tooltip id="mobile-source" place="top" />
                              <Tooltip id="bank-source" place="top" />
                              {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                  svgClassName='w-20px h-20px'
                                  data-tooltip-id="web-source" data-tooltip-content='Website' />
                                :
                                itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                  <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                    data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                  itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                    <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                      data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                    : null}
                            </td>
                            <td>
                              <div className='mh-100px overflow-auto text-wrap'>
                                {itemData?.itemDetail}
                              </div>
                            </td>
                          </tr>
                          {itemData.splitLineItems && itemData.splitLineItems.map((splitItem: any, splitIndex: any) => (
                            <>

                              {splitIndex === 0 && splitItem.amount > 0 && (
                                <tr className="p-2">
                                  <td></td>
                                  <td className="text-start fw-bold bg-light" colSpan={2}>{labelKey.splitLocations} ( {itemData.splitLineItems.length} )</td>
                                  <td className="text-end fw-bold bg-light pe-5">{labelKey.splitAmount} </td>
                                  {/* <td className="text-start fw-bold bg-light" colSpan={11}></td> */}
                                </tr>
                              )}
                              {splitItem.amount > 0 &&
                                <tr key={`${index}-${splitIndex}`} className="split-item-row">
                                  <td></td>
                                  <td className="text-start bg-light" colSpan={2}>
                                    {splitItem.expItemLocation?.title ? splitItem.expItemLocation.title : <span>N/A</span>}
                                  </td>
                                  <td className="text-end bg-light pe-5">
                                    <CurrencyComp amount={splitItem.amount} />
                                  </td>
                                  {/* <td className="text-end bg-light" colSpan={11}></td> */}
                                </tr>
                              }
                            </>
                          ))}
                        </>

                      ))}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='modal-footer py-4'>
          <button className='btn btn-light' onClick={handleClose}>{labelKey.close}</button>
          {(data?.expenseStatus?.id !== constraintConfig.expenseStatus.drafted &&
            <button className='btn btn-light-primary'
              onClick={() => {
                setExpenseLogData(data);
                expenseLogHandler();
              }}>{labelKey.expenseLogs}</button>
          )}
          {data?.userAccount?.orgUserID === userAccountID && <>
            {(data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted ||
              data?.expenseStatus?.id === constraintConfig.expenseStatus.rejected)
              && (
                <button className='btn  btn-primary'
                  onClick={() => {
                    setExpenseConfirmationData(data?.expenseId);
                    expenseConfirmationModalHandler();
                  }}
                >{labelKey.submitForApproval}</button>
              )}
          </>}
        </div>
      </Modal >
    </>
  )
}

export default ExpenseDetailModal