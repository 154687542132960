import moment from 'moment';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { BiRefresh } from 'react-icons/bi';
import { FaCheck, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';
import { IoIosRepeat } from 'react-icons/io';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../..//_metronic/helpers';
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import DateTimeComp from '../../../components/dateComponent/DateTimeComp';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import { constraintConfig } from '../../../constraintConfig';
import { useLazyGetExpenseForReportQuery, useLazyGetExpenseItemByExpenseIdQuery, useLazyGetExpenseItemsByExpenseItemIdQuery } from '../../../services/ExpenseApi';
import { useGetAllDraftInvoicesQuery } from '../../../services/InvoiceDataDraftApi';
import labelKey from '../../localization/label.json';
import ExpenseDetailModal from '../expenseItems/ExpenseDetailModal';
import { UpdateExpenseItemModal } from '../expenseItems/update-single-Item/UpdateExpenseItemModal';
import { timePeriodOptions } from '../reportAnalytics/ReportFilterOptions';
import AddInvoiceModal from './AddInvoiceModal';
import DeleteInvoiceModal from './DeleteInvoiceModal';
import RetryInvoiceModal from './RetryInvoiceModal';
import DateRangeComp from '../../../components/dateComponent/DateRangeComp';

interface LightboxData {
    imageURL: string;
}

const Invoices = () => {
    const currentDate = moment().format('MM/DD/YYYY');
    const threeDaysAgo = moment().subtract(3, 'days').format('MM/DD/YYYY');
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [filterByStatus, setFilterByStatus] = useState(0);
    const [filterByStartDate, setFilterByStartDate] = useState(threeDaysAgo);
    const [filterByEndDate, setFilterByEndDate] = useState(currentDate);
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');

    const [triggerItemsById, { data: itemsByIdData }] = useLazyGetExpenseItemByExpenseIdQuery()
    const [triggerexpenseReportData, { data: expenseReportData }] = useLazyGetExpenseForReportQuery()
    const [triggerItemById, { data: itemByIdData }] = useLazyGetExpenseItemsByExpenseItemIdQuery()

    const { data, isLoading, refetch } = useGetAllDraftInvoicesQuery({
        filterByStatus: filterByStatus || 0,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        timePeriod: filterByTimePeriod
    });


    useEffect(() => {
        const interval = setInterval(() => {
            // Check if any item's ocrReadStatus is 0
            if (data?.result?.some((item: any) => item.ocrReadStatus === 0)) {
                refetch();
            }
        }, 15000); // 15000ms = 15 seconds

        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [data, refetch]);


    const [showAddUpdateDepartmentModal, setShowAddUpdateDepartmentModal] = useState(false)
    const [showDepartmentEdit, setShowDepartmentEdit] = useState({})
    const departmentModalHandler = () => {
        setShowAddUpdateDepartmentModal((prev) => !prev);
    };
    const [showAttachmentDeleteModal, setShowAttachmentDeleteModal] = useState(false)
    const [attachmentDeleteData, setAttachmentDeleteData] = useState<number | null>(null);

    const deleteHandler = () => {
        setShowAttachmentDeleteModal((prev) => !prev);
    };
    const [showRetryInvoiceAttachmentModal, setShowRetryInvoiceAttachmentModal] = useState(false)
    const retryInvoiceHandler = () => {
        setShowRetryInvoiceAttachmentModal((prev) => !prev);
    };

    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)

    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };
    const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)
    const expenseItemModalHandler = () => {
        setShowUpdateExpenseItemModal((prev) => !prev);
    };

    const [prevModalState, setPrevModalState] = useState({
        addUpdateDepartmentModal: false,
        showAttachmentDeleteModal: false,
        showRetryInvoiceAttachmentModal: false,
        showExpenseDetailModal: false,
        showUpdateExpenseItemModal: false
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            addUpdateDepartmentModal: showAddUpdateDepartmentModal,
            showAttachmentDeleteModal: showAttachmentDeleteModal,
            showRetryInvoiceAttachmentModal: showRetryInvoiceAttachmentModal,
            showExpenseDetailModal: showExpenseDetailModal,
            showUpdateExpenseItemModal: showUpdateExpenseItemModal
        });
    }, [showAddUpdateDepartmentModal, showAttachmentDeleteModal, showRetryInvoiceAttachmentModal, showExpenseDetailModal, showUpdateExpenseItemModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.addUpdateDepartmentModal && !showAddUpdateDepartmentModal) ||
            (prevModalState.showAttachmentDeleteModal && !showAttachmentDeleteModal) ||
            (prevModalState.showRetryInvoiceAttachmentModal && !showRetryInvoiceAttachmentModal) ||
            (prevModalState.showExpenseDetailModal && !showExpenseDetailModal) ||
            (prevModalState.showUpdateExpenseItemModal && !showUpdateExpenseItemModal)
        ) {
            refetch();
        }
    }, [
        showAddUpdateDepartmentModal,
        showAttachmentDeleteModal,
        showRetryInvoiceAttachmentModal,
        showExpenseDetailModal,
        showUpdateExpenseItemModal,
        prevModalState.addUpdateDepartmentModal,
        prevModalState.showAttachmentDeleteModal,
        prevModalState.showRetryInvoiceAttachmentModal,
        prevModalState.showExpenseDetailModal,
        prevModalState.showUpdateExpenseItemModal,
        refetch,
    ]);
    const handleRefresh = () => {
        refetch()
    };

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setFilterByStartDate(startDateFormatted);
        setFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        // setLocalFilterByStartDate("");
        // setLocalFilterByEndDate("");
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setFilterByTimePeriod(selectedOption.value);
        setFilterByStartDate('')
        setFilterByEndDate('')
    };


    const [activeTab, setActiveTab] = useState(0);
    const handleTabChange = (status: number) => {
        setActiveTab(status);
        setFilterByStatus(status);
    };
    useEffect(() => {
        if (activeTab) {
            refetch();
        }
    }, [activeTab]);


    return (
        <>
            <ExpenseDetailModal
                show={showExpenseDetailModal}
                handleClose={() => setShowExpenseDetailModal(false)}
                data={expenseReportData?.result}
                expenseItems={itemsByIdData?.result} />
            <UpdateExpenseItemModal
                show={showUpdateExpenseItemModal}
                handleClose={() => setShowUpdateExpenseItemModal(false)}
                data={itemByIdData?.result}
                viewOnlyData={true} />
            <DeleteInvoiceModal
                show={showAttachmentDeleteModal}
                handleClose={() => setShowAttachmentDeleteModal(false)}
                data={attachmentDeleteData}
            />
            <RetryInvoiceModal
                show={showRetryInvoiceAttachmentModal}
                handleClose={() => setShowRetryInvoiceAttachmentModal(false)}
                data={attachmentDeleteData}
            />
            <AddInvoiceModal
                show={showAddUpdateDepartmentModal}
                handleClose={() => setShowAddUpdateDepartmentModal(false)}
                data={showDepartmentEdit} />
            <Tooltip id="refreshData" place="top" />
            <Tooltip id="retryInvoice" place="top" />
            <Tooltip id="reason" place="top" />
            <Tooltip id="reportId" place="top" />
            <Tooltip id="lineItem" place="top" />


            {lightboxOpen && (
                <Lightbox
                    mainSrc={lightboxData.imageURL}
                    onCloseRequest={() => setLightboxOpen(false)}
                    imageCaption="Attachment"
                    enableZoom={true}
                    imagePadding={50}
                />
            )}

            <div className='card mb-5'>
                <div className='card-header border-0 pt-6 align-items-center'>
                    <div className='d-flex align-items-center gap-2 overflow-auto'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content" }}>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 0 ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(0)}
                            >
                                {labelKey.all} (Except Attached & Sumitted)
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.readyToUse ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.readyToUse)}
                            >
                                {labelKey.readyToUse}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.attached ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.attached)}
                            >
                                {labelKey.attached}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.submitted ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.submitted)}
                            >
                                {labelKey.submitted}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.failed ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.failed)}
                            >
                                {labelKey.failed}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.alreadyUsed ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.alreadyUsed)}
                            >
                                {labelKey.alreadyUsed}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.futureReceipt ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.futureReceipt)}
                            >
                                {labelKey.futureReceipt}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === constraintConfig.invoiceStatus.all ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange(constraintConfig.invoiceStatus.all)}
                            >
                                {labelKey.all}
                            </button>

                        </div>
                        <Tooltip id="refreshData" place="top" />
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>
                    {/* <div
                        data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                        <IoIosRepeat className='h-20px w-20px' />
                    </div> */}
                    <div className='d-flex align-items-end ms-auto gap-2'>
                        {/* <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <label className='form-label fs-6 fw-bold text-dark'>{labelKey.dateRange}:</label>
                                <div className='position-relative mb-2'>
                                    <label className='position-absolute text-primary text-wrap'>Custom Date Range</label>
                                    <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                        <input type="text"
                                            className='h-10px mw-100px opacity-0 cursor-pointer'
                                            value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "mm/dd/yyyy"} />
                                    </DateRangePicker>
                                </div>
                            </div>
                            <Select
                                // customLabelClick={() => setDateRangeHide(true)}
                                options={[
                                    // { value: 0, label: 'All' },
                                    ...(timePeriodOptions?.map((option: any) => ({
                                        value: option.id,
                                        label: option?.value,
                                    })))
                                ]}
                                placeholder={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : 'Select Date'}
                                value={filterByTimePeriod && filterByTimePeriod !== ''
                                    ? {
                                        label: timePeriodOptions?.find((option: any) => option.id === filterByTimePeriod)?.value || '',
                                        value: filterByTimePeriod,
                                    }
                                    : null
                                }
                                onChange={handleTimePeriodChange}
                                className='w-250px'
                            />
                        </div> */}
                        <DateRangeComp
                            startDate={filterByStartDate || moment()}
                            endDate={filterByEndDate || moment()}
                            onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                            value={filterByStartDate && filterByEndDate ? `${filterByStartDate} - ${filterByEndDate}` : "MM/DD/YYYY"} />
                        <div className='card-toolbar mb-0'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowDepartmentEdit(true);
                                    departmentModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addReceipt}
                            </button>
                        </div>
                    </div>
                </div>


                <div className='card-body py-8'>
                    <div className="row" style={{ minHeight: "80vh" }}>
                        {!isLoading && (
                            <>
                                {data?.result?.length > 0 ? (
                                    <>
                                        {data?.result?.map((item: any, index: number) => (
                                            <div className="col-md-6 col-lg-4 col-xl-3 mt-4" key={index}>
                                                <div className="card card-xl-stretch mb-xl-8" style={{ border: "1px solid #cccccc" }}>
                                                    <div className='position-relative' style={{ height: 450 }}>
                                                        <div className='position-absolute my-3 mx-3 z-index-1' style={{ left: 0, bottom: 0 }}>
                                                            {item.ocrReadStatus === 0 ?
                                                                <span className="btn btn-sm btn-primary fs-7 fw-semibold w-max-content">{labelKey.processing} </span>
                                                                : item.ocrReadStatus === 1 ?
                                                                    <span className="btn btn-sm btn-success fs-7 fw-semibold w-max-content">{labelKey.readyToUse} <FaCheck className='ms-2' /> </span>
                                                                    : item.ocrReadStatus === 2 ?
                                                                        <span className="btn btn-sm btn-danger fs-7 fw-semibold w-max-content">{labelKey.failed} <FaExclamationTriangle className='ms-2' /> </span>
                                                                        : item.ocrReadStatus === 3 ?
                                                                            <span className="btn btn-sm btn-secondary fs-7 fw-bold w-max-content">{labelKey.attached}</span>
                                                                            : item.ocrReadStatus === 4 ?
                                                                                <span className="btn btn-sm btn-success fs-7 fw-bold w-max-content">{labelKey.submitted}</span>
                                                                                : item.ocrReadStatus === 5 ?
                                                                                    <span className="btn btn-sm btn-warning fs-7 fw-bold w-max-content">{labelKey.alreadyUsed} </span>
                                                                                    : item.ocrReadStatus === 6 ?
                                                                                        <span className="btn btn-sm btn-info fs-7 fw-bold w-max-content">{labelKey.futureReceipt} </span>
                                                                                        : item.ocrReadStatus === 7 ?
                                                                                            <span className="btn btn-sm btn-info fs-7 fw-bold w-max-content">{labelKey.cCReceipt} </span>
                                                                                            : null
                                                            }
                                                        </div>
                                                        {item.ocrReadStatus !== 3 && item.ocrReadStatus !== 4 ?
                                                            <div
                                                                className='btn btn-icon btn-bg-light hover-danger btn-sm position-absolute m-4'
                                                                style={{ left: 0, zIndex: 12, top: 6 }}
                                                                onClick={() => {
                                                                    setAttachmentDeleteData(item?.invoiceDataDraftId)
                                                                    deleteHandler();
                                                                }}
                                                            >
                                                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' svgClassName='svg-danger' />
                                                            </div>
                                                            : null}
                                                        {item?.noOfRetry <= 2 ?
                                                            <>
                                                                {item?.ocrReadStatus === 2 &&
                                                                    <div
                                                                        data-tooltip-id="retryInvoice" data-tooltip-content='Retry Invoice'
                                                                        className='btn btn-icon btn-bg-light btn-sm position-absolute m-4'
                                                                        style={{ right: 0, zIndex: 12, top: 6 }}
                                                                        onClick={() => {
                                                                            setAttachmentDeleteData(item?.invoiceDataDraftId)
                                                                            retryInvoiceHandler();
                                                                        }}
                                                                    >
                                                                        <BiRefresh className='h-15px w-15px' />
                                                                    </div>
                                                                }
                                                            </>
                                                            : null}
                                                        {item.fileURL.endsWith('.pdf') ? (
                                                            <div
                                                                onClick={() => openPdfInBrowserOrLightbox(item?.fileURL)}
                                                                style={{ cursor: "pointer", position: "relative", width: "100%", height: "100%" }}
                                                            >
                                                                <embed
                                                                    src={`${item.fileURL}#toolbar=0&navpanes=0&scrollbar=0`}
                                                                    type="application/pdf"
                                                                    width="100%"
                                                                    height="450px"
                                                                    className="cursor-pointer"
                                                                    onClick={() => openPdfInBrowserOrLightbox(item?.fileURL)}
                                                                />
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: "96%",
                                                                        height: "100%",
                                                                        backgroundColor: "transparent",
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        ) :
                                                            <img className="card-img-top w-100 cursor-pointer" src={item.fileURL}
                                                                height={450}
                                                                // onClick={() => openPdfInBrowserOrLightbox(index)}
                                                                onClick={() => openPdfInBrowserOrLightbox(item?.fileURL)}
                                                                onError={(e) => {
                                                                    e.currentTarget.onerror = null; // prevent looping
                                                                    e.currentTarget.src = 'media/svg/files/blank-image.svg'; // Path to your default image
                                                                }}
                                                                alt="Card image cap" />
                                                        }

                                                    </div>
                                                    <div className="card-body">
                                                        <div className='d-flex align-items-start gap-2 mt-1'>
                                                            <h5 className="card-title fs-6 m-0 w-150px">{labelKey.invoiceNo}:</h5>
                                                            <p className='mb-0'> {item.invoiceNo ? item.invoiceNo : "N/A"}</p>
                                                        </div>
                                                        {item.ocrReadStatus === 3 && <>
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <h5 className="card-title fs-6 m-0 w-150px d-flex align-items-center">{labelKey.reportId}:
                                                                    {item.trackingId &&
                                                                        <FaInfoCircle className='ms-2 text-gray-600 cursor-pointer'
                                                                            data-tooltip-id="reportId" data-tooltip-content='Report ID is clickable'
                                                                        />
                                                                    }
                                                                </h5>
                                                                <p className='mb-0' > {item.trackingId ?
                                                                    <span className='text-primary cursor-pointer'
                                                                        onClick={() => {
                                                                            expenseDetailHandler();
                                                                            triggerexpenseReportData({ expenseId: item?.expenseId })
                                                                            triggerItemsById({ ExpenseId: item?.expenseId })
                                                                        }}
                                                                    >{item.trackingId}</span>
                                                                    : "N/A"}
                                                                </p>
                                                            </div>
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <h5 className="card-title fs-6 m-0 w-150px d-flex align-items-center">{labelKey.lineItemCategory}:
                                                                    {item.category &&
                                                                        <FaInfoCircle className='ms-2 text-gray-600 cursor-pointer'
                                                                            data-tooltip-id="lineItem" data-tooltip-content='Line Item Category is clickable'
                                                                        />
                                                                    }
                                                                </h5>
                                                                <p className='mb-0'> {item.category ?
                                                                    <span className='text-primary cursor-pointer'
                                                                        onClick={() => {
                                                                            expenseItemModalHandler();
                                                                            triggerItemById({ expenseItemId: item?.expenseItemId })
                                                                        }}>{item.category}</span> : "N/A"}</p>
                                                            </div>
                                                        </>}
                                                        {item.ocrReadStatus !== 5 && <>
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <h5 className="card-title fs-6 m-0 w-150px">{labelKey.amount}:</h5>
                                                                <p className='mb-0'> <CurrencyComp amount={item.amount} /></p>
                                                            </div>
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <h5 className="card-title fs-6 m-0 w-150px">{labelKey.dateAndTime}:</h5>
                                                                {item.ocrReadStatus !== 0 &&
                                                                    <p className='mb-0'> {moment(item.invoiceDate).format('MM-DD-YYYY hh:mm A')}</p>
                                                                }
                                                            </div>
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <div>
                                                                    <h5 className="card-title fs-6 m-0 w-150px">{labelKey.vendor}:</h5>
                                                                </div>
                                                                <p className='mb-0'> {item.vendorName}</p>
                                                            </div>
                                                        </>}
                                                        <div className='d-flex align-items-start gap-2 mt-1'>
                                                            <h5 className="card-title fs-6 m-0 w-150px">{labelKey.uploadedFrom}:</h5>
                                                            <span className='mb-0'>
                                                                {item.recordSourceID === constraintConfig.recordSourceId.WebApp ?
                                                                    <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 svg-gray'
                                                                        data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                    : item.recordSourceID === constraintConfig.recordSourceId.Mobile ?
                                                                        <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 svg-gray'
                                                                            data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> : null}
                                                            </span>
                                                        </div>

                                                        <div className='d-flex align-items-center gap-2 mt-1'>
                                                            <h5 className="card-title fs-6 m-0 w-150px">{labelKey.uploadedDateAndTime}:</h5>
                                                            <p className='mb-0'><DateTimeComp formattedDate={item.createdDT} /> </p>
                                                        </div>
                                                        {item.remarks &&
                                                            <div className='d-flex align-items-start gap-2 mt-1'>
                                                                <div>
                                                                    <h5 className="card-title fs-6 m-0 w-150px d-flex align-items-center">{labelKey.reason}:
                                                                        {item.ocrReadStatus === 5 &&
                                                                            <FaInfoCircle className='ms-2 text-gray-600 cursor-pointer'
                                                                                data-tooltip-id="reason" data-tooltip-content='Reason is clickable'
                                                                            />
                                                                        }
                                                                    </h5>
                                                                </div>
                                                                {item.ocrReadStatus === 5 ?
                                                                    <p className='mb-0 text-primary cursor-pointer'
                                                                        onClick={() => {
                                                                            expenseItemModalHandler();
                                                                            triggerItemById({ expenseItemId: item?.usedExpenseItemId })
                                                                        }}
                                                                    > {item.remarks ? item.remarks : "N/A"}</p>
                                                                    : <p className='mb-0' > {item.remarks ? item.remarks : "N/A"}</p>}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : (
                                    <NoRecordFound />
                                )}
                            </>
                        )}
                        {isLoading && (
                            <LoadingComponent />
                        )}
                    </div>
                </div>
            </div >
        </>
    );
}

export default Invoices;
