/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { KTSVG } from '../../../../_metronic/helpers'
import { useCreateWorkflowMutation } from '../../../../services/WorkFlowApi'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step5 } from './steps/Step5'


type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateworkflowModal = ({ show, handleClose }: Props) => {
  const [currentStep, setCurrentStep] = useState(1); // State to manage the current step
  const [addWorkflow, {data:addData, isLoading, isError, error, isSuccess }] = useCreateWorkflowMutation();
  const handleNext = () => {
    if (currentStep === 1) {
      if (!formik.values?.workflowPolicyType.id) {
        toast.error(tosterKey.approverPolicyRequired);
        return;
      }
      else if (!formik.values?.workflowName) {
        toast.error(tosterKey.workflowNameRequired);
        return;
      }
      else if (String(formik.values?.workflowPolicyType.id) === '2') {
        if (!formik.values?.userAccountIds[0]) {
          toast.error(tosterKey.userIsRequired);
          return;
        }
      }
    }
    else if (currentStep === 2) {
      const step2Valid = validateStep2Data();
      if (!step2Valid) {
        return; // Validation failed, return early
      }
    }
    setCurrentStep((prevStep) => prevStep + 1); // Move to the next step
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1); // Move to the previous step
  };

  const currentDate = new Date();
  const initialValues = {
    workflowId: 0,
    workflowPolicyType: {
      id: 1,
      value: ''
    },
    workflowName: '',
    status: true,
    startdate: currentDate,
    endDate: '2099-12-31',
    userAccountIds: [
      0
    ],
    workflowApprover: [
      {
        workflowApproverId: 0,
        workflowId: 0,
        minimumAmount: 0,
        maximumAmount: 0,
        userAccount: {
          orgUserID: 0,
          firstName: '',
          middleName: '',
          lastName: '',
          email: '',
          imageUrl: ''
        },
        sequence: 1
      }
    ]
  }


  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      // const step2Valid = validateStep2Data();
      // if (!step2Valid) {
      //   return; // Validation failed, return early
      // }
      // else {
      //   addWorkflow({ ...values })
      // }
      if (currentStep === 1) {
        if (!formik.values?.workflowPolicyType.id) {
          toast.error(tosterKey.approverPolicyRequired);
          return;
        }
        else if (!formik.values?.workflowName) {
          toast.error(tosterKey.workflowNameRequired);
          return;
        }
        else {
          addWorkflow({ ...values });
        }
      } else {
        addWorkflow({ ...values });
      }

    },
  })

  const { resetForm } = formik;

  useEffect(() => {
    if (isSuccess) {
      const responseData = (addData as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      handleClose();
      resetForm()
    }
  }, [isSuccess, addData]);


  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  const rows = formik.values?.workflowApprover || [];

  const validateStep2Data = () => {
    let previousMaximumAmount = -1; // Set an initial value to compare the first maximumAmount

    // Loop through all items and check for validation errors
    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
      const maximumAmount = formik.values?.workflowApprover?.[rowIndex]?.maximumAmount;
      const userId = formik.values?.workflowApprover?.[rowIndex]?.userAccount?.orgUserID;
      if (!maximumAmount) {
        toast.error(tosterKey.maximumAmountIsrequired + ` for workflow ${rowIndex + 1}`);
        return;
      }
      else if (maximumAmount <= previousMaximumAmount) {
        toast.error(tosterKey.maximumAmountGreaterThanPrevious + ` for workflow ${rowIndex + 1}`);
        return false;
      }
      else if (!userId) {
        toast.error(tosterKey.approverIsRequired + ` for workflow ${rowIndex + 1}`);
        return false;
      }
      // Update the previousMaximumAmount for the next iteration
      previousMaximumAmount = maximumAmount;
    }

    // All items passed validation
    return true;
  };


  // const nextStep = () => {
  //   if (!stepper.current) {
  //     console.log("Stepper not initialized");
  //     return;
  //   }
  //   console.log("Before going next, Current Step Index:", stepper.current.currentStepIndex);

  //   if (stepper.current.currentStepIndex === 1) {
  //     if (!formik.values?.workflowPolicyType.id) {
  //       toast.error(tosterKey.approverPolicyRequired);
  //       return;
  //     }
  //     else if (!formik.values?.workflowName) {
  //       toast.error(tosterKey.workflowNameRequired);
  //       return;
  //     }
  //     else if (formik.values?.workflowPolicyType.id === 2) {
  //       if (!formik.values?.userAccountIds[0]) {
  //         toast.error(tosterKey.userIsRequired);
  //         return;
  //       }
  //     }
  //   }
  //   // else if (formik.values?.workflowPolicyType.id !== 1) {
  //   else if (stepper.current.currentStepIndex === 2) {
  //     const step2Valid = validateStep2Data();
  //     if (!step2Valid) {
  //       return; // Validation failed, return early
  //     }
  //   }
  //   // }
  //   stepper.current.goNext();
  //   console.log("After going next, Current Step Index:", stepper.current.currentStepIndex);

  // };


  const handleModalClose = () => {
    resetForm();
    handleClose();
  };
  useEffect(() => {
    if (show) {
      resetForm();
      setCurrentStep(1);
    }
  }, [show])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleModalClose}
      backdrop="static"
    >
      <div className='modal-header'>
        <h2>Create Workflow</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <form onSubmit={formik.handleSubmit}>
          {/* Sidebar for step navigation */}
          <div className='d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='stepper stepper-pills stepper-column' >
              <div className='sidebar d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
                <div className='stepper-nav ps-lg-10 tep-indicators'>
                  <div className='stepper-item'>
                    <div className='stepper-wrapper'>
                      <div className={currentStep === 1 ? 'active stepper-icon w-40px h-40px bg-primary ' : 'stepper-icon w-40px h-40px'}>
                        <i className='stepper-check fas fa-check'></i>
                        <span className={currentStep === 1 ? 'active stepper-number text-white' : 'stepper-number'}>1</span>
                      </div>
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{labelKey.workflowDetails}</h3>

                        <div className='stepper-desc'>{labelKey.defineYourWorkflowDetail}</div>
                      </div>
                    </div>
                    {String(formik.values.workflowPolicyType.id) === '2' &&
                      <div className='stepper-line h-40px'></div>
                    }
                  </div>
                  {String(formik.values.workflowPolicyType.id) === '2' &&
                    <>
                      <div className='stepper-item'>
                        <div className='stepper-wrapper'>
                          <div className={currentStep === 2 ? 'active stepper-icon w-40px h-40px bg-primary ' : 'stepper-icon w-40px h-40px'}>
                            <i className='stepper-check fas fa-check'></i>
                            <span className={currentStep === 2 ? 'active stepper-number text-white' : 'stepper-number'}>2</span>
                          </div>
                          <div className='stepper-label'>
                            <h3 className='stepper-title'>{labelKey.workflowApprovers}</h3>

                            <div className='stepper-desc'>{labelKey.defineApproversDetail}</div>
                          </div>
                        </div>
                        <div className='stepper-line h-40px'></div>
                      </div>
                      <div className='stepper-item'>
                        <div className='stepper-wrapper'>
                          <div className={currentStep === 3 ? 'active stepper-icon w-40px h-40px bg-primary ' : 'stepper-icon w-40px h-40px'}>
                            <i className='stepper-check fas fa-check'></i>
                            <span className={currentStep === 3 ? 'active stepper-number text-white' : 'stepper-number'}>3</span>
                          </div>
                          <div className='stepper-label'>
                            <h3 className='stepper-title'>{labelKey.completed}</h3>
                            <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <div>
              {/* Display steps based on the currentStep */}
              {currentStep === 1 && <Step1 formik={formik} />}
              {String(formik.values.workflowPolicyType.id) === '2' && currentStep === 2 && <Step2 formik={formik} />}
              {currentStep === 3 && <Step5 formik={formik} />}

              {/* Step navigation buttons */}
              {String(formik.values.workflowPolicyType.id) !== '2' ?
                // Show a single submit button for non-step-based workflow
                <div className='d-flex pt-10'>
                  <Button
                    type='submit'
                    className='btn btn-lg btn-primary ms-auto'
                    disabled={isLoading}
                  >
                    {!isLoading ? 'Submit' :
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelKey.pleaseWait}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>}
                  </Button>
                </div>
                :
                // Show next and previous buttons for step-based workflow
                <div className='d-flex flex-stack pt-10'>
                  {currentStep !== 1 && (
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      onClick={handlePrev}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      {labelKey.previous}
                    </button>
                  )}
                  {currentStep !== 3 ? (
                    <button
                      type='button'
                      className='btn btn-lg btn-primary ms-auto'
                      onClick={handleNext}
                    >
                      {labelKey.nextStep}{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-1 me-0'
                      />
                    </button>
                  ) : (
                    <Button
                      type='submit'
                      className='btn btn-lg btn-primary'
                      disabled={isLoading}
                    >
                      {!isLoading ? 'Submit' :
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {labelKey.pleaseWait}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>}
                    </Button>
                  )}
                </div>

              }
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreateworkflowModal }

