// src/store/slices/columnVisibilitySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ColumnVisibilityState {
    pages: {
        [key: string]: string[];
    };
}

interface ToggleColumnPayload {
    page: string;
    columnId: string;
}

interface SetHiddenColumnsPayload {
    page: string;
    hiddenColumns: string[];
}

const initialState: ColumnVisibilityState = {
    pages: {
        countries: ['sr', 'countryName', 'countryCode', 'isActive', 'actions'],
        division: ['sr', 'divisionName', 'divisionCode', 'isActive', 'action'],
        expenseVoilation: ['sr', 'fieldName', 'description', 'violationMessage', 'isActive', 'violationLevelVM', 'actions'],
        department: ['sr', 'departmentName', 'code', 'isActive', 'actions'],
        locations: ['sr', 'locationName', 'locationCode', 'address', 'latitude', 'longitude', 'isActive', 'updatedOn', 'createdAt', 'createdBy', 'updatedBy', 'description', 'actions'],
        categories: ['sr', 'categories', 'actions', 'glCode', 'status', 'description'],
        budget: ['sr', 'user', 'allocatedBudget', 'startDate', 'endDate', 'remainingBudget', 'actions'],
        categoryBudgeting: ['sr', 'name', 'startDate', 'endDate', 'users', 'status', 'actions'],
        mileage: ['sr', 'from', 'to', 'ratePerKM', 'ratePerMi', 'actions'],
        workflow: ['sr', 'workflowName', 'workflowType', 'status', 'users', 'actions'],
        vendor: ['sr', 'name', 'description', 'address', 'phone', 'status', 'actions'],
        expenseUsers: ['sr', 'firstName', 'ssId', 'employeeId', 'bcEmployeeId', 'userOrg', 'userRole', 'manager', 'mgrApproveMaxLimit', 'department', 'division', 'designation', 'cellphone', 'officeLocation', 'active', 'createDT', 'lastLoginDt', 'actions'],
        expenseDetail: ['reportId', 'expenseType', 'title', 'amount', 'createdDate', 'expenseStatus', 'manager', 'comment', 'actions'],
        expenseByCategory: ['sr', 'category', 'currency', 'amount', 'vendor', 'paymentMethod', 'itemDate', 'attachment', 'source', 'description', 'reimbursableDistance'],
        cards: ['sr', 'creditCardCatagory', 'creditCardType', 'cardNumber', 'expiryDate', 'isDeleted', 'actions'],
        approvalQueue: ['trackingId', 'userAccount', 'title', 'expenseDetail', 'amount', 'reportDate', 'expenseStatus', 'clarificationNote', 'actions'],
        expenseHistory: ['trackingId', 'expenseType', 'title', 'expenseDetail', 'amount', 'reportDate', 'expenseStatus', 'manager', 'comments', 'createdDate', 'updatedDate', 'actions'],
        expenseLineItems: ['checkbox', 'sr', 'expenseCategory', 'currency', 'amount', 'vander', 'paymentMethod', 'itemDate', 'location', 'attachment', 'description', 'source', 'reimbursableDistance', 'createdDateTime', 'updatedDate', 'actions'],
        budgetHistory: ['sr', 'user', 'allocatedBudget', 'startDate', 'endDate', 'remainingBudget', 'comments'],
        helpSupport: ['sr', 'topic', 'section', 'subject', 'description', 'assignedTo', 'requestTrackingID', 'assignedToUserDate', 'appResolutionType', 'appRequestSeverity', 'qaCompleted', 'deploymentNeeded', 'deploymentReady', 'deployed', 'estimatedDate', 'lastUpdateDate', 'status', 'requester', 'action'],
        ExpenseVoilationReport: ['trackingId', 'category', 'user', 'voilation', 'providedValues', 'receiptValues'],
        requestlogs: ['user', 'exceptionArea', 'reqMethodName', 'responseCode', 'date', 'message'],
        categoryBudgetReport: ['sr', 'userName', 'budgetName', 'category', 'budget', 'remainingBudget', 'startDate', 'endDate'],
        accountPayableList: ['reportID', 'userAccount', 'expenseType', 'title', 'expenseDetail', 'amount', 'reportDate', 'expenseStatus', 'assignToMe', 'assignToAP', 'clarificationNote', 'syncToBCDate', 'syncToBCStatus', 'bCExpenseCreated', 'bcExpenseCreatedDate', 'bCExpenseUpdated', 'bcExpenseUpdatedDate', 'action'],
        backupApprovers: ['checkbox', 'trackingId', 'userAccount', 'title', 'expenseDetail', 'amount', 'reportDate', 'expenseStatus', 'clarificationNote', 'actions'],
        mergeLineItems: ['cCTransactionID', 'lineItemDetailEntityId', 'ccVendorName', 'employeeId', 'ccItemImportDate', 'ccMerchantType', 'ccItemDescription', 'cctransactionDate', 'productCode', 'ccTotalAmount', 'userFullName', 'expenseReportId', 'ssExpenseCategoryName', 'ssVendorName', 'ssItemDate', 'ssItemAmount', 'currency', 'itemDebitOrCreditIndicator', 'debitOrCreditIndicator', 'ssAttachment', 'ccReportId', 'ccAttachement', 'paymentMethod', 'ccLineItemId', 'ccSource', 'ssPaymentMethod', 'ssItemDescription', 'ssCreatedDate', 'ssLineItemId', 'ssSource', 'ccCategory', 'ssCategory'],
        bankCCFeeds: ['cCTransactionID', 'lineItemDetailEntityId', 'bankVendorName', 'employeeId', 'itemImportDate', 'merchantType', 'itemDescription', 'transactionDate', 'productCode', 'ccTotalAmount', 'userFullName', 'expenseReportId', 'expenseCategoryName', 'vendorName', 'itemDate', 'itemAmount', 'currency', 'itemDebitOrCreditIndicator', 'debitOrCreditIndicator', 'accountNumber', 'ssLineItemId'],
        userCCFeed: ['cCTransactionID', 'lineItemDetailEntityId', 'bankVendorName', 'employeeId', 'itemImportDate', 'merchantType', 'itemDescription', 'transactionDate', 'productCode', 'ccTotalAmount', 'userFullName', 'expenseReportId', 'expenseCategoryName', 'vendorName', 'itemDate', 'itemAmount', 'currency', 'itemDebitOrCreditIndicator', 'debitOrCreditIndicator', 'accountNumber', 'fileName', 'ssLineItemId'],
        exportReports: ['sS_ReportNo', 'amount', 'itemTransactionDate', 'receipt', 'reportStatus', 'submitted_By', 'reportDate', 'syncSSToBCDateTime', 'reportTitle', 'paymentMethod', 'assignedToName', 'chargeDepartmentCode', 'chargeDepartmentName', 'chargeLocationCode', 'chargeLocationName', 'expenseLineItemNo', 'transactionSource', 'vendorName', 'categoryName', 'splitToLocationsName', 'reportSubmittedDate', 'apApprovedDate', 'paidDate'],
        // Add other pages as needed
    }
};

const columnVisibilitySlice = createSlice({
    name: 'columnVisibility',
    initialState,
    reducers: {
        toggleColumn: (state, action: PayloadAction<ToggleColumnPayload>) => {
            const { page, columnId } = action.payload;
            if (state.pages[page].includes(columnId)) {
                state.pages[page] = state.pages[page].filter(id => id !== columnId);
            } else {
                state.pages[page].push(columnId);
            }
        },
        setHiddenColumns: (state, action: PayloadAction<SetHiddenColumnsPayload>) => {
            const { page, hiddenColumns } = action.payload;
            state.pages[page] = hiddenColumns;
        }
    }
});

export const { toggleColumn, setHiddenColumns } = columnVisibilitySlice.actions;
export default columnVisibilitySlice.reducer;
