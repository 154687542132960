import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import DateComp from '../../../components/dateComponent/DateComp'
import Pagination from '../../../components/pagination/Pagination'
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useDepartmentsLookupQuery, useGetAllDesignationWithoutRoleQuery } from '../../../services/GeneralApi'
import { useGetManagerTeamQuery } from '../../../services/authApi'
import labelKey from "../../localization/label.json"
import AddUserModal from '../users/AddUserModal'
import UpdateUserModal from '../users/UpdateUserModal'
import UserActiveConfirmation from '../users/UserActiveConfirmation'
import UserDetailModal from '../users/UserDetailModal'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'

type Props = {
    className?: string
}
const FamilyAccount: React.FC<Props> = ({ className }) => {
    const location = useLocation();
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const { userAccountID, roleID } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [managerId, setManagerId] = useState(userAccountID);
    const [filterByDepartment, setFilterByDepartment] = useState('');
    const [filterByDesignation, setFilterByDesignation] = useState('');
    const [filterByStatus, setFilterByStatus] = useState('');
    const [isChecked, setIsChecked] = useState(false)

    const [localFilterByDepartment, setLocalFilterByDepartment] = useState('');
    const [localFilterByDesignation, setLocalFilterByDesignation] = useState('');
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const [sortedColumn, setSortedColumn] = useState("createDT");
    const { data: designationLookUp } = useGetAllDesignationWithoutRoleQuery('');
    const { data: deparmentLookUp } = useDepartmentsLookupQuery('');
    const { data: getManagerTeam,
        isError: getManagerIsError,
        isLoading: getManagerIsLoading,
        refetch,
        isSuccess } = useGetManagerTeamQuery({
            pageNumber: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
            searchStr: searchStr,
            managerId: managerId,
            filterByDepartment: filterByDepartment,
            filterByDesignation: filterByDesignation,
            filterByStatus: filterByStatus
        });
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    const handleDeparmentChange = (selectedOption: any) => {
        setLocalFilterByDepartment(selectedOption.value);
        // const departmentName = selectedOption.value.split(' - ')[1] || selectedOption.value;
        // setLocalFilterByDepartment(departmentName);
    };
    const handleDesignationChange = (selectedOption: any) => {
        setLocalFilterByDesignation(selectedOption.value);
    };
    const handleFilterStatus = (selectedOption: any) => {
        setFilterByStatus(selectedOption.value);
    };

    const [showUserDetailModal, setShowUserDetailModal] = useState(false)
    const [userDetailData, setuserDetailData] = useState({});

    const userDetailHandler = () => {
        setShowUserDetailModal((prev) => !prev);
    };

    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false)
    const [editData, setEditData] = useState({});

    const editUserHandler = () => {
        setShowUpdateUserModal((prev) => !prev);
    };
    const [showConfirmationActiveUserModal, setShowConfirmationActiveUserModal] = useState(false)
    const expenseConfirmationModalHandler = () => {
        setShowConfirmationActiveUserModal((prev) => !prev);
    };
    useEffect(() => {
        if (getManagerIsError) {
            toast.error("Some thing went wrong")
        }
    }, [getManagerIsError])

    const tableRef = useRef(null);
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);

    const [prevModalState, setPrevModalState] = useState({
        showUserDetailModal: false,
        showUpdateUserModal: false,
        showAddUserModal: false,
        showConfirmationActiveUserModal: false,
    });

    useEffect(() => {
        setPrevModalState({
            showUserDetailModal: showUserDetailModal,
            showUpdateUserModal: showUpdateUserModal,
            showAddUserModal: showAddUserModal,
            showConfirmationActiveUserModal: showConfirmationActiveUserModal,
        });
    }, [
        showUserDetailModal,
        showUpdateUserModal,
        showAddUserModal,
        showConfirmationActiveUserModal,
    ]);

    useEffect(() => {
        if (
            (prevModalState.showUserDetailModal && !showUserDetailModal) ||
            (prevModalState.showUpdateUserModal && !showUpdateUserModal) ||
            (prevModalState.showAddUserModal && !showAddUserModal) ||
            (prevModalState.showConfirmationActiveUserModal && !showConfirmationActiveUserModal)
        ) {
            refetch();
        }
    }, [
        showUserDetailModal,
        showUpdateUserModal,
        showAddUserModal,
        showConfirmationActiveUserModal,
        prevModalState.showUserDetailModal,
        prevModalState.showUpdateUserModal,
        prevModalState.showAddUserModal,
        prevModalState.showConfirmationActiveUserModal,
        refetch,
    ]);

    const [pageTitle, setPageTitle] = useState(labelKey.teamManagement);
    useEffect(() => {
        // Check the current route and set the selected value accordingly
        if (location.pathname === '/active/team-management') {
            setFilterByStatus('active');
            setPageTitle('Active Reportees')
        } else if (location.pathname === '/all/team-management') {
            setPageTitle('All Reportees')
        } else {
            setFilterByStatus('');
            setPageTitle(labelKey.teamManagement);
        }

    }, [location.pathname]);

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByDepartment(localFilterByDepartment);
        setFilterByDesignation(localFilterByDesignation);
    };
    const resetFilters = () => {
        setFilterByDepartment('');
        setFilterByDesignation('');
        setLocalFilterByDepartment('');
        setLocalFilterByDesignation('');
        closeMenu();
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
        if (isSuccess) {
            closeMenu();
        }
    }, [isApplyingFilters, isSuccess]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    return (
        <>
            <AddUserModal show={showAddUserModal}
                handleClose={() => setShowAddUserModal(false)} />
            <UpdateUserModal show={showUpdateUserModal} handleClose={() => setShowUpdateUserModal(false)} data={editData} />
            <UserDetailModal show={showUserDetailModal} handleClose={() => setShowUserDetailModal(false)} data={userDetailData} />
            <UserActiveConfirmation
                show={showConfirmationActiveUserModal}
                handleClose={() => setShowConfirmationActiveUserModal(false)}
                data={editData}
                isChecked={isChecked} />

            <PageTitle breadcrumbs={[]}>{pageTitle}</PageTitle>
            <div className='card mb-5'>
                <div className='card-header border-0 '>
                    <div className='card-title'>
                        <SearchBarComponent
                            placeholder='User'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                            <div className='position-relative'>
                                <button
                                    type='button'
                                    className='btn btn-sm btn-light-primary me-3 d-flex justify-content-end'
                                    data-kt-menu-placement='bottom-end'
                                    onClick={toggleMenu}
                                >
                                    <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                                    {labelKey.filter}
                                </button>
                                <div className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px' ${isMenuOpen ? 'show position-absolute mt-3' : ''}`} style={{ right: "5px" }}>
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bold'>{labelKey.filterOptions}</div>
                                    </div>
                                    <div className='separator border-gray-200'></div>
                                    <div className='px-7 py-5'>

                                        <div className='mb-5'>
                                            <label className='form-label fs-5 fw-bold'>{labelKey.department}</label>
                                            <Select
                                                options={[
                                                    // { value: '', label: 'All' }, // Add the empty option
                                                    ...(deparmentLookUp?.result || []).map((option: any) => {
                                                        // const label = option.value.split(' - ')[1] || option.value;
                                                        return {
                                                            value: option.value,
                                                            label: option?.value,
                                                        };
                                                    }),
                                                ]}
                                                placeholder={`${localFilterByDepartment ? `${localFilterByDepartment}` : 'Select Department'}`}
                                                value={localFilterByDepartment}
                                                onChange={handleDeparmentChange}
                                            />
                                        </div>
                                        <div className='mb-5'>
                                            <label className='form-label fs-5 fw-bold'>{labelKey.designation}</label>
                                            <Select
                                                options={[
                                                    // { value: '', label: 'All' }, // Add the empty option
                                                    ...(designationLookUp?.result || []).map((option: any) => ({
                                                        value: option.value,
                                                        label: option?.value,
                                                    })),
                                                ]}
                                                placeholder={`${localFilterByDesignation ? `${localFilterByDesignation}` : 'Select Designation'}`}
                                                value={localFilterByDesignation}
                                                onChange={handleDesignationChange}
                                            />
                                        </div>

                                    </div>
                                    <div className='separator border-gray-200'></div>
                                    <div className='px-7 py-5'>
                                        <button className='btn btn-primary pull-right' onClick={applyFilters}>
                                            {isApplyingFilters ? <>
                                                <span>loading...</span>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            </> : "Apply Filters"}
                                        </button>
                                        <button className='btn btn-danger pull-right me-3' onClick={resetFilters}>Reset Filters</button>
                                    </div>
                                </div>
                            </div>


                            {/* <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={tableRef.current}
                            >
                                <button type='button' className='btn btn-sm btn-light-primary me-3' >
                                    <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                    {labelKey.export}
                                </button>
                            </DownloadTableExcel> */}

                            {/* <button className='btn btn-sm btn-sm btn-light-primary' onClick={() => setShowAddUserModal(true)}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addReportee}
                            </button> */}
                        </div>
                    </div>
                    {/* } */}
                </div>
                <div className='card-body py-3'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' ref={tableRef}>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-40px rounded-start text-center'>{labelKey.sr}</th>
                                        <th className='min-w-120px' onClick={() => handleSort("firstName")}
                                        >
                                            <div className='table-arrow'>
                                                {labelKey.name}
                                                {sortedColumn === "firstName" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th>
                                        <th className='min-w-120px' onClick={() => handleSort("department")}>
                                            <div className='table-arrow'>
                                                {labelKey.department}
                                                {sortedColumn === "department" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th>
                                        <th className='min-w-120px' onClick={() => handleSort("designation")}
                                        >
                                            <div className='table-arrow'>
                                                {labelKey.designation}
                                                {sortedColumn === "designation" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th>

                                        <th className='min-w-140px' onClick={() => handleSort("cellPhone")}
                                        >
                                            <div className='table-arrow'>
                                                {labelKey.cellPhone}
                                                {sortedColumn === "cellPhone" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th>
                                        {/* <th className='min-w-120px' onClick={() => handleSort("active")}
                                        >
                                            <div className='table-arrow'>
                                                {labelKey.status}
                                                {sortedColumn === "active" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th> */}
                                        <th className='min-w-140px' onClick={() => handleSort("createDT")}
                                        >
                                            <div className='table-arrow'>
                                                {labelKey.createdDate}
                                                {sortedColumn === "createDT" ? (
                                                    <>
                                                        {sortOrder === "desc" ? <FaArrowDown /> : <FaArrowUp />}
                                                    </>
                                                ) :
                                                    ''
                                                }
                                            </div>
                                        </th>
                                        <th className='min-w-100px rounded-end pe-4 text-end'>{labelKey.action}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!getManagerIsLoading &&
                                        <>
                                            {getManagerTeam?.result?.data?.length > 0 ? (
                                                <>
                                                    {getManagerTeam?.result?.data?.map((data: any, index: any) => (


                                                        <tr key={index}>
                                                            <td className='text-center'>
                                                                {(page - 1) * pageSize + index + 1}
                                                            </td>
                                                            <td>
                                                                <Link to={`/user-profile/expense-items/${data.userAccountID}`}>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                            {/* {data?.imageUrl ? <img src={data?.imageUrl} alt="" /> : */}
                                                                            <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.firstName?.charAt(0)}</div>
                                                                            {/* } */}
                                                                        </div>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="text-gray-800 fw-semibold mb-1 text-capitalize">{data?.firstName} {data?.middleName} {data?.lastName}</span>
                                                                            <span className='text-lowercase'>{data?.email}</span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td>{data?.department?.title ? data?.department?.title : "N/A"}</td>
                                                            <td>{data?.designation?.title ? data?.designation?.title : "N/A"}</td>
                                                            <td>{data?.cellPhone ? data?.cellPhone : "N/A"}</td>
                                                            {/* <td>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    {data?.active == true ?
                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                        <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}

                                                                    {(roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2 || roleID === constraintConfig.roleID.role4) &&
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input cursor-pointer"
                                                                                type="checkbox"
                                                                                role="switch" id="flexSwitchCheckChecked"
                                                                                checked={data.active}
                                                                                onChange={(e) => {
                                                                                setIsChecked(e.target.checked);
                                                                                    setEditData(data);
                                                                                    expenseConfirmationModalHandler();
                                                                                }} />
                                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked"></label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </td> */}
                                                            <td><DateComp formattedDate={data?.createDT} /></td>
                                                            <td>
                                                                <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                    {/* <Tooltip id="update-user-1" place="bottom" />
                                                                    <div
                                                                        data-tooltip-id="update-user-1" data-tooltip-content='Update User'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                        onClick={() => {
                                                                            setEditData(data);
                                                                            editUserHandler();
                                                                        }}
                                                                    >
                                                                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                    </div> */}
                                                                    <Tooltip id="user-detail-1" place="top" />
                                                                    <div
                                                                        data-tooltip-id="user-detail-1" data-tooltip-content='User Details'
                                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                        onClick={() => {
                                                                            setuserDetailData(data?.userAccountID);
                                                                            userDetailHandler();
                                                                        }} >
                                                                        <KTSVG
                                                                            path='/media/icons/duotune/arrows/arr064.svg'
                                                                            className='svg-icon-3'
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    }
                                    {getManagerIsLoading && (
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalResult={getManagerTeam?.result?.totalRecords === 0 ? 0 : ((getManagerTeam?.result?.pageNumber - 1) * getManagerTeam?.result?.pageSize) + 1}
                            toResult={getManagerTeam?.result?.totalRecords === 0 ? 0 : Math.min(getManagerTeam?.result?.pageNumber * getManagerTeam?.result?.pageSize, getManagerTeam?.result?.totalRecords)}
                            ofResult={getManagerTeam?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={getManagerTeam?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}

export default FamilyAccount