import axios from 'axios'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
import { constraintConfig } from '../../../constraintConfig'

type Props = {
    show: boolean
    handleClose: () => void
    data: any
}
const ExpenseApproverModal = ({ show, handleClose, data, }: Props) => {

    const { token, baseURL } = useAppSelector(selectAuth);
    const [expenseApproversData, setExpenseApproversData] = useState<any>(null); // Initialize with null
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            if (show && data) {
                setIsLoading(true); // Set loading state to true
                try {
                    const headers = {
                        Authorization: `Bearer ${token}`,
                    };

                    let getExpenseLogResponse;

                    if (data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted) {
                        getExpenseLogResponse = await axios.get(
                            `${baseURL}/api/Expense${API_END_POINTS.getExpenseApproversByExpenseId}?expenseId=${data?.expenseId}`,
                            {
                                headers,
                            }
                        );
                    } else {
                        getExpenseLogResponse = await axios.get(
                            `${baseURL}/api/Expense${API_END_POINTS.getExpenseApproversByExpenseIdV2}?expenseId=${data?.expenseId}`,
                            {
                                headers,
                            }
                        );
                    }

                    const expenseApproversData = getExpenseLogResponse.data;
                    setExpenseApproversData(expenseApproversData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setIsLoading(false); // Set loading state to false after fetching data
                }
            }
        };
        fetchData();
    }, [show, data, token]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header py-4'>
                    <h2 className='mb-0'>{labelKey.workflowApprovers}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>

                <div className='modal-body py-lg-5 px-lg-10'>
                    <div className='row'>
                        <h3>{expenseApproversData?.workflowTypeName}</h3>
                        <div className='table-responsive'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr>
                                        <th className='ps-4 rounded-start'>{labelKey.level}</th>
                                        <th>{labelKey.approver}</th>
                                        <th className='rounded-end'>{labelKey.managerMaxLimit}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!isLoading && (<>
                                        {expenseApproversData?.result?.workflowApproversData?.length > 0 ?
                                            <>
                                                {expenseApproversData?.result?.workflowApproversData?.map((data: any, index: any) => (
                                                    <tr key={index}>
                                                        <td className='ps-4'>{data?.approverLevel}</td>
                                                        <td className='ps-4'>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.fullName.charAt(0)}</div>
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <p className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.fullName} </p>
                                                                    <span className='text-lowercase'>{data?.managerEmail}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><CurrencyComp amount={data?.mgrApproveMaxLimit} /> </td>
                                                    </tr>
                                                ))}
                                            </>
                                            :
                                            <tr>
                                                <td colSpan={5} className='text-center'>{labelKey.notAvailable}</td>
                                            </tr>
                                        }
                                    </>)}
                                    {isLoading && (
                                        <tr>
                                            <td colSpan={5} className="text-center">{labelKey.loading} ....</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className='modal-footer py-4'>
                    <button className='btn btn-light-primary' onClick={handleClose}>{labelKey.close}</button>
                </div>

            </Modal>
        </>
    )
}

export default ExpenseApproverModal