/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import CryptoJS from 'crypto-js'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa6'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { setUser } from '../../../../features/authSlice'
import { useAppDispatch } from '../../../../hooks'
import { useLoginUserMutation } from '../../../../services/authenticationApi'
import labelKey from "../../../localization/label.json"

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .test('uppercase', 'Password must contain at least one capital letter', (value) => {
      return /[A-Z]/.test(value || '');
    })
    .test('specialCharacter', 'Password must contain at least one special character', (value) => {
      return /[\W_]/.test(value || '');
    })
    .test('number', 'Password must contain at least one number', (value) => {
      return /[0-9]/.test(value || '');
    })
    .test('lowercase', 'Password must contain at least one lowercase letter', (value) => {
      return /[a-z]/.test(value || '');
    })
    .required('Password is required'),
})




export function Login() {
  const themeModeMenuValue = localStorage.getItem('kt_theme_mode_menu');
  const [showPassword, setShowPassword] = useState(true)
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const [loginUser, {
    data: loginData,
    isError,
    isSuccess: isLoginSuccess,
    error,
    isLoading: isLoginLoading },] = useLoginUserMutation();

  const initialValues = {
    username: "",
    password: "",
    userinfo: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: (values) => {

      // Define key and iv
      const key = CryptoJS.enc.Utf8.parse('SS#BoQ&IA9NB$&!2');
      const iv = CryptoJS.enc.Utf8.parse('SIV#Bo*QIA9B$!2%');

      // Create user information object
      const userInfo = {
        username: values.username,
        password: values.password,
      };

      // Convert user information object to JSON string
      const userInfoString = JSON.stringify(userInfo);

      // Encrypt user information JSON string
      const encryptedUserInfo = CryptoJS.AES.encrypt(userInfoString, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();

      // Proceed with form submission
      loginUser({ userinfo: encryptedUserInfo } as any);
    }

  })
  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userAgent = navigator.userAgent.toLowerCase();

    let browserName = "Unknown";
    let deviceType = "Desktop";
    let deviceName = "Unknown Device";

    // Detect browser
    if (userAgent.includes("chrome") && !userAgent.includes("edg") && !userAgent.includes("opr")) {
      browserName = "Chrome";
    } else if (userAgent.includes("safari") && !userAgent.includes("chrome") && !userAgent.includes("android")) {
      browserName = "Safari";
    } else if (userAgent.includes("firefox")) {
      browserName = "Firefox";
    } else if (userAgent.includes("opr") || userAgent.includes("opera")) {
      browserName = "Opera";
    } else if (userAgent.includes("edg")) {
      browserName = "Edge";
    } else if (userAgent.includes("msie") || userAgent.includes("trident")) {
      browserName = "Internet Explorer";
    } else if (userAgent.includes("android")) {
      browserName = "Android Browser";
    } else if (userAgent.includes("iphone") && userAgent.includes("mobile")) {
      browserName = "iOS Browser";
    }

    // Detect device type and name
    if (userAgent.includes("mobile")) {
      deviceType = "Mobile";
      if (userAgent.includes("iphone")) {
        deviceName = "iPhone";
      } else if (userAgent.includes("android")) {
        deviceName = "Android Device";
      } else if (userAgent.includes("ipad")) {
        deviceName = "iPad";
      } else {
        deviceName = "Mobile Device";
      }
    } else if (userAgent.includes("tablet") || userAgent.includes("ipad")) {
      deviceType = "Tablet";
      deviceName = userAgent.includes("ipad") ? "iPad" : "Android Tablet";
    } else {
      // For desktop/laptop, detecting exact model is challenging
      if (userAgent.includes("windows")) {
        deviceName = "Windows PC";
      } else if (userAgent.includes("macintosh")) {
        deviceName = "Mac";
      } else if (userAgent.includes("linux")) {
        deviceName = "Linux PC";
      }
    }

    // console.log("Time Zone:", timeZone);
    // console.log("Browser:", browserName);
    // console.log("Device Type:", deviceType);
    // console.log("Device Name:", deviceName);
  }, []);

  useEffect(() => {
    if (isLoginSuccess) {
      // toast.success("User Login Succesfully");
      dispatch(
        setUser({
          firstName: loginData.result.userAccountBasicInfo.firstName,
          lastName: loginData.result.userAccountBasicInfo.lastName,
          email: loginData.result.userAccountBasicInfo.email,
          role: loginData.result.userAccountBasicInfo.roleName,
          roleID: loginData.result.userAccountBasicInfo.roleID,
          contactPhone: loginData.result.userAccountBasicInfo.cellPhone,
          businessPhone: loginData.result.userAccountBasicInfo.businessPhone,
          businessExt: loginData.result.userAccountBasicInfo.businessExt,
          userAccountID: loginData.result.userAccountBasicInfo.userAccountID,
          userInOrgId: loginData.result.userAccountBasicInfo.userAccountInOrgs[0].userInOrgId,
          locationType: loginData.result.userAccountBasicInfo.locationType.id,
          expenseType: loginData.result.userAccountBasicInfo.expenseType.id,
          location: loginData.result.userAccountBasicInfo.location?.id,
          locationName: loginData.result.userAccountBasicInfo.location?.value,
          reportPermision: loginData.result.userAccountBasicInfo.reportPermision,
          timeZone: loginData.result?.userAccountBasicInfo?.userTimeZone?.title,
          token: loginData.result.token,
          expiresAt: loginData.result.expiresAt,
          baseURL: loginData.result.baseURL,
          microServiceURL: loginData.result.microServiceURL,
          flexBudgetPermission: loginData.result?.userPermission[0]?.value,
          categoryBudgetPermission: loginData.result?.userPermission[1]?.value,

        })
      );
      navigate("dashboard")
    }
  }, [isLoginSuccess]);


  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
      >
        {/* <ToastContainer /> */}
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <div>
            {/* <img src="/media/logos/favicon-2.png" alt="" width="70px" height="70px" className='position-absolute' style={{top:"-94px",marginLeft:"-29px"}}  /> */}

            {/* {themeModeMenuValue === 'dark' ? */}
            <img
              alt='Logo'
              src='/media/logos/logomain-white.png'
              className='h-50px me-5 theme-dark-show'
            />
            {/* : */}
            <img
              alt='Logo'
              src='/media/logos/logomain.png'
              className='h-50px me-5 theme-light-show'
            />
            {/* } */}
          </div>
          <h3 className='text-dark mb-3 mt-5'>{labelKey.signInToYourAccount}</h3>
          {/* <div className='text-gray-400 fw-semibold fs-4'>
            New Here?{' '}
            <Link to='/auth/registration' className='link-primary fw-bold'>
              Create an Account
            </Link>
          </div> */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bold text-dark'>{labelKey.email}</label>
          <input
            placeholder='Email'
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.username && formik.errors.username },
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              })}
            type='email'
            {...formik.getFieldProps('username')}
            autoComplete='off'
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bold text-dark fs-6 mb-0'>{labelKey.password}</label>
            </div>
          </div>
          <input
            type={showPassword ? "password" : "text"}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.password && formik.errors.password },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div style={{ float: "right" }}>
            {showPassword ?
              <FaEye
                className='position-absolute fs-4'
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer", color: '#000' }} /> :
              <FaEyeSlash
                className='position-absolute fs-4'
                onClick={() => setShowPassword(!showPassword)}
                style={{ marginLeft: "-50px", marginTop: "-26px", cursor: "pointer", color: '#000' }} />}
          </div>
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bold mt-2'
            style={{ float: 'right' }}
          >
            {labelKey.forgotPassword}?
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            className='btn btn-lg btn-primary w-100 mb-5 mt-10'
            // disabled={formik.isSubmitting || !formik.isValid}
            disabled={isLoginLoading}
          >
            {!isLoginLoading &&
              <span className='indicator-label'> {labelKey.login}</span>
            }
            {isLoginLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          {/* begin::Separator */}
          {/* <div className='text-center text-muted text-uppercase fw-bold mb-5'>or</div> */}
          {/* end::Separator */}

          {/* begin::Google link */}
          {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a>
      
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a>
       
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
          {/* end::Google link */}
        </div>
        {/* end::Action */}
      </form>
    </>
  )
}
