import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import TextField from '../../../../components/textFields/TextField';
import tosterKey from "../../../localization/toster.json";
import labelKey from "../../../localization/label.json";
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import { useAddCreditCardInfoMutation, useUpdateCreditCardInfoMutation } from '../../../../services/CreditCardApi';
import { useAddCountryMutation, useUpdateCountryMutation } from '../../../../services/OrgSettingsApi';


type Props = {
  show: boolean
  handleClose: () => void
  data?: any
}
const AddUpdateCountriesModal = ({ show, handleClose, data }: Props) => {
  const [addCountry, { data: addCountryData, isLoading, isSuccess, isError, error }] = useAddCountryMutation()
  const [updateCountry, { data: updateCountryData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateCountryMutation()


  const initialValues = {
    countryId: 0,
    countryName: '',
    countryCode: ''
  }

  const [updateId, setUpdateId] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  // Store form values when the modal is closed
  const handleModalClose = () => {
    setFormValues(formik.values);
    handleClose();
  };
  useEffect(() => {
    // Restore form values when the modal reopens
    if (show && !data && Object.keys(formValues).length !== 0) {
      formik.setValues(formValues);
    }
    // Update form values based on data received
    if (data) {
      setUpdateId(data?.countryId);
      formik.setValues({ ...data });
    } else if (!show) {
      // Reset form values only when closing and it's not an update action
      setUpdateId(0);
      formik.resetForm();
    }
  }, [show, data]);

  const validationSchema = Yup.object().shape({
    countryName: Yup.string().required('Country Name is required'),
    countryCode: Yup.string().required('Country Code is required'),

  })
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (updateId) {
        updateCountry(values)
      } else {
        addCountry(values)
      }
    }
  })
  const { resetForm } = formik;

  useEffect(() => {
    if (isSuccess) {
      const responseData = (addCountryData as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      handleClose();
      resetForm()
    }
  }, [isSuccess, addCountryData]);
  
  useEffect(() => {

    if (updateIsSuccess) {
      const responseData = (updateCountryData as any)?.message;
      const successMessage = responseData;
      toast.success(successMessage);
      handleClose();
    }
  }, [updateIsSuccess, updateCountryData]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);
  useEffect(() => {
    if (updateIsError && updateError) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
        errorMessage = (updateError.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [updateIsError, updateError]);

  return (
    <>
      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-md modal-dialog-centered'
        show={show}
        onHide={handleModalClose}
      >
        <div className='modal-header'>
          <h2 className='mb-0'>{updateId ? <>{labelKey.updateCountry}</> : <>{labelKey.addCountry}</>}</h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>

        <form
          className='form w-100'
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            formik.setTouched({
              countryName: true,
              countryCode: true,
            }, true); // Set touched for all fields to trigger validation display
          }}
        >
          <div className='modal-body py-lg-10 px-lg-10'>
            <div className="row">


              <div className='col-md-12 mb-5'>
                <TextField
                  label={labelKey.country}
                  required={true}
                  type='text'
                  placeholder='Enter Country'
                  labelClass={`${formik.touched.countryName && formik.errors.countryName ? 'is-invalid' : ''}`}
                  {...formik.getFieldProps('countryName')}
                  value={formik.values.countryName || ''}
                />
                {formik.touched.countryName && formik.errors.countryName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.countryName}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-12 mb-5'>
                <TextField
                  label={labelKey.countryCode}
                  required={true}
                  type='text'
                  placeholder='Enter Country Code'
                  labelClass={`${formik.touched.countryCode && formik.errors.countryCode ? 'is-invalid' : ''}`}
                  {...formik.getFieldProps('countryCode')}
                  value={formik.values.countryCode || ''}
                />
                {formik.touched.countryCode && formik.errors.countryCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.countryCode}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='modal-footer justify-content-center'>
            <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
            <button
              type='submit'
              className='btn btn-primary mb-5 mt-5'
              disabled={isLoading}
            >
              {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

              {updateId ?
                <>
                  {updateIsLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {labelKey.pleaseWait}...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </> :
                <>
                  {isLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {labelKey.pleaseWait}...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </>
              }


            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddUpdateCountriesModal