import React from 'react';
import { KTSVG } from '../../_metronic/helpers';
import '../textFields/TextField.css'

interface Props {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    placeholder: string;
    className?: string;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Update this line
}

const SearchBarComponent: React.FC<Props> = ({ value, placeholder, onChange, className='w-250px', onKeyUp }) => {
    return (
        <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-5'
            />
            <input
                type='text'
                data-kt-user-table-filter='search'
                className={`${className} form-control form-control-solid ps-14 default-input`}
                placeholder={`Search ${placeholder}...`}
                value={value}
                onChange={onChange}
                onKeyUp={onKeyUp}
            />
        </div>
    );
};

export default SearchBarComponent;
