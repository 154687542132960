import React, { useEffect, useState } from 'react'
import tosterKey from "../../../localization/toster.json";
import labelKey from "../../../localization/label.json";

import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import TextField from '../../../../components/textFields/TextField';
import { useCreateExpenseCategoryMutation, useUpdateExpenseCategoryMutation } from '../../../../services/CategoriesApi';
import TextAreaField from '../../../../components/textFields/TextAreaField';
type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddCategoryModal = ({ show, handleClose, data }: Props) => {
    const [addCategory, { data: addCategoryData, isLoading, isSuccess, isError, error }] = useCreateExpenseCategoryMutation()
    const [updateCategory, { data: updateCategoryData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateExpenseCategoryMutation()
    const initialValues = {
        expenseCategoryId: 0,
        title: '',
        description: '',
        isActive: true,
        forManager: true,
        forUser: true,
        glCode: ''
    }
    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.expenseCategoryId);
            formik.setValues({ ...data })
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);

    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Category is required'),
    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateCategory(values)
            } else {
                addCategory(values)
            }
        }
    })
    const { resetForm } = formik;
    useEffect(() => {
        if (isSuccess) {
            const responseData = (addCategoryData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
            resetForm()
        }
    }, [isSuccess, addCategoryData]);
    useEffect(() => {

        if (updateIsSuccess) {
            const responseData = (updateCategoryData as any)?.message;
            const successMessage = responseData;
            toast.success(successMessage);
            handleClose();
        }
    }, [updateIsSuccess, updateCategoryData]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);

    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateCategory}</> : <>{labelKey.addCategory}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.category}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Category'
                                    labelClass={`${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('title')}
                                    value={formik.values.title || ''}
                                />
                                {formik.touched.title && formik.errors.title && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.title}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.glCode}
                                    type='text'
                                    placeholder='Enter Gl Code'
                                    {...formik.getFieldProps('glCode')}
                                    value={formik.values.glCode || ''}
                                />
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <TextAreaField
                                    label={labelKey.description}
                                    rows={5}
                                    placeholder='Enter Description'
                                    {...formik.getFieldProps('description')}
                                    value={formik.values.description || ''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                            {updateIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddCategoryModal