import { debounce } from 'lodash'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Dropdown, Spinner } from 'react-bootstrap'
import { BiDotsVerticalRounded } from "react-icons/bi"
import { FaExclamationCircle, FaHistory } from 'react-icons/fa'
import { FaCheck, FaEye, FaPaperPlane } from 'react-icons/fa6'
import { IoIosRepeat, IoIosWarning, IoMdRefresh } from 'react-icons/io'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import ReactReadMoreReadLess from "react-read-more-read-less"
import { Link, useLocation, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { KTSVG } from '../../../_metronic/helpers'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import DateComp from '../../../components/dateComponent/DateComp'
import Pagination from '../../../components/pagination/Pagination'
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent'
import TableHeading from '../../../components/tableHeading/TableHeading'
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu'
import TextField from '../../../components/textFields/TextField'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useGetExpenseByUserIdQuery } from '../../../services/ExpenseApi'
import { useGetAllExpenseTypeQuery } from '../../../services/GeneralApi'
import { API_END_POINTS } from '../../../services/apiEndpoints'
import labelKey from "../../localization/label.json"
import CategoriesWithIcons from './CategoriesWithIcons'
import ClarificationModal from './ClarificationModal'
import ConfirmationExpenseModal from './ConfirmationExpenseModal'
import ExpenseApproverModal from './ExpenseApproverModal'
import ExpenseDetailModal from './ExpenseDetailModal'
import ExpenseDetailWithOcrModal from './ExpenseDetailWithOcrModal'
import ExpenseItemViolationModal from './ExpenseItemViolationModal'
import ExpenseLogModal from './ExpenseLogModal'
import ExpenseStatusUpdate from './ExpenseStatusUpdate'
import { CreateAppModal } from './create-app-stepper/CreateExpenseModal'
import { UpdateExpenseModal } from './update-app-stepper/UpdateExpenseModal'
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import UploadDirectReceipt from './UploadDirectReceipt'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import useColumnVisibility from '../../customHooks/useColumnVisibility'
import FilterMenu from '../../../components/filterMenu/FilterMenu'
import { UpdateExpenseItemModal } from './update-single-Item/UpdateExpenseItemModal'
import { allRoutes } from '../../routing/All_Routes'
import DownloadReport from '../reportAnalytics/DownloadReport'
import DateTimeComp from '../../../components/dateComponent/DateTimeComp'
import { RiArrowGoBackFill } from "react-icons/ri";
import DateRangeComp from '../../../components/dateComponent/DateRangeComp'
import { toast } from 'react-toastify'

interface LightboxData {
    imageURL: string;
}
const ExpenseItemPage: React.FC = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'expenseHistory' });

    const location = useLocation();
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const [showCreateExpenseModal, setShowCreateExpenseModal] = useState<boolean>(false)
    const { userAccountID, roleID, token, baseURL } = useAppSelector(selectAuth);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("trackingId");
    const [sortOrder, setSortOrder] = useState("desc");
    // const [filterByMonth, setfilterByMonth] = useState('');
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [filterByStatus, setFilterByStatus] = useState('');
    const [filterByExpType, setFilterByExpType] = useState("0");
    const [sortedColumn, setSortedColumn] = useState("trackingId");

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByExpType, setLocalFilterByExpType] = useState("0");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const [showInnerTable, setShowInnerTable] = useState(null);
    const [expenseItemData, setExpenseItemData] = useState<any>(null);
    const [showExpenseItemData, setShowExpenseItemData] = useState({})
    const [expenseItemDataIsLoading, setExpenseItemDataIsLoading] = useState(false);
    const [expenseId, setExpenseId] = useState(0);

    const { id } = useParams();
    const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')
    const { data, isFetching: isLoading, refetch, isSuccess } = useGetExpenseByUserIdQuery({
        userAccountId: id ? id : userAccountID,
        pageNumber: page,
        pageSize: pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchStr: searchStr,
        filterByExpType: filterByExpType,
        startDate: filterByStartDate,
        endDate: filterByEndDate,
        filterByStatus: filterByStatus
    })


    useEffect(() => {
        const interval = setInterval(() => {
            // Check if any item's ocrReadStatus is 0
            if (data?.result?.data?.some((data:any) => data?.expenseStatus?.id === constraintConfig.expenseStatus.processing)) {
                refetch();
            }
        }, 10000); // 10000ms = 10 seconds
    
        return () => clearInterval(interval); // Clean up interval on component unmount
    }, [data, refetch]);

    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state
        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };
    const handleFilterByExpType = (selectedOption: any) => {
        setLocalFilterByExpType(selectedOption.value);
    };
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByExpType(localFilterByExpType);
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByExpType("0");
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByExpType("0");
    }
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    // Tab state and handler
    const [activeTab, setActiveTab] = useState('');
    const handleTabChange = (status: string) => {
        setActiveTab(status);
        setFilterByStatus(status);
        setShowInnerTable(null);
        setPage(1)
        setPageSize(10)
    };

    useEffect(() => {
        if (filterByStatus === "" || searchStr === "" || sortOrder === "" || sortBy === "") {
            refetch();
        }
    }, [filterByStatus, searchStr, sortOrder, sortBy]);


    const fetchExpenseItemData = async (expenseId: any, token: any) => {
        const headers = {
            Authorization: `Bearer ${token}`, // Replace 'Bearer' with your token type if needed
            // Add any other headers you need here
        };
        const response = await fetch(`${baseURL}/api/Expense${API_END_POINTS.expenseItemById}?ExpenseId=${expenseId}`, {
            headers,
        });
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    };


    const handleRowClick = async (rowId: any, expenseId: any) => {
        if (showInnerTable === rowId) {
            setShowInnerTable(null);
        } else {
            setShowInnerTable(rowId);
            if (expenseId) {
                setExpenseItemDataIsLoading(true);
                try {
                    const data = await fetchExpenseItemData(expenseId, token);
                    setExpenseItemData(data);
                } catch (error) {
                    setExpenseItemData(null);
                } finally {
                    setExpenseItemDataIsLoading(false); // Set loading state to false after fetching data
                }
            }
        }
    };
    const handleRefresh = () => {
        setShowInnerTable(null)
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success('Data Refresh successfully!');
                }
            });
    };
    const [showUpdateExpenseModal, setShowUpdateExpenseModal] = useState(false)
    const [editData, setEditData] = useState({});

    const updateModalhandler = () => {
        setShowUpdateExpenseModal((prev) => !prev);
    };

    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState(false)
    // const [expenseDeleteData, setExpenseDeleteData] = useState({});

    // const deleteHandler = () => {
    //     setShowExpenseDeleteModal((prev) => !prev);
    // };


    const [showExpenseDetailModal, setShowExpenseDetailModal] = useState(false)
    const [expenseDetailData, setExpenseDetailData] = useState({});

    const expenseDetailHandler = () => {
        setShowExpenseDetailModal((prev) => !prev);
    };

    const [showExpenseConfirmationModal, setShowExpenseConfirmationModal] = useState(false)
    const [expenseConfirmationData, setExpenseConfirmationData] = useState({});
    const [recallExpense, setRecallExpense] = useState(0);


    const expenseConfirmationModalHandler = () => {
        setShowExpenseConfirmationModal((prev) => !prev);
    };


    const [showExpenseLogModal, setShowExpenseLogModal] = useState(false)
    const [expenseLogData, setExpenseLogData] = useState({});
    const expenseLogHandler = () => {
        setShowExpenseLogModal((prev) => !prev);
    };

    const [showUpdateExpenseStatusModal, setShowUpdateExpenseStatusModal] = useState(false)
    const [editExpenseStatus, setEditExpenseStatus] = useState({});
    const editExpenseStatusHandler = () => {
        setShowUpdateExpenseStatusModal((prev) => !prev);
    };
    const [showClarificationModal, setShowClarificationModal] = useState(false)
    const clarificationHandler = () => {
        setShowClarificationModal((prev) => !prev);
    };

    const [showExpenseDetailWithOcrModal, setShowExpenseDetailWithOcrModal] = useState(false)
    const expenseDetailWithOcrHandler = () => {
        setShowExpenseDetailWithOcrModal((prev) => !prev);
    };
    const [showExpenseItemViolationsModal, setShowExpenseItemViolationsModal] = useState(false)
    const [expenseItemId, setExpenseItemId] = useState();

    const expenseItemViolationsHandler = () => {
        setShowExpenseItemViolationsModal((prev) => !prev);
    };
    const [showExpenseApproversModal, setShowExpenseApproversModal] = useState(false)
    const expenseApproversHandler = () => {
        setShowExpenseApproversModal((prev) => !prev);
    };

    const [showUpdateExpenseItemModal, setShowUpdateExpenseItemModal] = useState(false)

    const expenseItemModalHandler = () => {
        setShowUpdateExpenseItemModal((prev) => !prev);
    };

    const [prevModalState, setPrevModalState] = useState({
        showCreateExpenseModal: false,
        showExpenseDeleteModal: false,
        showExpenseConfirmationModal: false,
        showExpenseLogModal: false,
        showUpdateExpenseStatusModal: false,
        showExpenseDetailModal: false,
        showExpenseDetailWithOcrModal: false,
        showExpenseItemViolationsModal: false,
        showUpdateExpenseModal: false,
        showUpdateExpenseItemModal: false
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            showCreateExpenseModal: showCreateExpenseModal,
            showExpenseDeleteModal: showExpenseDeleteModal,
            showExpenseConfirmationModal: showExpenseConfirmationModal,
            showExpenseLogModal: showExpenseLogModal,
            showUpdateExpenseStatusModal: showUpdateExpenseStatusModal,
            showExpenseDetailModal: showExpenseDetailModal,
            showExpenseDetailWithOcrModal: showExpenseDetailWithOcrModal,
            showExpenseItemViolationsModal: showExpenseItemViolationsModal,
            showUpdateExpenseModal: showUpdateExpenseModal,
            showUpdateExpenseItemModal: showUpdateExpenseItemModal
        });
    }, [showCreateExpenseModal,
        showExpenseDeleteModal,
        showExpenseConfirmationModal,
        showExpenseLogModal,
        showUpdateExpenseStatusModal,
        showExpenseDetailModal,
        showExpenseDetailWithOcrModal,
        showExpenseItemViolationsModal,
        showUpdateExpenseModal,
        showUpdateExpenseItemModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.showCreateExpenseModal && !showCreateExpenseModal) ||
            (prevModalState.showExpenseDeleteModal && !showExpenseDeleteModal) ||
            (prevModalState.showExpenseConfirmationModal && !showExpenseConfirmationModal) ||
            (prevModalState.showExpenseLogModal && !showExpenseLogModal) ||
            (prevModalState.showUpdateExpenseStatusModal && !showUpdateExpenseStatusModal) ||
            (prevModalState.showExpenseDetailModal && !showExpenseDetailModal) ||
            (prevModalState.showExpenseDetailWithOcrModal && !showExpenseDetailWithOcrModal) ||
            (prevModalState.showExpenseItemViolationsModal && !showExpenseItemViolationsModal) ||
            (prevModalState.showUpdateExpenseModal && !showUpdateExpenseModal) ||
            (prevModalState.showUpdateExpenseItemModal && !showUpdateExpenseItemModal)

        ) {
            refetch();
            setShowInnerTable(null)
        }
    }, [
        showCreateExpenseModal,
        showExpenseDeleteModal,
        showExpenseConfirmationModal,
        showExpenseLogModal,
        showUpdateExpenseStatusModal,
        showExpenseDetailModal,
        showExpenseDetailWithOcrModal,
        showExpenseItemViolationsModal,
        showUpdateExpenseModal,
        showUpdateExpenseItemModal,
        prevModalState.showCreateExpenseModal,
        prevModalState.showExpenseDeleteModal,
        prevModalState.showExpenseConfirmationModal,
        prevModalState.showExpenseLogModal,
        prevModalState.showUpdateExpenseStatusModal,
        prevModalState.showExpenseDetailModal,
        prevModalState.showExpenseDetailWithOcrModal,
        prevModalState.showExpenseItemViolationsModal,
        prevModalState.showUpdateExpenseModal,
        prevModalState.showUpdateExpenseItemModal,
        refetch,
    ]);

    useEffect(() => {
        if (showExpenseItemViolationsModal === false || showExpenseDetailWithOcrModal === false || showUpdateExpenseModal === false) {
            setShowInnerTable(null)
        }
    }, [showExpenseItemViolationsModal, showExpenseDetailWithOcrModal, showUpdateExpenseModal])


    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    useEffect(() => {
        // Check the current route and set the selected value accordingly
        if (location.pathname === `${allRoutes.expenseReports}/pending`) {
            setFilterByStatus('Draft');
        }
        else if (location.pathname === `${allRoutes.expenseReports}/approved`) {
            setFilterByStatus('Manager Approved');
            setActiveTab('Manager Approved')
        }
        else if (location.pathname === `${allRoutes.expenseReports}/apApproved`) {
            setFilterByStatus('AP Approved');
            setActiveTab('AP Approved')
        }
        else {
            // Set the default value here if needed
            setFilterByStatus('');
        }
    }, [location.pathname]);

    return (
        <>
            {showCreateExpenseModal &&
                <CreateAppModal show={showCreateExpenseModal} handleClose={() => setShowCreateExpenseModal(false)} />
            }
            <UpdateExpenseModal show={showUpdateExpenseModal} handleClose={() => setShowUpdateExpenseModal(false)} data={editData} expenseItems={expenseItemData?.result} />
            {/* <DeleteExpenseModal show={showExpenseDeleteModal} handleClose={() => setShowExpenseDeleteModal(false)} data={expenseDeleteData} /> */}
            <ExpenseDetailModal show={showExpenseDetailModal} handleClose={() => setShowExpenseDetailModal(false)} data={expenseDetailData} expenseItems={expenseItemData?.result} />
            <ConfirmationExpenseModal show={showExpenseConfirmationModal} handleClose={() => setShowExpenseConfirmationModal(false)} data={expenseConfirmationData} recallExpense={recallExpense} />
            <ExpenseLogModal show={showExpenseLogModal} handleClose={() => setShowExpenseLogModal(false)} data={expenseLogData} />
            <ExpenseStatusUpdate show={showUpdateExpenseStatusModal} handleClose={() => setShowUpdateExpenseStatusModal(false)} data={editExpenseStatus} />
            <ClarificationModal show={showClarificationModal} handleClose={() => setShowClarificationModal(false)} data={editData} />
            <ExpenseDetailWithOcrModal show={showExpenseDetailWithOcrModal} handleClose={() => setShowExpenseDetailWithOcrModal(false)} data={expenseDetailData} expenseItemId={expenseItemId} />
            <ExpenseItemViolationModal show={showExpenseItemViolationsModal} handleClose={() => setShowExpenseItemViolationsModal(false)} data={expenseDetailData} expenseItemId={expenseItemId} />
            <ExpenseApproverModal show={showExpenseApproversModal} handleClose={() => setShowExpenseApproversModal(false)} data={expenseId} />
            <UpdateExpenseItemModal
                show={showUpdateExpenseItemModal}
                handleClose={() => setShowUpdateExpenseItemModal(false)}
                data={showExpenseItemData}
                viewOnlyData={false} />
            <Tooltip id="web-source" place="top" />
            <Tooltip id="mobile-source" place="top" />
            <Tooltip id="bank-source" place="top" />

            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header align-items-center border-0 pt-5'>
                    <div className='d-flex align-items-center gap-2 overflow-auto'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content" }}>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Draft' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Draft')}
                            >
                                {labelKey.draft}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Pending for Approval' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Pending for Approval')}
                            >
                                {labelKey.pendingForApproval}
                            </button>
                            {/* {data?.result?.data[0]?.userAccount?.orgUserID === userAccountID ? */}
                            <>
                                {roleID !== constraintConfig.roleID.role2 &&
                                    <>
                                        {/* <button
                                                type='button'
                                                className={`btn btn-sm ${activeTab === 'Clarification' ? 'btn-primary' : 'btn-light-primary'}`}
                                                onClick={() => handleTabChange('Clarification')}
                                            >
                                                {labelKey.clarification}
                                            </button> */}
                                        <button
                                            type='button'
                                            className={`btn btn-sm ${activeTab === 'Rejected' ? 'btn-primary' : 'btn-light-primary'}`}
                                            onClick={() => handleTabChange('Rejected')}
                                        >
                                            {labelKey.rejected}
                                        </button>
                                        <button
                                            type='button'
                                            className={`btn btn-sm ${activeTab === 'Manager Approved' ? 'btn-primary' : 'btn-light-primary'}`}
                                            onClick={() => handleTabChange('Manager Approved')}
                                        >
                                            {labelKey.managerApproved}
                                        </button>
                                    </>}
                            </>
                            {/* : */}
                            {/* <>
                                    <button
                                        type='button'
                                        className={`btn btn-sm ${activeTab === 'Rejected' ? 'btn-primary' : 'btn-light-primary'}`}
                                        onClick={() => handleTabChange('Rejected')}
                                    >
                                        {labelKey.rejected}
                                    </button>
                                    <button
                                        type='button'
                                        className={`btn btn-sm ${activeTab === 'Manager Approved' ? 'btn-primary' : 'btn-light-primary'}`}
                                        onClick={() => handleTabChange('Manager Approved')}
                                    >
                                        {labelKey.managerApproved}
                                    </button>
                                </> */}
                            {/* } */}
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'AP Approved' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('AP Approved')}
                            >
                                {labelKey.aPApproved}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === 'Paid' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('Paid')}
                            >
                                {labelKey.paid}
                            </button>
                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === '' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('')}
                            >
                                {labelKey.all}
                            </button>
                        </div>
                        <Tooltip id="refreshData" place="top" />
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>
                    <div className='d-flex align-items-center flex-column flex-md-row w-100 w-md-auto mt-2 m-md-0'>
                        <SearchBarComponent
                            placeholder='Expense'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                            className='me-3'
                        />
                        <div className='d-flex align-items-center gap-2'>
                            <FilterMenu
                                onclickApplyFilters={applyFilters}
                                onclickResetFilters={resetFilters}
                                isApplyingFiltersLoading={isApplyingFilters}
                            >
                                <>
                                    <div className='mb-5'>
                                        {/* <label className='form-label fw-bold text-dark fs-6'>Date:</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className="form-control form-control-lg form-control-solid default-input"
                                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker> */}
                                        <DateRangeComp
                                            startDate={localFilterByStartDate || moment()}
                                            endDate={localFilterByEndDate || moment()}
                                            onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}
                                            value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "MM/DD/YYYY"} />
                                    </div>
                                    <div>
                                        <label className='form-label fs-5 fw-bold'>{labelKey.expenseType}:</label>
                                        <Select
                                            options={[
                                                // { value: '0', label: 'All' }, // Add the empty option
                                                ...(Array.isArray(getAllExpenseType?.result) ? getAllExpenseType.result.map((option: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                })) : [])
                                            ]}
                                            placeholder={
                                                localFilterByExpType && Array.isArray(getAllExpenseType?.result)
                                                    ? getAllExpenseType.result.find((option: any) => option.id === localFilterByExpType)?.value
                                                    : 'Select Type'
                                            }
                                            value={localFilterByExpType}
                                            onChange={handleFilterByExpType}
                                        />
                                    </div>
                                </>
                            </FilterMenu>
                            <div className='card-toolbar'>
                                {data?.result?.data[0]?.userAccount?.orgUserID === userAccountID && <>
                                    <Link to={allRoutes.expenseWizard}>
                                        <button className='btn btn-sm btn-light-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                            {labelKey.newExpense}
                                        </button>
                                    </Link>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('trackingId') && (
                                        <TableHeading
                                            label={labelKey.reportId}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='trackingId'
                                            className="ps-4"
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseType') && (
                                        <TableHeading
                                            label={labelKey.type}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseType'
                                        />
                                    )}
                                    {hiddenColumns.includes('title') && (
                                        <TableHeading
                                            label={labelKey.title}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='title'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseDetail') && (
                                        <TableHeading
                                            label={labelKey.description}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseDetail'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('amount') && (
                                        <TableHeading
                                            label={labelKey.totalAmount}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='amount'
                                            labelClassName="text-end"
                                        />
                                    )}
                                    {hiddenColumns.includes('reportDate') && (
                                        <TableHeading
                                            label={labelKey.reportDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='reportDate'
                                            className="text-center w-100px"
                                        />
                                    )}
                                    {hiddenColumns.includes('expenseStatus') && (
                                        <TableHeading
                                            label={labelKey.status}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='expenseStatus'
                                        />
                                    )}
                                    {hiddenColumns.includes('manager') && (
                                        <TableHeading
                                            label={labelKey.assignedTo}
                                            columnId='manager'
                                        />
                                    )}
                                    {hiddenColumns.includes('comments') && (
                                        <TableHeading
                                            label={labelKey.comments}
                                            columnId='comments'
                                        />
                                    )}
                                    {hiddenColumns.includes('createdDate') && (
                                        <TableHeading
                                            label={labelKey.createdDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='createdDate'
                                        />
                                    )}
                                    {hiddenColumns.includes('updatedDate') && (
                                        <TableHeading
                                            label={labelKey.updatedDate}
                                            sortedColumn={sortedColumn}
                                            sortOrder={sortOrder}
                                            handleSort={handleSort}
                                            columnId='updatedDate'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.reportId}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("trackingId")}
                                                checked={hiddenColumns.includes('trackingId')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.type}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseType")}
                                                checked={hiddenColumns.includes('expenseType')}
                                                fieldClass='mb-4'
                                            />

                                            <TextField
                                                rightLabel={labelKey.title}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("title")}
                                                checked={hiddenColumns.includes('title')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.description}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseDetail")}
                                                checked={hiddenColumns.includes('expenseDetail')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.totalAmount}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("amount")}
                                                checked={hiddenColumns.includes('amount')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.reportDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("reportDate")}
                                                checked={hiddenColumns.includes('reportDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.status}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("expenseStatus")}
                                                checked={hiddenColumns.includes('expenseStatus')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.assignedTo}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("manager")}
                                                checked={hiddenColumns.includes('manager')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.comments}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("comments")}
                                                checked={hiddenColumns.includes('comments')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.createdDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("createdDate")}
                                                checked={hiddenColumns.includes('createdDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.updatedDate}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("updatedDate")}
                                                checked={hiddenColumns.includes('updatedDate')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading &&
                                    <>
                                        {id ?
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <>
                                                                <tr key={data.expenseId}
                                                                    className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                                    onClick={() => {
                                                                        handleRowClick(index, data?.expenseId);
                                                                    }}>
                                                                    {hiddenColumns.includes('trackingId') && (
                                                                        <td className='ps-5'>{data?.trackingId}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseType') && (
                                                                        <td>{data?.expenseType?.title}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('title') && (
                                                                        <td>{data?.title}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseDetail') && (
                                                                        <td className='text-wrap'>
                                                                            {/* {data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail} */}
                                                                            {data && data.expenseDetail && <ReactReadMoreReadLess
                                                                                charLimit={40}
                                                                                readMoreText={"Read more"}
                                                                                readLessText={"Read less"}
                                                                                readMoreClassName="readMore"
                                                                                readLessClassName="readLess"
                                                                            >
                                                                                {data.expenseDetail}
                                                                            </ReactReadMoreReadLess>}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('amount') && (
                                                                        <td className='text-end'>
                                                                            <CurrencyComp amount={data?.amount} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('reportDate') && (
                                                                        <td className='text-center'><DateComp formattedDate={data?.reportDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseStatus') && (
                                                                        <td>
                                                                            {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ?
                                                                                <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                                    <span className="badge badge-light-info fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                    // data?.expenseStatus.id === constraintConfig.expenseStatus.clarification ?
                                                                                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.approved ?
                                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                        data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.apApproved ?
                                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.processing ?
                                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.paid ?
                                                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                        <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span>
                                                                            }
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('manager') && (
                                                                        <td>
                                                                            {data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval || data?.expenseStatus.id === constraintConfig.expenseStatus.approved ? (
                                                                                <>
                                                                                    {data?.manager?.firstName &&
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='symbol symbol-40px symbol-circle me-3'>
                                                                                                {/* {data?.manager?.imageUrl ? <img src={data?.manager?.imageUrl} alt="" /> : */}
                                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.manager?.firstName?.charAt(0)}</div>
                                                                                                {/* } */}
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                                <p className='text-capitalize mb-0'>
                                                                                                    {data?.manager?.firstName}  {data?.manager?.middleName} {data?.manager?.lastName}
                                                                                                </p>
                                                                                                <span className='text-muted fw-semibold text-muted text-lowercase d-block fs-7'>
                                                                                                    {data?.manager?.email}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                            ) : ""}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('comments') && (
                                                                        <td className='text-wrap'>
                                                                            {/* {data?.comment && data?.comment.length > 20 ? `${data?.comment.substring(0, 20)}...` : data?.comment} */}
                                                                            {data && data.comment && <ReactReadMoreReadLess
                                                                                charLimit={30}
                                                                                readMoreText={"Read more"}
                                                                                readLessText={"Read less"}
                                                                                readMoreClassName="readMore"
                                                                                readLessClassName="readLess"
                                                                            >
                                                                                {data.comment}
                                                                            </ReactReadMoreReadLess>}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('createdDate') && (
                                                                        <td><DateTimeComp formattedDate={data?.createdDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('updatedDate') && (
                                                                        <td><DateTimeComp formattedDate={data?.updatedDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('actions') && (
                                                                        <td className='text-end' >
                                                                            <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="transparent" id="dropdown-basic" className='remove-arrow p-2'>
                                                                                        <BiDotsVerticalRounded />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item>
                                                                                            <div onClick={() => {
                                                                                                setExpenseDetailData(data);
                                                                                                expenseDetailHandler();
                                                                                            }}>
                                                                                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                    <KTSVG
                                                                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                                                                        className='svg-icon-3'
                                                                                                    />
                                                                                                </div>
                                                                                                <span>Detail</span>
                                                                                            </div>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item>
                                                                                            <div onClick={() => {
                                                                                                setExpenseLogData(data?.expenseId);
                                                                                                expenseLogHandler();
                                                                                            }}>
                                                                                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                    <FaHistory className='text-dark text-hover-primary' />
                                                                                                </div>
                                                                                                <span>Expense Logs</span>
                                                                                            </div>
                                                                                        </Dropdown.Item>
                                                                                        {/* {(roleID === constraintConfig.roleID.role2) && ["Rejected", "Approved"].includes(data?.expenseStatus.title) && (
                                                                                        <Dropdown.Item>
                                                                                            <div onClick={() => {
                                                                                                setEditExpenseStatus(data);
                                                                                                editExpenseStatusHandler();
                                                                                            }}>
                                                                                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                                                </div>
                                                                                                <span>Status Update</span>
                                                                                            </div>
                                                                                        </Dropdown.Item>
                                                                                    )} */}
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>

                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                {showInnerTable === index && (
                                                                    <tr className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}>
                                                                        <td className='pt-0 px-3' colSpan={20}>
                                                                            <div className="table-responsive">
                                                                                <table className="table align-middle gs-0 gy-4">
                                                                                    <thead>
                                                                                        <tr className='fw-bold text-muted bg-light'>
                                                                                            {/* <th className='ps-4 rounded-start text-center w-60px'>Sr</th> */}
                                                                                            <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                            <th>{labelKey.category}</th>
                                                                                            {/* <th>{labelKey.currency}</th> */}
                                                                                            <th>{labelKey.amount}</th>
                                                                                            <th className='w-150px'>{labelKey.reimbursableDistance}</th>
                                                                                            <th>{labelKey.vendor}</th>
                                                                                            <th>{labelKey.paymentMethod}</th>
                                                                                            <th>{labelKey.attachment}</th>
                                                                                            <th>{labelKey.description}</th>
                                                                                            <th>{labelKey.source}</th>
                                                                                            {(data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted) && (
                                                                                                <th className='text-end pe-3'>{labelKey.action}</th>
                                                                                            )}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                                            <tr key={itemData?.expenseItemId}>
                                                                                                {/* <td className='text-center'>
                                                                                                    {index + 1}
                                                                                                </td> */}
                                                                                                <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                                <td className='ps-5'>{itemData?.expenseCategory?.title}</td>
                                                                                                {/* <td>{itemData?.currency?.title}</td> */}
                                                                                                <td>
                                                                                                    <CurrencyComp amount={itemData?.amount} />
                                                                                                </td>
                                                                                                <td className='text-end'>
                                                                                                    {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                        <>
                                                                                                            {itemData?.reimbursableDistance}
                                                                                                        </>
                                                                                                        : null
                                                                                                    }
                                                                                                </td>
                                                                                                <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                                <td>{itemData?.paymentMethod?.title}</td>
                                                                                                <td>
                                                                                                    {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null || itemData?.attachmentFile === "" ?
                                                                                                        <span className='ps-9'>N/A</span> :
                                                                                                        <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                    }
                                                                                                    {lightboxOpen && (
                                                                                                        <Lightbox
                                                                                                            mainSrc={lightboxData.imageURL}
                                                                                                            onCloseRequest={() => setLightboxOpen(false)}
                                                                                                            imageCaption="Attachment"
                                                                                                            enableZoom={true}
                                                                                                            imagePadding={50}
                                                                                                        />
                                                                                                    )}
                                                                                                </td>
                                                                                                <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                                <td>
                                                                                                    {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                        <img src="/media/icons/figmaIcons/Web.svg"
                                                                                                            width={20} height={20}
                                                                                                            className='ms-4' alt=""
                                                                                                            data-tooltip-id="web-source" data-tooltip-content='Website' /> :
                                                                                                        itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                            <img src="/media/icons/figmaIcons/Mobile.svg"
                                                                                                                width={20} height={20}
                                                                                                                className='ms-4' alt=""
                                                                                                                data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                            itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                                <img src="/media/icons/figmaIcons/Bank.svg"
                                                                                                                    width={20} height={20}
                                                                                                                    className='ms-4' alt=""
                                                                                                                    data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                                : null}
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={20}>
                                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                                    No matching records found
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </>
                                            :
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <Fragment key={index}>
                                                                <Tooltip id="my-tooltip" place="bottom" />
                                                                <tr
                                                                    data-tooltip-id="my-tooltip" data-tooltip-content={labelKey.clickHereToExpand}
                                                                    className={`cursor-pointer ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                                    onClick={() => {
                                                                        handleRowClick(index, data?.expenseId);
                                                                    }}>
                                                                    {hiddenColumns.includes('trackingId') && (
                                                                        <td className='ps-5'>{data?.trackingId}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseType') && (
                                                                        <td>{data?.expenseType?.title}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('title') && (
                                                                        <td>{data?.title}</td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseDetail') && (
                                                                        <td className='text-wrap'>
                                                                            {/* {data?.expenseDetail?.length > 60 ? `${data?.expenseDetail.substring(0, 40)}...` : data?.expenseDetail} */}
                                                                            {data && data.expenseDetail && <ReactReadMoreReadLess
                                                                                charLimit={40}
                                                                                readMoreText={"Read more"}
                                                                                readLessText={"Read less"}
                                                                                readMoreClassName="readMore"
                                                                                readLessClassName="readLess"
                                                                            >
                                                                                {data.expenseDetail}
                                                                            </ReactReadMoreReadLess>}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('amount') && (
                                                                        <td className='text-end'>
                                                                            <CurrencyComp amount={data?.amount} />
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('reportDate') && (
                                                                        <td className='text-center'><DateComp formattedDate={data?.reportDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('expenseStatus') && (
                                                                        <td>
                                                                            {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ?
                                                                                <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ?
                                                                                    <span className="badge badge-light-info fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                    // data?.expenseStatus.id === constraintConfig.expenseStatus.clarification ?
                                                                                    //     <span className="badge badge-light-warning fs-7 fw-semibold">{labelKey.clarification}</span> :
                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.approved ?
                                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                        data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                            data?.expenseStatus.id === constraintConfig.expenseStatus.apApproved ?
                                                                                                <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.processing ?
                                                                                                    <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.paid ?
                                                                                                        <span className="badge badge-light-success fs-7 fw-semibold">{data?.expenseStatus.title}</span> :
                                                                                                        <span className="badge badge-light-primary fs-7 fw-semibold">{data?.expenseStatus.title}</span>
                                                                            }
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('manager') && (
                                                                        <td>
                                                                            {data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval || data?.expenseStatus.id === constraintConfig.expenseStatus.approved ? (
                                                                                <>
                                                                                    {data?.manager?.firstName &&
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='symbol symbol-40px symbol-circle me-3'>
                                                                                                {/* {data?.manager?.imageUrl ? <img src={data?.manager?.imageUrl} alt="" /> : */}
                                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.manager?.firstName?.charAt(0)}</div>
                                                                                                {/* } */}
                                                                                            </div>
                                                                                            <div className='d-flex justify-content-start flex-column'>
                                                                                                <p className='text-capitalize mb-0'>
                                                                                                    {data?.manager?.firstName}  {data?.manager?.middleName} {data?.manager?.lastName}
                                                                                                </p>
                                                                                                <span className='text-muted fw-semibold text-muted text-lowercase d-block fs-7'>
                                                                                                    {data?.manager?.email}
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                            ) : ""}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('comments') && (
                                                                        <td className='text-wrap'>
                                                                            {/* {data?.comment && data?.comment.length > 20 ? `${data?.comment.substring(0, 20)}...` : data?.comment} */}
                                                                            {data && data.comment && <ReactReadMoreReadLess
                                                                                charLimit={30}
                                                                                readMoreText={"Read more"}
                                                                                readLessText={"Read less"}
                                                                                readMoreClassName="readMore"
                                                                                readLessClassName="readLess"
                                                                            >
                                                                                {data.comment}
                                                                            </ReactReadMoreReadLess>}
                                                                        </td>
                                                                    )}
                                                                    {hiddenColumns.includes('createdDate') && (
                                                                        <td><DateTimeComp formattedDate={data?.createdDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('updatedDate') && (
                                                                        <td><DateTimeComp formattedDate={data?.updatedDate} /></td>
                                                                    )}
                                                                    {hiddenColumns.includes('actions') && (
                                                                        <td className='text-end' >
                                                                            <Tooltip id="expense-violation" place="top" />
                                                                            <Tooltip id={labelKey.submitForApproval} place="top" />
                                                                            <Tooltip id={labelKey.updateExpense} place="top" />

                                                                            <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                                {data?.isViolation === true &&
                                                                                    <div
                                                                                        data-tooltip-id="expense-violation" data-tooltip-content='Expense violation'
                                                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                        onClick={() => {
                                                                                            setExpenseDetailData(data);
                                                                                            expenseDetailWithOcrHandler();
                                                                                        }}
                                                                                    >
                                                                                        <IoIosWarning className='text-danger h-15px w-15px' />
                                                                                    </div>
                                                                                }
                                                                                {(data?.expenseStatus.id === constraintConfig.expenseStatus.drafted || data?.expenseStatus.id === constraintConfig.expenseStatus.rejected) && (
                                                                                    <>
                                                                                        <div
                                                                                            data-tooltip-id={labelKey.submitForApproval} data-tooltip-content={labelKey.submitForApproval}
                                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                            onClick={() => {
                                                                                                setRecallExpense(0)
                                                                                                setExpenseDetailData(data);
                                                                                                setExpenseConfirmationData(data?.expenseId);
                                                                                                expenseConfirmationModalHandler();
                                                                                            }}
                                                                                        >
                                                                                            <FaPaperPlane className='text-gray-800' />
                                                                                        </div>
                                                                                        <div
                                                                                            data-tooltip-id={labelKey.updateExpense} data-tooltip-content={labelKey.updateExpense}
                                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                            onClick={() => {
                                                                                                setEditData(data);
                                                                                                setExpenseId(data?.expenseId)
                                                                                                updateModalhandler();
                                                                                            }}
                                                                                        >
                                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                                        </div>
                                                                                    </>
                                                                                )}

                                                                                <div>
                                                                                    <Dropdown>
                                                                                        <Dropdown.Toggle variant="transparent" id="dropdown-basic" className='remove-arrow p-2'>
                                                                                            <BiDotsVerticalRounded />
                                                                                        </Dropdown.Toggle>

                                                                                        <Dropdown.Menu>
                                                                                            {(data?.expenseStatus.id === constraintConfig.expenseStatus.processing ||
                                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.pendingForApproval ||
                                                                                                data?.expenseStatus.id === constraintConfig.expenseStatus.approved
                                                                                            ) && (
                                                                                                    <Dropdown.Item>
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                setExpenseConfirmationData('');
                                                                                                                setRecallExpense(data);
                                                                                                                expenseConfirmationModalHandler();
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>

                                                                                                                <RiArrowGoBackFill className='text-dark text-hover-primary' />
                                                                                                                {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                                                                                            </div>
                                                                                                            <span>{labelKey.recallExpense}</span>
                                                                                                        </div>
                                                                                                    </Dropdown.Item>
                                                                                                )}
                                                                                            {(data?.expenseStatus.id === constraintConfig.expenseStatus.rejected) && (
                                                                                                <Dropdown.Item>
                                                                                                    <div
                                                                                                        onClick={() => {
                                                                                                            setEditData(data);
                                                                                                            clarificationHandler();
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                            <FaExclamationCircle className='text-gray text-hover-primary' />
                                                                                                            {/* <i className="fa fa-exclamation-circle" aria-hidden="true"></i> */}
                                                                                                        </div>
                                                                                                        <span>{labelKey.clarification}</span>
                                                                                                    </div>
                                                                                                </Dropdown.Item>
                                                                                            )}
                                                                                            {(data?.expenseStatus.id === constraintConfig.expenseStatus.drafted || data?.expenseStatus.id === constraintConfig.expenseStatus.rejected) && (
                                                                                                <>
                                                                                                    <Dropdown.Item>
                                                                                                        <div
                                                                                                            onClick={() => {
                                                                                                                setRecallExpense(0)
                                                                                                                setExpenseDetailData(data);
                                                                                                                setExpenseConfirmationData(data?.expenseId);
                                                                                                                expenseConfirmationModalHandler();
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                                <FaPaperPlane className='text-dark text-hover-primary' />
                                                                                                            </div>
                                                                                                            <span>{labelKey.submitForApproval}</span>
                                                                                                        </div>
                                                                                                    </Dropdown.Item>
                                                                                                    {/* {expenseItemData?.result.some((itemData: any) => itemData?.recordSourceId !== constraintConfig.recordSourceId.BankFeed) && */}
                                                                                                    <Dropdown.Item>
                                                                                                        <div onClick={() => {
                                                                                                            setEditData(data);
                                                                                                            setExpenseId(data?.expenseId)
                                                                                                            updateModalhandler();
                                                                                                        }}>
                                                                                                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                                            >
                                                                                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                                                            </div>
                                                                                                            <span>{labelKey.updateExpense}</span>
                                                                                                        </div>
                                                                                                    </Dropdown.Item>
                                                                                                    {/* } */}
                                                                                                </>
                                                                                            )}
                                                                                            <Dropdown.Item>
                                                                                                <div onClick={() => {
                                                                                                    setExpenseDetailData(data);
                                                                                                    expenseDetailHandler();
                                                                                                }}>
                                                                                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                        <KTSVG
                                                                                                            path='/media/icons/duotune/arrows/arr064.svg'
                                                                                                            className='svg-icon-3'
                                                                                                        />
                                                                                                    </div>
                                                                                                    <span>{labelKey.details}</span>
                                                                                                </div>
                                                                                            </Dropdown.Item>
                                                                                            {(data?.expenseStatus.title !== "Drafted" &&
                                                                                                <Dropdown.Item>
                                                                                                    <div onClick={() => {
                                                                                                        setExpenseLogData(data);
                                                                                                        expenseLogHandler();
                                                                                                    }}>
                                                                                                        <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                                                                                            <FaHistory className='text-dark text-hover-primary' />
                                                                                                        </div>
                                                                                                        <span>{labelKey.expenseLogs}</span>
                                                                                                    </div>
                                                                                                </Dropdown.Item>
                                                                                            )}

                                                                                            {data?.expenseStatus.title !== "AP Approved" && <>
                                                                                                {roleID === constraintConfig.roleID.role5 ||
                                                                                                    roleID === constraintConfig.roleID.role4

                                                                                                    ?
                                                                                                    <Dropdown.Item>
                                                                                                        <div onClick={() => {
                                                                                                            setExpenseId(data)
                                                                                                            expenseApproversHandler();
                                                                                                        }}

                                                                                                        >
                                                                                                            <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 text-hover-primary'>
                                                                                                                <FaCheck className='text-muted text-hover-primary svg-icon-3' />
                                                                                                            </div>
                                                                                                            <span>{labelKey.approvers}</span>
                                                                                                        </div>
                                                                                                    </Dropdown.Item>
                                                                                                    : null
                                                                                                }
                                                                                            </>}
                                                                                            <Dropdown.Item>
                                                                                                <div>
                                                                                                    <DownloadReport data={data} label />

                                                                                                    {/* <span>{labelKey.reportDownload}</span> */}
                                                                                                </div>
                                                                                            </Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    )}
                                                                </tr>
                                                                {showInnerTable === index && (
                                                                    <tr
                                                                        className={`inner-row ${showInnerTable === index ? 'highlighted-row' : ''}`}
                                                                    >
                                                                        <td className='pt-0 px-3' colSpan={20}>
                                                                            <div className="table-responsive sticky-responsive">
                                                                                <table className="table align-middle gs-0 gy-4">
                                                                                    <thead>
                                                                                        <tr className='fw-bold text-muted bg-light'>
                                                                                            {/* <th className='ps-4 text-center w-60px'>Sr</th> */}
                                                                                            <th className='ps-4'>{labelKey.itemDate}</th>
                                                                                            <th>{labelKey.category}</th>
                                                                                            {/* <th>{labelKey.currency}</th> */}
                                                                                            <th className='w-100px text-end pe-15'>{labelKey.amount}</th>
                                                                                            <th className='w-150px pe-15 text-end'>{labelKey.reimbursableDistance}</th>
                                                                                            <th>{labelKey.vendor}</th>
                                                                                            <th>{labelKey.paymentMethod}</th>
                                                                                            {/* <th>{labelKey.location}</th> */}
                                                                                            <th>{labelKey.attachment}</th>
                                                                                            <th>{labelKey.description}</th>
                                                                                            <th>{labelKey.source}</th>
                                                                                            <th>{labelKey.updatedDate}</th>

                                                                                            {/* {(data?.expenseStatus?.id === constraintConfig.expenseStatus.drafted) && ( */}
                                                                                            <th className='text-end pe-3'>{labelKey.action}</th>
                                                                                            {/* )} */}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {!expenseItemDataIsLoading && <>
                                                                                            {expenseItemData?.result?.length > 0 ? <>
                                                                                                {expenseItemData?.result?.map((itemData: any, index: any) => (
                                                                                                    <tr key={index}>
                                                                                                        {/* <td className='text-center'>
                                                                                                            {index + 1}
                                                                                                        </td> */}
                                                                                                        <td className='ps-4'><DateComp formattedDate={itemData.itemDate} /></td>
                                                                                                        <td className='ps-5'>
                                                                                                            <CategoriesWithIcons itemData={itemData} />
                                                                                                        </td>
                                                                                                        {/* <td>{itemData?.currency?.title}</td> */}
                                                                                                        <td className={`text-end pe-15 ${itemData?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'text-danger' : null}`}>
                                                                                                            <CurrencyComp amount={itemData?.amount} />
                                                                                                        </td>
                                                                                                        <td className='text-end pe-15'>
                                                                                                            {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ?
                                                                                                                <>
                                                                                                                    {itemData?.reimbursableDistance}
                                                                                                                </>
                                                                                                                : null
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>{itemData?.vendor?.name !== " " ? itemData?.vendor?.name : "N/A"}</td>
                                                                                                        <td>{itemData?.paymentMethod?.title}</td>
                                                                                                        {/* <td>{itemData?.expItemLocation?.title ? itemData?.expItemLocation?.title : "N/A"}</td> */}
                                                                                                        <td>
                                                                                                            {/* {itemData?.attachmentFile === "undefined" || itemData?.attachmentFile === "null" || itemData?.attachmentFile === null || itemData?.attachmentFile === "" ?
                                                                                                                <span className='ps-9'>N/A</span> :
                                                                                                                <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                            } */}
                                                                                                            {itemData?.expenseCategory?.id === constraintConfig.categoriesId.mileage ? '' :
                                                                                                                itemData?.attachmentFile === null || itemData?.attachmentFile === "null" || itemData?.attachmentFile === "undefined" || data?.attachmentFile === "" || data?.attachmentFile === "Receipt is not available" ? (
                                                                                                                    itemData?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? null : (
                                                                                                                        <>
                                                                                                                            {data?.expenseStatus.id === constraintConfig.expenseStatus.drafted || data?.expenseStatus.id === constraintConfig.expenseStatus.rejected ?
                                                                                                                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-7'
                                                                                                                                    data-tooltip-id="upload-reciept" data-tooltip-content='Upload Reciept'
                                                                                                                                    onClick={() => setShowExpenseItemData(itemData)}
                                                                                                                                >

                                                                                                                                    <UploadDirectReceipt
                                                                                                                                        data={showExpenseItemData}
                                                                                                                                        fetchExpenseItemData={fetchExpenseItemData}
                                                                                                                                        setExpenseItemData={setExpenseItemData} />

                                                                                                                                </div>
                                                                                                                                : ""
                                                                                                                            }
                                                                                                                        </>)
                                                                                                                )
                                                                                                                    :

                                                                                                                    <FaEye className='ms-10' onClick={() => openPdfInBrowserOrLightbox(itemData?.attachmentFile)} />
                                                                                                            }
                                                                                                            {lightboxOpen && (
                                                                                                                <Lightbox
                                                                                                                    mainSrc={lightboxData.imageURL}
                                                                                                                    onCloseRequest={() => setLightboxOpen(false)}
                                                                                                                    imageCaption="Attachment"
                                                                                                                    enableZoom={true}
                                                                                                                    imagePadding={50}
                                                                                                                />
                                                                                                            )}
                                                                                                        </td>
                                                                                                        <td>{itemData?.itemDetail?.length > 60 ? `${itemData?.itemDetail.substring(0, 40)}...` : itemData?.itemDetail}</td>
                                                                                                        <td>
                                                                                                            {itemData?.recordSourceId === constraintConfig.recordSourceId.WebApp ?
                                                                                                                <KTSVG path="/media/icons/figmaIcons/Web.svg" className='svg-icon-1 ms-4'
                                                                                                                    svgClassName='w-20px h-20px'
                                                                                                                    data-tooltip-id="web-source" data-tooltip-content='Website' />
                                                                                                                :
                                                                                                                itemData?.recordSourceId === constraintConfig.recordSourceId.Mobile ?
                                                                                                                    <KTSVG path="/media/icons/figmaIcons/Mobile.svg" className='svg-icon-1 ms-4'
                                                                                                                        data-tooltip-id="mobile-source" data-tooltip-content='Mobile' /> :
                                                                                                                    itemData?.recordSourceId === constraintConfig.recordSourceId.BankFeed ?
                                                                                                                        <KTSVG path="/media/icons/figmaIcons/Bank.svg" className='svg-icon-1 ms-4'
                                                                                                                            data-tooltip-id="bank-source" data-tooltip-content='Bank' />
                                                                                                                        : null}
                                                                                                        </td>
                                                                                                        <td><DateTimeComp formattedDate={itemData.updatedDate} /></td>

                                                                                                        <td>
                                                                                                            <div className='d-flex gap-3 justify-content-end'>
                                                                                                                {itemData?.isViolation === true &&
                                                                                                                    <div
                                                                                                                        data-tooltip-id="expense-voilation" data-tooltip-content='Expense voilation'
                                                                                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                                                        onClick={() => {
                                                                                                                            setExpenseDetailData(data);
                                                                                                                            setExpenseItemId(itemData?.expenseItemId)
                                                                                                                            expenseItemViolationsHandler();
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <IoIosWarning className='text-danger h-15px w-15px' />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                <Tooltip id="Update-Item" place="top" />
                                                                                                                {(data?.expenseStatus.id === constraintConfig.expenseStatus.drafted ||
                                                                                                                    data?.expenseStatus.id === constraintConfig.expenseStatus.rejected) && (
                                                                                                                        // itemData?.debitOrCreditIndicator !== constraintConfig.debitOrCreditIndicator.creditIndicator &&
                                                                                                                        <div
                                                                                                                            data-tooltip-id="Update-Item" data-tooltip-content='Update Item'
                                                                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                                                                                                            onClick={() => {
                                                                                                                                setShowExpenseItemData(itemData);
                                                                                                                                expenseItemModalHandler();
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        {/* {(data?.expenseStatus?.title === "Drafted") && (
                                                                                                    <td>
                                                                                                        <div className='d-flex gap-2 justify-content-end'>

                                                                                                            <OverlayTrigger
                                                                                                                delay={{ show: 250, hide: 350 }}
                                                                                                                overlay={
                                                                                                                    <Tooltip>
                                                                                                                        Delete Expense
                                                                                                                    </Tooltip>
                                                                                                                }>
                                                                                                                <div className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                                                                                    onClick={() => {
                                                                                                                        setExpenseDeleteData(itemData?.expenseItemId);
                                                                                                                        deleteHandler();
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                                                                                                </div>
                                                                                                            </OverlayTrigger>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )} */}
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </>
                                                                                                :
                                                                                                <tr>
                                                                                                    <td colSpan={13}>
                                                                                                        <NoRecordFound />
                                                                                                    </td>
                                                                                                </tr>
                                                                                            }
                                                                                        </>}
                                                                                        {expenseItemDataIsLoading && (
                                                                                            <tr>
                                                                                                <td colSpan={13} className="text-center">
                                                                                                    <LoadingComponent />
                                                                                                </td>
                                                                                            </tr>
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={20}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {isLoading && (
                                    <tr>
                                        <td colSpan={20} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                        toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                        ofResult={data?.result?.totalRecords}
                        onChange={handlePageSizeChange}
                        pageSize={pageSize}
                        onPageChange={handlePageClick}
                        pageCount={data?.result?.totalPages || 0}
                        currentPage={page}
                    />
                </div>
            </div >


        </>
    )
}

export default ExpenseItemPage