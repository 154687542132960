/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Autocomplete,
  Libraries,
  useJsApiLoader
} from '@react-google-maps/api';
import axios from 'axios';
import moment from 'moment';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaPercentage } from 'react-icons/fa';
import { FaLock, FaTrash, FaUpload } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../../_metronic/helpers';
import CurrencyComp from '../../../../../components/currencyComponent/CurrencyComp';
import BGBlur from '../../../../../components/loadingComponent/BGBlur';
import SearchSelect from '../../../../../components/textFields/SearchSelect';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from '../../../../../features/authSlice';
import { removeRowData, updateRowData } from '../../../../../features/uploadReceiptStep2';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { useUploadOCRInvoiceMutation } from '../../../../../services/BackgroundJobApi';
import { useAllCurrencyLookupQuery, useExpenseCategoryLookupQuery, useGetAllLocationsQuery, useGetAllTripTypeQuery, useGetPaymentMethodLookupQuery } from '../../../../../services/GeneralApi';
import { useUploadFileToAzureMutation } from '../../../../../services/IntegrartionApi';
import { useGetVendorLookUpQuery } from '../../../../../services/VendorApi';
import { API_END_POINTS } from '../../../../../services/apiEndpoints';
import { baseUrl } from '../../../../../services/baseUrl';
import labelKey from "../../../../localization/label.json";
import AddUpdateVendorModal from '../../../vendorAndCard/vendor/AddUpdateVendorModal';
import DeleteItemModal from '../DeleteItemModal';
type Props = {
  formik: any
  isStep2Valid: any
  data?: any
  orgDetail?: any
}
interface LightboxData {
  imageURL: string;
}
interface WordCounts {
  [key: string]: number;
}

interface CategoryBudgetData {
  result: number;
  message: string;
  success: boolean;
  // Add any other properties if needed
}
type APIError = {
  response?: {
    data?: {
      message?: string;
      errors?: {
        itemDate?: string[];
      };
    };
  };
};
const Step2 = ({ formik, isStep2Valid, data, orgDetail }: Props) => {
  const currentDate = new Date();
  const dispatch = useAppDispatch();

  const { token, locationType, location, locationName, baseURL } = useAppSelector(selectAuth);
  const [expenseItems, setExpenseItems] = useState<any[]>(formik.values.expenseItem || []);
  // console.log('expenseItems',expenseItems);

  const [uploadFileToAzure, { data: azurefileData, isLoading: fileIsloading, error: fileError, isError: fileIsError }] = useUploadOCRInvoiceMutation();
  const [uploadFileToAzureForMilage] = useUploadFileToAzureMutation();
  const { data: expenseCategory } = useExpenseCategoryLookupQuery('')
  const { data: currency } = useAllCurrencyLookupQuery('')
  const { data: vendor, refetch: vendorRefetch } = useGetVendorLookUpQuery('')
  const { data: locationsData, refetch: locationDataRefetch } = useGetAllLocationsQuery('')
  const { data: tripTypeData, refetch: tripTypeRefetch } = useGetAllTripTypeQuery('')
  const { data: getPaymentMethod, refetch: paymentMethodRefetch } = useGetPaymentMethodLookupQuery('')
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
  const [showAddUpdateVendorModal, setShowAddUpdateVendorModal] = useState(false)
  const [newRowID, setNewRowID] = useState(0)
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<number | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const initialRows = data
    ? [...expenseItems.map((_, index) => ({ id: index, showInnerTable: true }))]
    : [{ id: 0, showInnerTable: true }];
  const [rows, setRows] = useState(initialRows);

  const [rowIds, setRowIds] = useState<number[]>([]);
  useEffect(() => {
    const ids = rows.map(row => row.id);
    setRowIds(ids);
  }, [rows]);

  const [getMileageByDate, setGetMileageByDate] = useState<any>(null); // Initialize with null
  const [milageRateIsError, setMilageRateIsError] = useState(false); // Initialize error state

  const mileageRefetch = async (id: any) => {
    if (parseInt(formik.values.expenseItem[id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const getExpenseLogResponse = await axios.get(
          `${baseURL}/api/Expense${API_END_POINTS.getMileagebyDate}?date=${formik.values?.expenseItem[id]?.itemDate || ''}`,
          {
            headers,
          }
        );
        const getMileageByDate = getExpenseLogResponse.data;
        setGetMileageByDate(getMileageByDate);
      } catch (error) {
        console.error(`Error fetching data for id ${id}: `, error);
        setMilageRateIsError(true);
      } finally {
      }
    }
  };

  const [getCategoryBudget, setGetCategoryBudget] = useState<{ [key: number]: CategoryBudgetData }>({});
  const [getCategoryBudgetError, setGetCategoryBudgetError] = useState<string | null>(null);

  // console.log('getCategoryBudgetError', getCategoryBudgetError);

  const categoryBudgetRefetch = async (id: any,) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const getExpenseLogResponse = await axios.get(
        `${baseURL}/api/Budget${API_END_POINTS.getRemainingCatagoryBudgetOfUser}?catagoryId=${formik.values?.expenseItem[id]?.expenseCategory?.id || 0}&itemDate=${formik.values?.expenseItem[id]?.itemDate || ''}`,
        {
          headers,
        }
      );
      return getExpenseLogResponse.data;
    } catch (error) {
      // console.error(`Error fetching data for categoryId ${id}: `, error);
      const typedError = error as APIError;
      const errorMessage = typedError.response?.data?.errors?.itemDate?.[0] || 'Unknown error occurred';

      // console.log('errorMessage', errorMessage);
      setGetCategoryBudgetError(errorMessage)

      return null;
    }
  };

  // useEffect(() => {
  //   const fetchCategoryBudgets = async () => {
  //     const newCategoryBudgets: { [key: number]: CategoryBudgetData } = {};
  //     for (const id of rowIds) {
  //       const categoryBudgetData = await categoryBudgetRefetch(id);
  //       newCategoryBudgets[id] = categoryBudgetData;
  //     }
  //     setGetCategoryBudget(newCategoryBudgets);
  //   };
  //   fetchCategoryBudgets();
  // }, [formik.values.expenseItem, rowIds]);

  useEffect(() => {
    const fetchCategoryBudgets = async () => {
      const newCategoryBudgets: { [key: number]: CategoryBudgetData } = {};
      for (const id of rowIds) {
        const categoryId = formik.values.expenseItem[id]?.expenseCategory?.id;
        const itemDate = formik.values.expenseItem[id]?.itemDate;
        if (categoryId && itemDate) { // Trigger API only when both values are present
          const categoryBudgetData = await categoryBudgetRefetch(id);
          newCategoryBudgets[id] = categoryBudgetData;
        }
      }
      setGetCategoryBudget(newCategoryBudgets);
    };

    fetchCategoryBudgets();
  },
    // Memoize the dependencies to avoid infinite triggering
    [JSON.stringify(rowIds.map(id => ({
      categoryId: formik.values.expenseItem[id]?.expenseCategory?.id,
      itemDate: formik.values.expenseItem[id]?.itemDate
    })))]);


  // const handleCategoryChange = async (selectedOption: any, id: any) => {
  //   const updatedValue = selectedOption.value;
  //   const updatedLabel = selectedOption.label;

  //   // Update both the ID and title (label) in Formik's state
  //   await formik.setFieldValue(`expenseItem[${id}].expenseCategory.id`, updatedValue);
  //   await formik.setFieldValue(`expenseItem[${id}].expenseCategory.title`, updatedLabel);

  //   // Refetch category budget if necessary
  //   categoryBudgetRefetch(id);
  // };


  // const [distanceCal, setDistanceCal] = useState<any>(null);
  const [distanceError, setDistanceError] = useState(false);
  const [distanceIsLoading, setDistanceIsLoading] = useState<Record<string, boolean>>({});
  const [selectedByMouse, setSelectedByMouse] = useState(false);


  const handleTripTypeChange = async (event: any, id: any) => {
    // event.persist(); // Make the event persistent
    const updatedValue = event.value;
    // console.log('Selected tripType.id:', updatedValue);
    await formik.setFieldValue(`expenseItem[${id}].tripType.id`, updatedValue);

    if (formik.values.expenseItem[id]?.tripType.id) {
      tripTypeDistanceRefetchOnTripId(id, updatedValue);
    }
  };
  const tripTypeDistanceRefetchOnTripId = async (id: any, tripTypeId?: any) => {
    if (parseInt(formik.values.expenseItem[id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
      const { startLocation, endLocation, tripType, itemDate } = formik.values.expenseItem[id];
      if (!startLocation || !endLocation || !tripType?.id || !itemDate) {
        // toast.error('Please fill in all required fields.');
        return;
      }
      setDistanceIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [id]: true,
      }));
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const getTripDistanceResponse = await axios.get(
          `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}?source=${formik.values?.expenseItem[id]?.startLocation || ''}&destinaiton=${formik.values?.expenseItem[id]?.endLocation || ''}&tripTypeid=${tripTypeId}&itemDate=${formik.values?.expenseItem[id]?.itemDate || ''}`,
          {
            headers,
          }
        );
        const distanceCal = getTripDistanceResponse.data;

        const mileageDistance = (distanceCal?.result?.distance).toFixed(2);
        const reimbursableDistance = (distanceCal?.result?.reimburable).toFixed(2);
        const mileageRate = (distanceCal?.result?.rate).toFixed(2);
        const amount = (distanceCal?.result?.amount).toFixed(2);

        formik.setFieldValue(`expenseItem[${id}].milage`, mileageDistance);
        formik.setFieldValue(`expenseItem[${id}].reimbursableDistance`, reimbursableDistance);
        formik.setFieldValue(`expenseItem[${id}].mileageRate`, mileageRate);
        formik.setFieldValue(`expenseItem[${id}].amount`, amount);

      } catch (error) {
        console.error(`Error fetching data for id ${id}: `, error);
        setDistanceError(true);

        // Clear fields on error
        formik.setFieldValue(`expenseItem[${id}].milage`, 0);
        formik.setFieldValue(`expenseItem[${id}].reimbursableDistance`, 0);
        formik.setFieldValue(`expenseItem[${id}].mileageRate`, 0);
        formik.setFieldValue(`expenseItem[${id}].amount`, 0);
        // toast.error(`Expense Item${id + 1}: ${(error as { response?: { data?: { message?: string } } })?.response?.data?.message}`);
        const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
        if (errorMessage !== undefined) {
          toast.error(`Expense Item${id + 1}: ${errorMessage}`);
        }
      } finally {
        setDistanceIsLoading((prevLoadingState) => ({
          ...prevLoadingState,
          [id]: false,
        }));
      }
    }
  };

  // const tripTypeDistanceRefetch = async (id: any) => {
  //   if (parseInt(formik.values.expenseItem[id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage &&
  //     formik.values?.expenseItem[id]?.startLocation !== '' &&
  //     formik.values?.expenseItem[id]?.endLocation !== '') {
  //     setDistanceIsLoading((prevLoadingState) => ({
  //       ...prevLoadingState,
  //       [id]: true,
  //     }));
  //     try {
  //       const headers = {
  //         Authorization: `Bearer ${token}`,
  //       };
  //       const getTripDistanceResponse = await axios.get(
  //         `${baseURL}/api/General${API_END_POINTS.getDistanceByTripType}?source=${formik.values?.expenseItem[id]?.startLocation || ''}&destinaiton=${formik.values?.expenseItem[id]?.endLocation || ''}&tripTypeid=${formik.values?.expenseItem[id]?.tripType.id || ''}`,
  //         {
  //           headers,
  //         }
  //       );
  //       const distanceCal = getTripDistanceResponse.data;
  //       // Use distanceCal instead of distanceInMeters
  //       const distanceInKilometers = +(distanceCal.result / 1000).toFixed(2);
  //       const distanceInMiles = +(distanceCal.result * 0.000621371).toFixed(2);

  //       const ratePerKM = getMileageByDate.result?.ratePerKM;
  //       const ratePerMile = getMileageByDate.result?.ratePerMile;
  //       const distanceResult = ratePerKM ? distanceInKilometers : ratePerMile ? distanceInMiles : 0;
  //       formik.setFieldValue(`expenseItem[${id}].milage`, distanceResult);
  //       formik.setFieldValue(`expenseItem[${id}].reimbursableDistance`, distanceResult);
  //       setDistanceCal({ ...distanceCal, distanceInKilometers, distanceInMiles, distanceResult });

  //     } catch (error) {
  //       console.error(`Error fetching data for id ${id}: `, error);
  //       setDistanceError(true);
  //       // toast.error(`Expense Item${id + 1}: ${(error as { response?: { data?: { message?: string } } })?.response?.data?.message}`);
  //       const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
  //       if (errorMessage !== undefined) {
  //         toast.error(`Expense Item${id + 1}: ${errorMessage}`);
  //       }
  //     } finally {
  //       setDistanceIsLoading((prevLoadingState) => ({
  //         ...prevLoadingState,
  //         [id]: false,
  //       }));
  //     }
  //   }
  // };

  // const [sources, setSources] = useState<{ [key: string]: string }>({});
  // const [destinations, setDestinations] = useState<{ [key: string]: string }>({});

  // console.log('sources', sources);
  // console.log('destinations', destinations);
  // const originRef = useRef<HTMLInputElement>(null);
  // const destinationRef = useRef<HTMLInputElement>(null);
  const originRef = useRef<Record<number, HTMLInputElement | null>>({});
  const destinationRef = useRef<Record<number, HTMLInputElement | null>>({});


  const tripTypeDistanceRefetch = async (id: any) => {
    if (parseInt(formik.values.expenseItem[id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
      const { startLocation, endLocation, tripType, itemDate } = formik.values.expenseItem[id];


      if (!startLocation || !endLocation || !tripType?.id || !itemDate) {
        // toast.error('Please fill in all required fields.');
        return;
      }
      setDistanceIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        [id]: true,
      }));

      // const originValue = originRef.current ? originRef.current.value : '';
      // const destinationValue = destinationRef.current ? destinationRef.current.value : '';
      const originValue = originRef.current[id]?.value || '';
      const destinationValue = destinationRef.current[id]?.value || '';
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };


        const getTripDistanceResponse = await axios.get(
          `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}?source=${originValue || ''}&destinaiton=${destinationValue || ''}&tripTypeid=${formik.values?.expenseItem[id]?.tripType.id || ''}&itemDate=${formik.values?.expenseItem[id]?.itemDate || ''}`,
          // `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}?source=${currentSource}&destination=${currentDestination}&tripTypeid=${tripType.id}&itemDate=${itemDate}`,

          {
            headers,
          }
        );
        const distanceCal = getTripDistanceResponse.data;

        const mileageDistance = (distanceCal?.result?.distance).toFixed(2);
        const reimbursableDistance = (distanceCal?.result?.reimburable).toFixed(2);
        const mileageRate = (distanceCal?.result?.rate).toFixed(2);
        const amount = (distanceCal?.result?.amount).toFixed(2);

        formik.setFieldValue(`expenseItem[${id}].milage`, mileageDistance);
        formik.setFieldValue(`expenseItem[${id}].reimbursableDistance`, reimbursableDistance);
        formik.setFieldValue(`expenseItem[${id}].mileageRate`, mileageRate);
        formik.setFieldValue(`expenseItem[${id}].amount`, amount);

      } catch (error) {
        console.error(`Error fetching data for id ${id}: `, error);
        setDistanceError(true);
        // Clear fields on error
        formik.setFieldValue(`expenseItem[${id}].milage`, 0);
        formik.setFieldValue(`expenseItem[${id}].reimbursableDistance`, 0);
        formik.setFieldValue(`expenseItem[${id}].mileageRate`, 0);
        formik.setFieldValue(`expenseItem[${id}].amount`, 0);
        // toast.error(`Expense Item${id + 1}: ${(error as { response?: { data?: { message?: string } } })?.response?.data?.message}`);
        const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
        if (errorMessage !== undefined) {
          toast.error(`Expense Item${id + 1}: ${errorMessage}`);
        }
      } finally {
        setDistanceIsLoading((prevLoadingState) => ({
          ...prevLoadingState,
          [id]: false,
        }));
      }
    }
  };

  useEffect(() => {
    // Filtering row IDs that match the specific category ID
    const mileageIds = rowIds.filter(id => {
      const categoryId = parseInt(formik.values.expenseItem[id]?.expenseCategory?.id);
      return categoryId === 14; // Replace 14 with your specific category ID
    });

    mileageIds.forEach(id => {
      // Check if it's a new item and matches the specific category ID
      if (
        formik.values.expenseItem[id] &&
        parseInt(formik.values.expenseItem[id]?.expenseCategory?.id) === 14 &&
        !getMileageByDate // Ensure the API hasn't been triggered before
      ) {
        mileageRefetch(id);
      }
      // if (selectedByMouse) {
      // tripTypeDistanceRefetch(id);
      // setSelectedByMouse(false);
      // }
      // if (formik.values.expenseItem[id]?.tripType.id) {
      //   tripTypeDistanceRefetch(id)
      // }
    });
  }, [rowIds, formik.values.expenseItem, getMileageByDate, selectedByMouse]);

  // useEffect(() => {
  //   if (formik.values.expenseItem[newRowID]?.tripType.id) {
  //     tripTypeDistanceRefetch(newRowID)
  //   }
  // }, [formik.values.expenseItem[newRowID]?.tripType?.id])

  useEffect(() => {
    // if (formik.values.expenseItem[newRowID]?.itemDate) {
    //   mileageRefetch(newRowID);
    // }
    if (formik.values.expenseItem[newRowID]?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage) {
      paymentMethodRefetch()
      locationDataRefetch()
      tripTypeRefetch()
      mileageRefetch(newRowID);
      // if (milageRateIsError) {
      //   toast.error('Milage rate is not defined please contact with your admin');
      // }
    }
  }, [formik.values.expenseItem[newRowID]?.itemDate, formik.values.expenseItem[newRowID]?.expenseCategory?.id])


  useEffect(() => {
    setExpenseItems(formik.values.expenseItem || []);
  }, [formik.values.expenseItem]);

  // Update rows whenever initialRows changes
  useEffect(() => {
    if (data && expenseItems) {
      // Convert iterator to array and then map over it
      const newRows = Array.from(Array(expenseItems.length).keys()).map(index => ({
        id: index,
        showInnerTable: true
      }));
      setRows(newRows);
    }
  }, [data, expenseItems]);



  const handleRowClick = (rowId: number) => {
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id === rowId) {
          return {
            ...row,
            showInnerTable: !row.showInnerTable,
          };
        }
        return row;
      })
    );
  };
  const newRowRef = useRef<HTMLDivElement | null>(null);

  // useEffect(() => {
  //   if (newRowRef.current) {
  //     newRowRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [rows]);

  const handleAddNewItem = () => {
    const newRowId = rows.length;
    const newRow = { id: newRowId, showInnerTable: true };

    // setRows(prevRows => [...prevRows, newRow]); 
    setNewRowID(newRowId);
    // Set the current date for the itemDate field in the new row
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    formik.setFieldValue(`expenseItem[${newRowId}].itemDate`, formattedDate);
    formik.setFieldValue(`expenseItem[${newRowId}].isKM`, false);
    formik.setFieldValue(`expenseItem[${newRowId}].isMile`, false);
    formik.setFieldValue(`expenseItem[${newRowId}].milage`, 0);
    formik.setFieldValue(`expenseItem[${newRowId}].amount`, 0);
    formik.setFieldValue(`expenseItem[${newRowId}].expenseItemId`, 0);
    formik.setFieldValue(`expenseItem[${newRowId}].itemDetail`, '');
    formik.setFieldValue(`expenseItem[${newRowId}].paymentMethod.id`, 1);
    formik.setFieldValue(`expenseItem[${newRowId}].vendor.vendorId`, 0);
    formik.setFieldValue(`expenseItem[${newRowId}].tripType.id`, 1);
    formik.setFieldValue(`expenseItem[${newRowId}].expItemLocation.id`, location);
    formik.setFieldValue(`expenseItem[${newRowId}].expItemLocation.title`, locationName);
    formik.setFieldValue(`expenseItem[${newRowId}].expenseCategory.id`, 1);
    formik.setFieldValue(`expenseItem[${newRowId}].expenseCategory.title`, 'Other');
    formik.setFieldValue(`expenseItem[${newRowId}].invoiceDataDraftId`, 0);
    // Initialize isSplitLineItem and splitLineItems with default values
    formik.setFieldValue(`expenseItem[${newRowId}].isSplitLineItem`, false);
    formik.setFieldValue(`expenseItem[${newRowId}].splitLineItems`, [
      {
        splitLineItemId: 0,
        amount: 0,
        expItemLocation: { id: 0, title: '' },
      },
      {
        splitLineItemId: 0,
        amount: 0,
        expItemLocation: { id: 0, title: '' },
      },
    ]);
    // formik.setFieldValue(`expenseItem[${newRowId}].attachmentFile`, azurefileData?.result);
    // Set the currency id for the new row
    formik.setFieldValue(`expenseItem[${newRowId}].currency.id`, 2); // Replace 2 with the desired currency id

    setRows((prevRows) => {
      // Toggle the showInnerTable value of all previous rows to false
      const updatedRows = prevRows.map((row) => ({ ...row, showInnerTable: false }));
      return [...updatedRows, newRow];
    });

    // Ensure the scroll happens after the DOM update
    setTimeout(() => {
      if (newRowRef.current) {
        newRowRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Small delay to ensure the DOM is ready
  };
  const handleDeleteItem = (rowId: number) => {
    setSelectedItemToDelete(rowId);
    setShowDeleteConfirmationModal(true);
  };

  useEffect(() => {
    if (showAddUpdateVendorModal === false) {
      vendorRefetch()
    }
  }, [showAddUpdateVendorModal])
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>(new Array(rows.length).fill(''));
  const [imagePreviews, setImagePreviews] = useState<string[]>(new Array(rows.length).fill(''));

  const handleConfirmDelete = () => {

    if (selectedItemToDelete !== null) {
      setSelectedFileNames((prevFileNames) => {
        const updatedFileNames = [...prevFileNames];
        updatedFileNames[selectedItemToDelete] = ''; // Reset the file name for the specific row
        return updatedFileNames;
      });

      setImagePreviews((prevImagePreviews) => {
        const updatedImagePreviews = [...prevImagePreviews];
        updatedImagePreviews[selectedItemToDelete] = ''; // Reset the preview URL for the specific row
        return updatedImagePreviews;
      });
    }
    formik.setFieldValue(`expenseItem[${selectedItemToDelete}].expenseCategory.id`, '');

    formik.setFieldValue(
      'expenseItem',
      formik.values.expenseItem.filter((_: any, index: any) => index !== selectedItemToDelete)
    );

    // Set the updated rows state and the formik.values.expenseItem array
    setRows((prevRows) =>
      prevRows
        .filter((row) => row.id !== selectedItemToDelete)
        .map((row, index) => ({ ...row, id: index, showInnerTable: row.id === 0 })) // Reset row IDs and keep the first row open
    );


    // Close the confirmation modal
    setShowDeleteConfirmationModal(false);
    setSelectedItemToDelete(null);
  };

  const isPdfFile = (filePath: string | undefined) => {
    if (!filePath) return false;
    const fileExtension = filePath.toLowerCase().split('.').pop();
    return fileExtension === 'pdf';
  };
  const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
    if (attachmentFile) {
      if (isPdfFile(attachmentFile)) {
        // For PDF files, open in the browser
        window.open(attachmentFile, '_blank');
      } else {
        // For image files, open in the Lightbox
        setLightboxData({ imageURL: attachmentFile });
        setLightboxOpen(true);
      }
    }
  };
  const [rowLoading, setRowLoading] = useState<boolean[]>(new Array(rows.length).fill(false));
  const handleImageChangeFirst = async (event: any, rowId: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async () => {
      const updatedPreviews = [...imagePreviews];
      updatedPreviews[rowId] = reader.result as string;
      setImagePreviews(updatedPreviews);
      setRowLoading((prevLoading) => {
        const updatedLoading: boolean[] = [...prevLoading];
        updatedLoading[rowId] = true;
        return updatedLoading;
      });
      const formData = new FormData(); // Create a new FormData object
      formData.append('file', file); // Append the file to the FormData object
      try {
        let response;
        if (parseInt(formik.values.expenseItem[rowId]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
          response = await uploadFileToAzureForMilage(formData); // Pass the FormData object
        } else {
          response = await uploadFileToAzure(formData); // Pass the FormData object
        }

        // Check if the response is successful
        if (response && typeof response === 'object' && 'data' in response) {
          const successMessage = (response as { data?: { message?: string } })?.data?.message;
          if (successMessage) {
            toast.success(`${successMessage}`);
          }

          // Extract data from response
          const data = (response as { data?: any })?.data?.result;
          // Dispatch action to update Redux state
          dispatch(updateRowData({ rowId, data }));

          if (parseInt(formik.values.expenseItem[rowId]?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
            formik.setFieldValue(`expenseItem[${rowId}].attachmentFile`, data?.result);
            formik.setFieldValue(`expenseItem[${rowId}].itemDate`, currentDate.toISOString().split('T')[0]);
            formik.setFieldValue(`expenseItem[${rowId}].amount`, 0);
            formik.setFieldValue(`expenseItem[${rowId}].vendor.vendorId`, 0);
            formik.setFieldValue(`expenseItem[${rowId}].vendor.name`, '');
            formik.setFieldValue(`expenseItem[${rowId}].invoiceDataDraftId`, 0);
          } else {
            formik.setFieldValue(`expenseItem[${rowId}].attachmentFile`, data?.fileURL);
            formik.setFieldValue(`expenseItem[${rowId}].itemDate`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.itemDate : data?.invoiceDate);
            formik.setFieldValue(`expenseItem[${rowId}].amount`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.amount : data?.amount);
            formik.setFieldValue(`expenseItem[${rowId}].vendor.vendorId`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.vendor?.vendorId : data?.vendorInfo?.vendorId);
            formik.setFieldValue(`expenseItem[${rowId}].vendor.name`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.vendor?.name : data?.vendorInfo?.name);
            formik.setFieldValue(`expenseItem[${rowId}].expenseCategory.id`, data?.catagoryId);
            formik.setFieldValue(`expenseItem[${rowId}].expenseCategory.title`, data?.catagoryName);
            formik.setFieldValue(`expenseItem[${rowId}].paymentMethod.id`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.paymentMethod.id : data?.paymentMethodId);
            formik.setFieldValue(`expenseItem[${rowId}].paymentMethod.title`, formik.values.expenseItem[rowId]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ? formik.values.expenseItem[rowId]?.paymentMethod.title : data?.paymentMethodName);
            formik.setFieldValue(`expenseItem[${rowId}].invoiceDataDraftId`, data?.invoiceDataDraftId);
          }
        }


        // Set rowLoading to false when the API call completes
        setRowLoading((prevLoading) => {
          const updatedLoading = [...prevLoading];
          updatedLoading[rowId] = false;
          return updatedLoading;
        });
        // Handle the response or any errors here
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error(error);
        setRowLoading((prevLoading) => {
          const updatedLoading = [...prevLoading];
          updatedLoading[rowId] = false;
          return updatedLoading;
        });
      }
      const fileName = file ? (file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name) : '';
      // Set the selected file name for the current row
      setSelectedFileNames((prevFileNames) => {
        const updatedFileNames = [...prevFileNames];
        updatedFileNames[rowId] = fileName;
        return updatedFileNames;
      });
    };
    reader.readAsDataURL(file);

  };


  const fileInputRefs = useRef<Record<string, HTMLInputElement | null>>({});
  const setFileInputRefForRow = (ref: HTMLInputElement | null, rowId: string) => {
    if (ref) {
      fileInputRefs.current[rowId] = ref;
    }
  };

  const handleRemoveFile = (rowId: any) => {
    // Get the current file name and preview URL for the specific row
    const currentFileName = selectedFileNames[rowId];
    const currentPreviewURL = imagePreviews[rowId];

    // Reset the uploaded file for the specific row
    formik.setFieldValue(`expenseItem[${rowId}].attachmentFile`, null);
    // Reset the file input value by setting it to null
    // if (fileInputRef.current !== null) {
    //   fileInputRef.current.value = '';
    // }
    if (fileInputRefs.current[rowId] !== null && fileInputRefs.current[rowId] !== undefined) {
      fileInputRefs.current[rowId]!.value = '';
    }


    // Reset other state variables related to file upload
    setSelectedFileNames((prevFileNames) => {
      const updatedFileNames = [...prevFileNames];
      updatedFileNames[rowId] = ''; // Reset the file name for the specific row
      return updatedFileNames;
    });

    setImagePreviews((prevImagePreviews) => {
      const updatedImagePreviews = [...prevImagePreviews];
      updatedImagePreviews[rowId] = ''; // Reset the preview URL for the specific row
      return updatedImagePreviews;
    });
    // Close the lightbox if it's open and the removed file was an image
    if (lightboxOpen && currentPreviewURL) {
      setLightboxOpen(false);
    }
    dispatch(removeRowData(rowId));
  };
  useEffect(() => {
    if (fileIsError && fileError) {
      let errorMessage: string = 'Something went wrong';
      if ('data' in fileError && fileError.data && typeof fileError.data === 'object' && 'message' in fileError.data) {
        errorMessage = (fileError.data as { message: string }).message;
      }
      toast.error(errorMessage);

      // Clear the file input and state
      Object.keys(fileInputRefs.current).forEach((rowIdString) => {
        const rowId = parseInt(rowIdString);
        const fileInput = fileInputRefs.current[rowId];
        if (fileInput) {
          fileInput.value = ''; // Clear the input value
        }
        // Clear Formik values
        formik.setFieldValue(`expenseItem[${rowId}].attachmentFile`, null);
        // Log after setting to confirm
        setTimeout(() => {
          setSelectedFileNames((prevFileNames) => {
            const updatedFileNames = [...prevFileNames];
            updatedFileNames[rowId] = ''; // Clear the specific row
            return updatedFileNames;
          });
        }, 10);
        // Reset the displayed file name
      });


    }
  }, [fileIsError, fileError]);


  const libraries: Libraries = useMemo(() => ['places'], []);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });




  useEffect(() => {
    // Ensure that getMileageByDate is not null before triggering the API call
    if (!getMileageByDate) {
      mileageRefetch(rowIds);
    }
  }, [getMileageByDate]);

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, rowId: any, ref: React.RefObject<HTMLInputElement>) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault();
  //     if (ref.current && ref.current.value !== '') {
  //       if (ref === originRef) {

  //         formik.setFieldValue(`expenseItem[${rowId}].startLocation`, ref.current.value);
  //         // tripTypeDistanceRefetch(rowId);
  //       } else if (ref === destinationRef) {
  //         formik.setFieldValue(`expenseItem[${rowId}].endLocation`, ref.current.value);
  //         console.log('ref.current.value', ref.current.value);

  //         // calculateRoute(rowId);
  //         // tripTypeDistanceRefetch(rowId)

  //       }
  //     }
  //   }
  // };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    rowId: number,
    ref: HTMLInputElement | null
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (ref && ref.value !== '') {
        // Directly compare `ref` with `originRef.current[rowId]` and `destinationRef.current[rowId]`
        if (ref === originRef.current[rowId]) {
          formik.setFieldValue(`expenseItem[${rowId}].startLocation`, ref.value);
        } else if (ref === destinationRef.current[rowId]) {
          formik.setFieldValue(`expenseItem[${rowId}].endLocation`, ref.value);
        }
      }
    }
  };
  
  


  // const handleLocationChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   rowId: any,
  //   ref: React.RefObject<HTMLInputElement>
  // ) => {
  //   // Update Formik values based on the location type (startLocation or endLocation)
  //   const updatedLocation = event.target.value;

  //   if (ref === originRef) {
  //     formik.setFieldValue(`expenseItem[${rowId}].startLocation`, updatedLocation);
  //   } else if (ref === destinationRef) {
  //     formik.setFieldValue(`expenseItem[${rowId}].endLocation`, updatedLocation);
  //     // setTimeout(() => {
  //     //   tripTypeDistanceRefetch(rowId);
  //     // }, 500);
  //   }
  // };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: number,
    ref: MutableRefObject<Record<number, HTMLInputElement | null>>
  ) => {
    // Update Formik values based on the location type (startLocation or endLocation)
    const updatedLocation = event.target.value;

    if (ref === originRef) {
      formik.setFieldValue(`expenseItem[${rowId}].startLocation`, updatedLocation);
    } else if (ref === destinationRef) {
      formik.setFieldValue(`expenseItem[${rowId}].endLocation`, updatedLocation);
      // Uncomment if delay is needed for distance refetch
      // setTimeout(() => {
      //   tripTypeDistanceRefetch(rowId);
      // }, 500);
    }
  };


  const originAutocompleteRefs = useRef<Record<string, google.maps.places.Autocomplete | null>>({});
  const destinationAutocompleteRef = useRef<Record<string, google.maps.places.Autocomplete | null>>({});

  const handleStartPlaceChanged = (rowId: any) => {
    return () => {
      const autocomplete = originAutocompleteRefs.current[rowId];
      if (autocomplete) {
        const place = autocomplete.getPlace(); // Get the selected place
        if (place && place.geometry && place.geometry.location) {
          const startLocationLat = place.geometry.location.lat();
          const startLocationLng = place.geometry.location.lng();

          formik.setFieldValue(`expenseItem[${rowId}].startLocation`, place?.formatted_address);
          formik.setFieldValue(`expenseItem[${rowId}].startLatLngs.lat`, startLocationLat);
          formik.setFieldValue(`expenseItem[${rowId}].startLatLngs.lng`, startLocationLng);
          // setTimeout(() => {
          //   tripTypeDistanceRefetch(rowId);
          // }, 2000);
        }
      }
    };
  };
  const handleEndPlaceChanged = (rowId: any) => {
    return () => {
      const autocomplete = destinationAutocompleteRef.current[rowId];
      if (autocomplete) {
        const place = autocomplete.getPlace(); // Get the selected place

        if (place && place.geometry && place.geometry.location) {
          const endLocationLat = place.geometry.location.lat();
          const endLocationLng = place.geometry.location.lng();


          formik.setFieldValue(`expenseItem[${rowId}].endLocation`, place?.formatted_address);
          formik.setFieldValue(`expenseItem[${rowId}].endLatLngs.lat`, endLocationLat);
          formik.setFieldValue(`expenseItem[${rowId}].endLatLngs.lng`, endLocationLng);

        }
        // tripTypeDistanceRefetch(rowId)
      }
    };
  };
  useEffect(() => {
    Object.keys(formik.values.expenseItem).forEach(rowId => {
      handleStartPlaceChanged(rowId)();
      handleEndPlaceChanged(rowId)();
    });
  }, []);

  const [wordCounts, setWordCounts] = useState<WordCounts>({});
  const handleTextAreaChange = (event: any, rowId: string) => {
    const text = event.target.value;
    const characterCount = text.trim().length;
    // const characterCount = text.replace(/\s/g, '').length;
    setWordCounts((prevWordCounts) => ({
      ...prevWordCounts,
      [rowId]: characterCount,
    }));
  };


  const handleSplitLocationChange = (selectedOption: any, index: number, rowId: any) => {
    const selectedLocationId = selectedOption?.value;
    const selectedLocationTitle = selectedOption?.label;

    // Check for duplicate locations
    const isDuplicate = formik.values.expenseItem[rowId]?.splitLineItems.some(
      (item: any, i: number) =>
        item.expItemLocation?.id === selectedLocationId && i !== index
    );

    if (isDuplicate) {
      toast.error(`This location is already selected. Please choose a different location for Item ${rowId + 1}`);
    } else {
      // Update the form field if no duplicate is found
      formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].expItemLocation.id`, selectedLocationId || null);
      formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].expItemLocation.title`, selectedLocationTitle || null);
    }
  };

  // useEffect(() => {
  //   const updateSplitAmountsAndPercentages = () => {
  //     Object.keys(formik.values.expenseItem).forEach((rowId) => {
  //       const totalAmount = parseFloat(formik.values.expenseItem[rowId]?.amount || '0');
  //       if (totalAmount > 0) {
  //         formik.values.expenseItem[rowId]?.splitLineItems?.forEach((item: any, index: number) => {
  //           if (item?.percentage !== undefined && item.percentage !== '') {
  //             // Recalculate amount based on existing percentage
  //             const newAmount = (parseFloat(item.percentage.toString()) / 100) * totalAmount;
  //             // Only update if the value has changed
  //             if (parseFloat(item.amount) !== parseFloat(newAmount)) {
  //               formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].amount`, newAmount);
  //             }
  //           } else if (item?.amount !== undefined && item.amount !== '') {
  //             // Recalculate percentage based on existing amount
  //             const calculatedPercentage = (parseFloat(item?.amount?.toString()) / totalAmount) * 100;
  //             // Only update if the value has changed
  //             if (parseFloat(item.percentage) !== parseFloat(calculatedPercentage)) {
  //               formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].percentage`, calculatedPercentage);
  //             }
  //           }
  //         });
  //       }
  //     });
  //   };

  //   updateSplitAmountsAndPercentages();
  // }, [formik.values.expenseItem]);

  // const debouncedSetFieldValue = _.debounce((field, value) => {
  //   formik.setFieldValue(field, value);
  // }, 300); // Debounce for 300ms

  // useEffect(() => {
  //   formik.values.expenseItem?.forEach((item: any, rowId: any) => {
  //     const totalAmount = parseFloat(item?.amount || '0');

  //     if (totalAmount > 0) {
  //       item.splitLineItems?.forEach((splitItem: any, index: number) => {
  //         if (splitItem?.percentage !== undefined && splitItem.percentage !== '') {
  //           const newAmount = (parseFloat(splitItem.percentage?.toString()) / 100) * totalAmount;
  //           if (!_.isEqual(newAmount, splitItem.amount)) {
  //             debouncedSetFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].amount`, newAmount);
  //           }
  //         } else if (splitItem?.amount !== undefined && splitItem.amount !== '') {
  //           const calculatedPercentage = (parseFloat(splitItem.amount?.toString()) / totalAmount) * 100;
  //           if (!_.isEqual(calculatedPercentage, splitItem.percentage)) {
  //             debouncedSetFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].percentage`, calculatedPercentage);
  //           }
  //         }
  //       });
  //     }
  //   });
  // }, [formik.values.expenseItem]);



  // Handle split amount change for a specific row

  const handleSplitAmountChange = (index: number, newAmount: number | string, rowId: any) => {
    const totalAmount = parseFloat(formik.values.expenseItem[rowId]?.amount || '0');

    // Update amount
    formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].amount`, newAmount);

    // Calculate percentage based on the new amount
    const calculatedPercentage = totalAmount ? (parseFloat(newAmount.toString()) / totalAmount) * 100 : 0;
    formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].percentage`, calculatedPercentage.toFixed(2));
  };

  // Handle split percentage change for a specific row
  const handleSplitPercentageChange = (index: number, newPercentage: number | string, rowId: any) => {
    const totalAmount = parseFloat(formik.values.expenseItem[rowId]?.amount || '0');

    // Update percentage
    formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].percentage`, newPercentage);

    // Calculate amount based on the new percentage
    const calculatedAmount = totalAmount ? (parseFloat(newPercentage.toString()) / 100) * totalAmount : 0;
    formik.setFieldValue(`expenseItem[${rowId}].splitLineItems[${index}].amount`, calculatedAmount.toFixed(2));
  };

  return (
    <>
      <AddUpdateVendorModal show={showAddUpdateVendorModal}
        handleClose={() => setShowAddUpdateVendorModal(false)} />
      <DeleteItemModal
        show={showDeleteConfirmationModal}
        handleClose={() => setShowDeleteConfirmationModal(false)}
        handleDeleteConfirm={handleConfirmDelete}
      />
      <div className='pe-3 ' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='w-100'>
            {rows?.map((row, index) => {

              // const mileageRate = getMileageByDate?.result?.ratePerKM || getMileageByDate?.result?.ratePerMile || 0;
              // const mileageAmounts = +(mileageRate * formik.values?.expenseItem[row.id]?.reimbursableDistance || 0).toFixed(2);
              // const mileageAmounts = formik.values?.expenseItem[row.id]?.reimbursableDistance ? +(mileageRate * formik.values?.expenseItem[row.id]?.reimbursableDistance).toFixed(2) : '';


              const col3 = parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage
              // console.log('formik.values.expenseItem[row.id]?.tripType?.id', formik.values.expenseItem[row.id]?.tripType?.id);
              const categoryBudgetResult = getCategoryBudget[row.id] && getCategoryBudget[row.id].result;

              // Safely calculate the total split amount, ensuring valid amounts

              // const totalSplitAmount = formik.values.expenseItem[row.id]?.splitLineItems?.reduce((total: number, item: any) => {
              //   const validAmount = parseFloat(item.amount?.toString());
              //   // Check if validAmount is a number, otherwise default to 0
              //   return total + (!isNaN(validAmount) ? validAmount : 0);
              // }, 0) || 0;
              const totalSplitAmount = parseFloat(
                (formik.values.expenseItem[row.id]?.splitLineItems?.reduce((total: number, item: any) => {
                  const validAmount = parseFloat(item.amount?.toString());
                  return total + (!isNaN(validAmount) ? validAmount : 0);
                }, 0) || 0).toFixed(2)
              );

              // Round the total split amount to two decimal places
              const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;

              // Safely parse the total amount, defaulting to 0 if it's invalid
              const totalAmount = parseFloat(formik.values.expenseItem[row.id]?.amount?.toString() || '0');
              const validTotalAmount = !isNaN(totalAmount) ? totalAmount : 0;

              // Calculate the remaining amount and round it to two decimal places
              const remainingAmount = validTotalAmount - roundedTotalSplitAmount;
              const roundedRemainingAmount = Math.round(remainingAmount * 100) / 100;

              // Determine the class for the remaining amount text based on whether it's positive or negative
              const remainingAmountClass = roundedRemainingAmount >= 0 ? 'text-success' : 'text-danger';

              // Format the remaining amount to always show two decimal places
              const formattedRemainingAmount = roundedRemainingAmount.toFixed(2);




              return (
                <div className="border" key={row.id}
                  ref={row.id === rows.length - 1 ? newRowRef : null}
                >
                  {rowLoading[row.id] &&
                    <BGBlur />
                  }
                  <div className="bg-light py-3 px-3 cursor-pointer" onClick={() => handleRowClick(row.id)}>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex align-items-center gap-3'>
                        <p className='mb-0 fs-6 fw-bold'>{labelKey.expenseItem}: <span>{row.id + 1} - </span></p>
                        <p className='mb-0'>{formik.values?.expenseItem[row.id]?.expenseCategory?.title}</p>
                      </div>
                      {formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ||
                        formik.values.expenseItem[row.id]?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ? null :
                        <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3 text-hover-danger' onClick={() => handleDeleteItem(row.id)} />
                      }
                    </div>
                  </div>
                  {row.showInnerTable && (
                    <div className="row m-0 px-4 py-3">
                      <div className={`${col3 ? 'col-xl-3 col-md-6' :
                        categoryBudgetResult === -1 ? 'col-md-6' :
                          getCategoryBudgetError ? 'col-md-6' : 'col-md-6 col-xl-3'}
                           mb-5`}>
                        <SearchSelect
                          label={labelKey.category}
                          required
                          options={expenseCategory?.result?.map((option: any) => ({
                            label: option?.value,
                            value: option?.id
                          }))}
                          value={
                            formik.values.expenseItem[row.id]?.expenseCategory?.id
                              ? {
                                value: formik.values.expenseItem[row.id]?.expenseCategory?.id,
                                label: formik.values.expenseItem[row.id]?.expenseCategory?.title || '',
                              }
                              : null
                          }
                          onChange={(selectedOption) => {
                            formik.setFieldValue(`expenseItem.[${row.id}].expenseCategory.id`, selectedOption?.value || null);
                            formik.setFieldValue(`expenseItem.[${row.id}].expenseCategory.title`, selectedOption?.label || null);
                          }}
                          // onChange={(selectedOption) => handleCategoryChange(selectedOption, row.id)}
                          placeholder='Select Category'
                        // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                        />
                      </div>
                      {!getCategoryBudgetError &&
                        <>
                          {categoryBudgetResult !== undefined && categoryBudgetResult === -1 ? '' :
                            <div className='col-xl-3 col-md-6 mb-5'>
                              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{labelKey.remainingCategoryBudget}</label>
                              <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid' style={{ border: '1px solid #f0f0f0' }}>
                                ${categoryBudgetResult ? categoryBudgetResult : "0"}
                              </p>
                            </div>
                          }
                        </>}
                      {parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                        <div className='col-md-6 mb-5'>
                          <label className={`d-flex align-items-center fs-5 fw-bold mb-2 
                        ${formik.values.expenseItem?.[row.id]?.amount <= orgDetail?.result?.receiptReqMinAmnt ? null : 'required'}`}>
                            {labelKey.receiptDocument}
                            {formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator && <FaLock className='fs-7 ms-1' />}
                          </label>
                          <div className='d-flex align-items-center gap-2 w-100'>
                            {!rowLoading[row.id] &&
                              <div className='position-relative w-100 default-input rounded'>
                                <input
                                  type="file"
                                  id="file"
                                  name="file"
                                  accept=".png, .jpg, .jpeg, .pdf"
                                  // ref={fileInputRef}
                                  ref={(ref) => setFileInputRefForRow(ref, row.id.toString())}
                                  onChange={(event) => handleImageChangeFirst(event, row.id)}
                                  className='w-100 opacity-0 position-absolute h-40px cursor-pointer'
                                  disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                />
                                <div className={`form-control-lg form-control-solid form-control 
                                  ${formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'bg-secondary' : null}`} >
                                  <div className='d-flex align-items-center gap-2'>
                                    <FaUpload />
                                    <div className='w-100'>{selectedFileNames[row.id] ?
                                      <div className='d-flex align-items-center w-100'>
                                        <span className='fw-bold fs-5 mb-0'>{selectedFileNames[row.id]} </span>
                                        <span className='ms-auto'
                                          style={{ zIndex: 13 }}
                                          onClick={() => handleRemoveFile(row.id)}>
                                          <FaTrash className='ms-4 text-danger cursor-pointer' />
                                        </span>
                                      </div>
                                      : <span className='fw-bold fs-5 mb-0'>{labelKey.uploadYourFile}</span>}</div>
                                  </div>
                                </div>
                              </div>
                            }
                            {data ?
                              <>
                                {formik.values.expenseItem[row.id]?.attachmentFile && (
                                  <div className="mt-2 cursor-pointer">
                                    {/* <img src={formik.values.expenseItem[row.id]?.attachmentFile} alt=''
                                    style={{ maxWidth: '100%', maxHeight: '40px' }}
                                    onClick={() => openPdfInBrowserOrLightbox(formik.values.expenseItem[row.id]?.attachmentFile)} /> */}
                                    <LazyLoadImage
                                      alt='rcp'
                                      effect="blur"
                                      src={formik.values.expenseItem[row.id]?.attachmentFile.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : formik.values.expenseItem[row.id]?.attachmentFile}
                                      style={{ maxWidth: '100%', maxHeight: '40px' }}
                                      onClick={() => openPdfInBrowserOrLightbox(formik.values.expenseItem[row.id]?.attachmentFile)}
                                    />
                                  </div>
                                )}
                              </>
                              :
                              <>
                                {!rowLoading[row.id] &&
                                  <>
                                    {/* {imagePreviews[row.id] && (
                                      <div className="mt-2 cursor-pointer">
                                        <img src={imagePreviews[row.id]} alt=''
                                          style={{ maxWidth: '100%', maxHeight: '40px' }}
                                          onClick={() => openPdfInBrowserOrLightbox(imagePreviews[row.id])} />
                                      </div>
                                    )} */}
                                    {formik.values.expenseItem[row.id]?.attachmentFile && (
                                      <div className="mt-2 cursor-pointer">
                                        <img
                                          src={formik.values.expenseItem[row.id]?.attachmentFile.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : formik.values.expenseItem[row.id]?.attachmentFile}
                                          alt=''
                                          style={{ maxWidth: '100%', maxHeight: '40px' }}
                                          onClick={() => openPdfInBrowserOrLightbox(formik.values.expenseItem[row.id]?.attachmentFile)} />
                                      </div>
                                    )}
                                  </>}
                              </>}

                            {lightboxOpen && (
                              <Lightbox
                                mainSrc={lightboxData.imageURL}
                                onCloseRequest={() => setLightboxOpen(false)}
                                imageCaption="Attachment"
                                enableZoom={true}
                                imagePadding={50}
                              />
                            )}
                          </div>
                        </div>
                      }
                      <div className={`${col3 ? 'col-xl-3 col-md-6' : 'col-xl-4 col-md-6'} mb-5`}>
                        <TextField
                          label={labelKey.date}
                          required={true}
                          type='date'
                          max={currentDate.toISOString().split('T')[0]}
                          {...(formik.values.expenseItem[row.id] && formik.getFieldProps(`expenseItem[${row.id}].itemDate`))}
                          value={formik.values.expenseItem[row.id]?.itemDate ? moment(formik.values.expenseItem[row.id]?.itemDate).format('YYYY-MM-DD') : ''}
                          disabled={formik.values.expenseItem[row.id]?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ||
                            formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                          }
                        />
                      </div>
                      <div className={`${col3 ? categoryBudgetResult === -1 ? 'col-xl-2 col-lg-6' : 'col-xl-3 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                        {/* <SelectField
                          label={labelKey.currency}
                          required={true}
                          {...formik.getFieldProps(`expenseItem.[${row.id}].currency.id`)}
                        >
                          <option value="">Select Currency</option>
                          {currency?.result.map((option: any, index: any) => (
                            <option key={option.id} value={option.id}>{option?.value}</option>
                          ))}
                        </SelectField> */}
                        <SearchSelect
                          label={labelKey.currency}
                          required={true}
                          value={
                            formik.values.expenseItem[row.id]?.currency?.id
                              ? {
                                value: formik.values.expenseItem[row.id]?.currency?.id,
                                label: formik.values.expenseItem[row.id]?.currency?.title || 'USD',
                              }
                              : null
                          }
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue(`expenseItem.[${row.id}].currency.id`, selectedOption.value);
                            formik.setFieldValue(`expenseItem.[${row.id}].currency.title`, selectedOption.label);
                          }}
                          options={
                            currency?.result.map((option: any) => ({
                              value: option.id,
                              label: option.value,
                              key: option.id,
                            }))
                          }
                          placeholder="Select Currency"
                          disabled={formik.values.expenseItem[row.id]?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ||
                            formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                          }
                        />

                      </div>
                      {parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                        <>
                          <div className={`${col3 ? 'col-lg-3 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                            <SearchSelect
                              label={labelKey.vendor}
                              customLabel={labelKey.addNew}
                              customLabelClick={() => setShowAddUpdateVendorModal(true)}
                              customLabelClass="cursor-pointer"
                              required
                              options={[
                                ...(vendor?.result || []).map((option: any, index: any) => ({
                                  value: option.id,
                                  label: option?.value,
                                  key: option.id,
                                })),
                              ]}

                              placeholder='Select Vendor'
                              value={
                                formik.values.expenseItem[row.id]?.vendor?.vendorId
                                  ? {
                                    value: formik.values.expenseItem[row.id]?.vendor?.vendorId,
                                    label: formik.values.expenseItem[row.id]?.vendor?.name || '',
                                  }
                                  : null
                              }
                              onChange={(selectedOption: any) => {
                                formik.setFieldValue(`expenseItem.[${row.id}].vendor.vendorId`, selectedOption.value);
                                formik.setFieldValue(`expenseItem.[${row.id}].vendor.name`, selectedOption.label);
                              }}
                              disabled={(
                                formik.values.expenseItem[row.id]?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard &&
                                formik.values.expenseItem[row.id]?.vendor?.vendorId !== 1 ||
                                formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                              )}
                            />
                          </div>

                        </>
                      }
                      {formik.values.expenseItem[row.id]?.isSplitLineItem !== true &&
                        <div className={`${col3 ? 'col-lg-4 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                          <SearchSelect
                            label={labelKey.chargeLocation}
                            required
                            options={(locationsData?.result || []).map((option: any, index: any) => ({
                              value: option.id,
                              label: option?.value,
                              key: option.id,
                            }))}
                            value={
                              formik.values.expenseItem[row.id]?.expItemLocation?.id
                                ? {
                                  value: formik.values.expenseItem[row.id]?.expItemLocation?.id,
                                  label: formik.values.expenseItem[row.id]?.expItemLocation?.title || '',
                                }
                                : null
                            }
                            onChange={(selectedOption: any) => {
                              formik.setFieldValue(`expenseItem.[${row.id}].expItemLocation.id`, selectedOption?.value || null);
                              formik.setFieldValue(`expenseItem.[${row.id}].expItemLocation.title`, selectedOption?.label || null);
                            }}
                            // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                          />
                        </div>}
                      <div className={`${col3 ? 'col-xl-2 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                        <SearchSelect
                          label={labelKey.paymentMethod}
                          required={true}
                          selectClass={`${parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage && ''}`}
                          disabled={parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ||
                            parseInt(formik.values.expenseItem[row.id]?.paymentMethod?.id) === constraintConfig.paymentMethod.corporateCreditCard ||
                            formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                          }
                          value={
                            formik.values.expenseItem[row.id]?.paymentMethod?.id
                              ? {
                                value: formik.values.expenseItem[row.id]?.paymentMethod?.id,
                                label: formik.values.expenseItem[row.id]?.paymentMethod?.title || 'Cash',
                              }
                              : null
                          }
                          onChange={(selectedOption: any) => {
                            formik.setFieldValue(`expenseItem.[${row.id}].paymentMethod.id`, selectedOption.value);
                            formik.setFieldValue(`expenseItem.[${row.id}].paymentMethod.title`, selectedOption.label);
                          }}
                          options={
                            parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage
                              ? getPaymentMethod?.result.filter((option: any) => option.id !== 2 && option.id !== 3).map((option: any) => ({
                                value: option.id,
                                label: option.value,
                                key: option.id,
                              }))
                              : getPaymentMethod?.result.filter((option: any) => {
                                if (formik.values.expenseItem[row.id]?.paymentMethod?.id !== constraintConfig.paymentMethod.corporateCreditCard && option.id === 3) {
                                  return false; // Skip the option
                                }
                                return true;
                              }).map((option: any) => ({
                                value: option.id,
                                label: option.value,
                                key: option.id,
                              }))
                          }
                        />
                      </div>

                      {parseInt(formik.values.expenseItem[row.id]?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                        <>
                          <div className={`${col3 ? 'col-xl-2 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                            <SearchSelect
                              label={labelKey.tripType}
                              required
                              options={[
                                // { value: '', label: 'Select Trip Type' }, // Placeholder option
                                ...(tripTypeData?.result || []).map((option: any) => ({
                                  value: option.id,
                                  label: option.value,
                                  key: option.id,
                                }))
                              ]}
                              placeholder="Select Trip Type"
                              value={
                                formik.values.expenseItem[row.id]?.tripType?.id
                                  ? {
                                    value: formik.values.expenseItem[row.id].tripType.id,
                                    label: tripTypeData?.result.find((option: any) => option.id === formik.values.expenseItem[row.id].tripType.id)?.value || '',
                                  }
                                  : null
                              }
                              onChange={(selectedOption: any) => handleTripTypeChange(selectedOption, row.id)}
                              disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            />

                          </div>
                          <>
                            {isLoaded && (
                              <div className={`${locationType === 1 ? 'd-block' : 'd-none'} col-lg-8`}>
                                <div className="row">
                                  <div className='col-md-6 mb-5'>
                                    <label className='fs-5 fw-bold mb-2 required'>
                                      {labelKey.startLocation}
                                    </label>
                                    <Autocomplete
                                      onLoad={(autocomplete) => {
                                        // originAutocompleteRef.current = autocomplete;
                                        originAutocompleteRefs.current[row.id] = autocomplete;
                                        autocomplete.addListener('place_changed', handleStartPlaceChanged(row.id));
                                      }}
                                      onPlaceChanged={() => tripTypeDistanceRefetch(row.id)}
                                    >
                                      <input type='text'
                                        className='form-control form-control-lg form-control-solid default-input'
                                        placeholder='Origin'
                                        // ref={originRef}
                                        ref={(el) => (originRef.current[row.id] = el)}

                                        // onKeyDown={(event) => handleKeyDown(event, row.id, originRef)}
                                        // onChange={(event) => handleLocationChange(event, row.id, originRef)}
                                        onKeyDown={(event) => handleKeyDown(event, row.id, originRef.current[row.id])}
                                        onChange={(event) => handleLocationChange(event, row.id, originRef)}
                                        value={formik.values.expenseItem[row.id]?.startLocation}
                                        disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                      />
                                    </Autocomplete>
                                  </div>
                                  <div className='col-md-6 mb-5'>
                                    <label className='fs-5 fw-bold mb-2 required'>
                                      {labelKey.endLocation}
                                    </label>
                                    <div className='d-flex gap-2'>
                                      <Autocomplete className='w-100'
                                        onLoad={(autocomplete) => {
                                          // destinationAutocompleteRef.current = autocomplete;
                                          destinationAutocompleteRef.current[row.id] = autocomplete;
                                          autocomplete.addListener('place_changed', handleEndPlaceChanged(row.id));
                                        }}
                                        onPlaceChanged={() => tripTypeDistanceRefetch(row.id)}
                                      >
                                        <input
                                          type='text' className='form-control form-control-lg form-control-solid default-input'
                                          placeholder='Destination'
                                          // ref={destinationRef}
                                          ref={(el) => (destinationRef.current[row.id] = el)}

                                          // onKeyDown={(event) => handleKeyDown(event, row.id, destinationRef)}
                                          // onChange={(event) => handleLocationChange(event, row.id, destinationRef)}
                                          onKeyDown={(event) => handleKeyDown(event, row.id, destinationRef.current[row.id])}
                                          onChange={(event) => handleLocationChange(event, row.id, destinationRef)}
                                          value={formik.values.expenseItem[row.id]?.endLocation}
                                          disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                        />
                                      </Autocomplete>
                                      {/* <Tooltip id="calculateDistance" place="top" />
                                      <div className='btn btn-primary'
                                        // onClick={() => calculateRoute(row.id)}
                                        onClick={() => tripTypeDistanceRefetch(row.id)}
                                        data-tooltip-id="calculateDistance" data-tooltip-content="Calculate Distance"
                                      >
                                        <i className='fa fa-location-arrow'></i>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                          <div className={`${locationType === 2 ? 'd-block' : 'd-none'} col-lg-8`}>
                            <div className="row">
                              <div className='col-md-6 mb-5'>
                                <SearchSelect
                                  label={labelKey.startLocation}
                                  required
                                  options={(locationsData?.result || []).map((option: any, index: any) => ({
                                    value: option.id,
                                    label: option?.value,
                                    key: option.id,
                                  }))}
                                  placeholder={
                                    formik.values.expenseItem[row.id]?.startLocation
                                      ? formik.values.expenseItem[row.id]?.startLocation
                                      : 'Select Origin'
                                  }
                                  value={formik.values.expenseItem[row.id]?.startLocation}
                                  onChange={(selectedOption: any) => {
                                    setSelectedByMouse(true);
                                    formik.setFieldValue(`expenseItem.[${row.id}].startLocation`, selectedOption?.label || '');
                                  }}
                                  disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                />

                              </div>
                              <div className='col-md-6 mb-5'>
                                <div className='d-flex gap-2 w-100'>
                                  <SearchSelect
                                    label={labelKey.endLocation}
                                    required
                                    options={(locationsData?.result || []).map((option: any, index: any) => ({
                                      value: option.id,
                                      label: option?.value,
                                      key: option.id,
                                    }))}
                                    placeholder={
                                      formik.values.expenseItem[row.id]?.endLocation
                                        ? formik.values.expenseItem[row.id]?.endLocation
                                        : 'Select Destination'
                                    }
                                    getOptionLabel={(option: any) => option?.label || ''}
                                    value={formik.values.expenseItem[row.id]?.endLocation}
                                    onChange={(selectedOption: any) => {
                                      setSelectedByMouse(true);
                                      formik.setFieldValue(`expenseItem.[${row.id}].endLocation`, selectedOption?.label || '');
                                    }}
                                    selectClass='w-100'
                                    disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* } */}
                          <div className='col-lg-2 col-md-4 mb-5'>
                            <div className='position-relative'>
                              {distanceIsLoading[row.id] &&
                                <div className="spinner-border spinner-border-sm position-absolute me-5" style={{ right: 0, marginTop: '36px' }} role="status">
                                  <span className="sr-only">{labelKey.loading}...</span>
                                </div>}
                            </div>
                            <TextField
                              label={labelKey.distance}
                              required={true}
                              type='text'
                              placeholder='Distance'
                              min={0}
                              // value={distance.toString()}
                              // value={distanceCal?.distanceResult}
                              {...formik.getFieldProps(`expenseItem[${row.id}].milage`)}
                              readOnly={true}
                              disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            />
                          </div>
                          <div className='col-lg-2 col-md-4 mb-5'>
                            <TextField
                              label={labelKey.reimbursableDistance}
                              type='number'
                              readOnly={true}
                              placeholder='Reimbursable Distance'
                              // value={distanceCal?.distanceResult}
                              value={formik.values.expenseItem[row.id].reimbursableDistance}
                              {...formik.getFieldProps(`expenseItem[${row.id}].reimbursableDistance`)}
                              disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            />
                          </div>
                          <div className='col-lg-2 col-md-4 mb-5'>
                            <TextField
                              label={getMileageByDate?.result?.ratePerKM ? "Rate Per KM" : "Rate Per MI"}
                              type='text'
                              placeholder={getMileageByDate?.result?.ratePerKM ? "Rate Per KM" : "Rate Per MI"}
                              // value={getMileageByDate?.result?.ratePerKM ? getMileageByDate?.result?.ratePerKM?.toFixed(2) : getMileageByDate?.result?.ratePerMile?.toFixed(2)}
                              {...formik.getFieldProps(`expenseItem[${row.id}].mileageRate`)}
                              value={formik.values.expenseItem[row.id].mileageRate || 0}
                              readOnly={true}
                              disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            />
                          </div>
                          <div className='col-lg-2 col-md-4 mb-5'>
                            <TextField
                              label={labelKey.amount}
                              required={true}
                              type='number'
                              {...formik.getFieldProps(`expenseItem[${row.id}].amount`)}
                              placeholder='Amount'
                              // value={mileageAmounts?.toString()}
                              readOnly={true}
                              disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            />
                          </div>

                        </>
                        :
                        <div className={`${col3 ? 'col-lg-3 col-md-6' : 'col-lg-4 col-md-6'} mb-5`}>
                          <TextField
                            label={labelKey.amount}
                            required={true}
                            type='number'
                            placeholder='Enter Amount'
                            min={0}
                            {...formik.getFieldProps(`expenseItem[${row.id}].amount`)}
                            value={formik.values.expenseItem[row.id]?.amount || ''}
                            disabled={formik.values?.expenseItem[row.id]?.recordSourceId === constraintConfig.recordSourceId.BankFeed ||
                              formik.values.expenseItem[row.id]?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ||
                              formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                            }

                          />
                        </div>
                      }


                      <div className='col-md-12 mb-5'>
                        <div>
                          <TextAreaField
                            label={labelKey.description}
                            placeholder='Enter Expense Description'
                            {...formik.getFieldProps(`expenseItem[${row.id}].itemDetail`)}
                            onChange={(e) => {
                              formik.handleChange(e);
                              handleTextAreaChange(e, row.id.toString());
                            }}
                          // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                          />
                          <p className="text-muted text-end">Characters: ({wordCounts[row.id] || 0}/500)</p>
                        </div>
                      </div>

                      <div className='col-md-12 col-lg-12 col-xl-8 mb-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div>
                            <label className='form-label fs-5 fw-bold'>Split Amount to Locations</label>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                role="switch"
                                id={`flexSwitchCheckChecked`}
                                {...formik.getFieldProps(`expenseItem[${row.id}].isSplitLineItem`)}
                                checked={formik.values.expenseItem[row.id]?.isSplitLineItem}
                                onChange={(e) => {
                                  const isChecked = e.target.checked;
                                  formik.setFieldValue(`expenseItem[${row.id}].isSplitLineItem`, isChecked);

                                  if (isChecked) {
                                    const currentSplitLineItems = formik.values.expenseItem[row.id]?.splitLineItems || [];
                                    if (currentSplitLineItems.length === 1) {
                                      formik.setFieldValue(`expenseItem[${row.id}].splitLineItems`, [
                                        ...currentSplitLineItems,
                                        { splitLineItemId: 0, expItemLocation: { id: 0, title: '' }, amount: '' },
                                      ]);
                                    }
                                  }
                                }}
                              // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                              />
                            </div>
                          </div>
                          {formik.values.expenseItem[row.id]?.isSplitLineItem === true && (
                            <div className='pe-10'>
                              <div className='d-flex gap-2 align-items-center'>
                                <p className='mb-0 fs-4'><CurrencyComp amount={totalSplitAmount.toString()} />  of </p>
                                <p className='mb-0 fs-4'> <CurrencyComp amount={formik.values.expenseItem[row.id]?.amount} />  </p>
                              </div>
                              <p className={`${remainingAmountClass} fs-4 mb-0`}>
                                <CurrencyComp amount={formattedRemainingAmount} />
                                &nbsp; {roundedRemainingAmount >= 0 ? 'left' : 'Over'}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-4"></div>
                      {formik.values.expenseItem[row.id]?.isSplitLineItem && (
                        <div className='col-md-12 col-lg-12 col-xl-8'>
                          {formik.values.expenseItem[row.id]?.splitLineItems?.map((item: any, index: any) => (
                            <div key={index} className="row">
                              <div className='col-md-6 col-lg-6 col-xl-6'>
                                <div>
                                  <SearchSelect
                                    label={labelKey.splitLocation}
                                    labelClass='mt-5'
                                    required
                                    options={(locationsData?.result || []).map((option: any) => ({
                                      value: option.id,
                                      label: option?.value,
                                      key: option.id,
                                    }))}
                                    value={
                                      formik.values.expenseItem[row.id]?.splitLineItems[index]?.expItemLocation?.id
                                        ? {
                                          value: formik.values.expenseItem[row.id]?.splitLineItems[index]?.expItemLocation.id,
                                          label: formik.values.expenseItem[row.id]?.splitLineItems[index]?.expItemLocation.title || '',
                                        }
                                        : null
                                    }
                                    selectClass='w-100'
                                    // onChange={(selectedOption: any) => {
                                    //   formik.setFieldValue(`expenseItem[${row.id}].splitLineItems[${index}].expItemLocation.id`, selectedOption?.value || null);
                                    //   formik.setFieldValue(`expenseItem[${row.id}].splitLineItems[${index}].expItemLocation.title`, selectedOption?.label || null);
                                    // }}
                                    onChange={(selectedOption: any) => handleSplitLocationChange(selectedOption, index, row.id)}
                                  // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                  />

                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className='d-flex gap-2 align-items-center'>
                                  <TextField
                                    label={labelKey.splitAmount}
                                    required
                                    labelClass='mt-5'
                                    type='number'
                                    placeholder='Enter Amount'
                                    min={0}
                                    {...formik.getFieldProps(`expenseItem[${row.id}].splitLineItems[${index}].amount`)}
                                    value={formik.values.expenseItem[row.id]?.splitLineItems[index].amount || ''}
                                    // value={formik.values.expenseItem[row.id]?.splitLineItems[index].amount
                                    //   ? Number(formik.values.expenseItem[row.id]?.splitLineItems[index].amount).toFixed(2)
                                    //   : ''
                                    // }
                                    onChange={(e) => handleSplitAmountChange(index, e.target.value, row.id)}
                                  // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                  />
                                  {/* {formik.values?.expenseItem[row.id]?.debitOrCreditIndicator !== constraintConfig.debitOrCreditIndicator.creditIndicator &&
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3 text-hover-danger cursor-pointer mt-12'
                                      onClick={() => {
                                        const updatedSplitLineItems = formik.values.expenseItem[row.id]?.splitLineItems.filter((_: any, i: any) => i !== index);
                                        formik.setFieldValue(`expenseItem[${row.id}].splitLineItems`, updatedSplitLineItems);
                                        if (updatedSplitLineItems.length === 1) {
                                          formik.setFieldValue(`expenseItem[${row.id}].isSplitLineItem`, false);
                                        }
                                      }}
                                    />
                                  } */}
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3 col-xl-3">
                                <div className='d-flex gap-2 align-items-center position-relative'>
                                  <span className='position-absolute mt-13 ps-3 pe-2 h-35px d-flex align-items-center justify-content-center' style={{ borderRight: '1px solid #a1a5b7' }}>
                                    <FaPercentage className='h-20px w-20px text-muted' />
                                  </span>
                                  <TextField
                                    label={labelKey.splitAmountWithPer}
                                    required
                                    labelClass='mt-5'
                                    type='number'
                                    placeholder='Enter Percentage'
                                    min={0}
                                    fieldClass='ps-15'
                                    {...formik.getFieldProps(`expenseItem[${row.id}].splitLineItems[${index}].percentage`)}
                                    value={formik.values.expenseItem[row.id]?.splitLineItems[index].percentage || ''}
                                    onChange={(e) => handleSplitPercentageChange(index, e.target.value, row.id)}
                                  // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                                  />
                                  {formik.values?.expenseItem[row.id]?.debitOrCreditIndicator !== constraintConfig.debitOrCreditIndicator.creditIndicator &&
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3 text-hover-danger cursor-pointer mt-12'
                                      onClick={() => {
                                        const updatedSplitLineItems = formik.values.expenseItem[row.id]?.splitLineItems.filter((_: any, i: any) => i !== index);
                                        formik.setFieldValue(`expenseItem[${row.id}].splitLineItems`, updatedSplitLineItems);
                                        if (updatedSplitLineItems.length === 1) {
                                          formik.setFieldValue(`expenseItem[${row.id}].isSplitLineItem`, false);
                                        }
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className='d-flex justify-content-end w-100'>
                            <button
                              type="button"
                              className='btn btn-sm text-primary ps-0 pe-10'
                              onClick={() => {
                                const currentSplitLineItems = Array.isArray(formik.values.expenseItem[row.id]?.splitLineItems)
                                  ? formik.values.expenseItem[row.id]?.splitLineItems
                                  : []; // Ensure it's an array
                                formik.setFieldValue(`expenseItem[${row.id}].splitLineItems`, [
                                  ...currentSplitLineItems,
                                  {
                                    splitLineItemId: 0,
                                    expItemLocation: { id: 0, title: '' },
                                    amount: '',
                                  },
                                ]);
                              }}
                            // disabled={formik.values?.expenseItem[row.id]?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator}
                            >
                              Add More
                            </button>
                          </div>
                        </div>
                      )}

                    </div>
                  )}
                </div>
              )
            })}
          </div>
          {formik.values.expenseStatus?.id !== constraintConfig.expenseStatus?.rejected &&
            <div className="row mt-4">
              <div className='col-md-12'>
                <div className='d-flex align-items-center gap-3 justify-content-center'>
                  <div className='border w-100'></div>
                  <Button className='btn btn-sm btn-light-primary w-100' onClick={handleAddNewItem} disabled={!isStep2Valid}>
                    <i className='fa fa-plus'></i> {labelKey.addNewItem}</Button>
                  <div className='border w-100'></div>
                </div>
              </div>
            </div>
          }
        </div>
      </div >
    </>
  )
}

export { Step2 };

