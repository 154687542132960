import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
const persistConfig = {
    key: "auth",
    version: 1,
    storage,
    blacklist: [],
}
export interface AuthState {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    role: string | null;
    roleID: number | null;
    contactPhone: number | null;
    businessPhone: number | null;
    businessExt: number | null;
    userAccountID: number | null;
    userInOrgId: number | null;
    token: string | null;
    isImpersonating: boolean | null;
    expiresAt: string | null;
    locationType: number | null;
    expenseType: number | null;
    location: number | null;
    locationName: string | null;
    baseURL: string | null;
    microServiceURL: string | null;
    reportPermision: boolean | null;
    timeZone: string | null;
    flexBudgetPermission: boolean | null;
    categoryBudgetPermission: boolean | null;

}

const initialState: AuthState = {
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    roleID: null,
    contactPhone: null,
    businessPhone: null,
    businessExt: null,
    userAccountID: null,
    userInOrgId: null,
    token: null,
    isImpersonating: false,
    expiresAt: null,
    locationType: null,
    expenseType: null,
    location: null,
    locationName: null,
    reportPermision: null,
    baseURL: null,
    microServiceURL: null,
    timeZone: null,
    flexBudgetPermission: null,
    categoryBudgetPermission: null,
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{
            firstName: string,
            lastName: string,
            email: string,
            role: string,
            roleID: number,
            contactPhone: number,
            businessPhone: number,
            businessExt: number,
            userAccountID: number,
            userInOrgId: number,
            token: string,
            expiresAt: string,
            locationType: number,
            expenseType: number,
            location: number,
            locationName: string,
            reportPermision: boolean
            baseURL: string,
            microServiceURL: string,
            timeZone: string,
            flexBudgetPermission: boolean,
            categoryBudgetPermission: boolean,
        }>) => {
            localStorage.setItem("user", JSON.stringify({
                token: action.payload.token,
                baseURL: action.payload.baseURL,
                microServiceURL: action.payload.microServiceURL
            }));
            state.firstName = action.payload.firstName
            state.lastName = action.payload.lastName
            state.email = action.payload.email
            state.role = action.payload.role
            state.roleID = action.payload.roleID
            state.contactPhone = action.payload.contactPhone
            state.businessPhone = action.payload.businessPhone
            state.businessExt = action.payload.businessExt
            state.userAccountID = action.payload.userAccountID
            state.userInOrgId = action.payload.userInOrgId
            state.locationType = action.payload.locationType
            state.expenseType = action.payload.expenseType
            state.location = action.payload.location
            state.locationName = action.payload.locationName
            state.reportPermision = action.payload.reportPermision
            state.token = action.payload.token
            state.expiresAt = action.payload.expiresAt
            state.baseURL = action.payload.baseURL
            state.microServiceURL = action.payload.microServiceURL
            state.timeZone = action.payload.timeZone
            state.flexBudgetPermission = action.payload.flexBudgetPermission
            state.categoryBudgetPermission = action.payload.categoryBudgetPermission

        },

        logout: (state) => {
            // localStorage.clear();
            localStorage.removeItem('user');
            localStorage.removeItem('originalEmail');
            localStorage.removeItem('persist:auth');
            state.firstName = null;
            state.lastName = null;
            state.email = null;
            state.role = null;
            state.contactPhone = null;
            state.businessPhone = null;
            state.businessExt = null;
            state.userAccountID = null;
            state.userInOrgId = null;
            state.token = null;
            state.expiresAt = null;
            state.locationType = null;
            state.expenseType = null;
            state.location = null;
            state.locationName = null;
            state.reportPermision = null;
            state.baseURL = null;
            state.microServiceURL = null;
            state.timeZone = null;
        },

        startImpersonating: (state) => {
            state.isImpersonating = true;
        },
        stopImpersonating: (state) => {
            state.isImpersonating = false;
        },
        setOriginalEmail: (state, action: PayloadAction<string>) => {
            localStorage.setItem("originalEmail", action.payload);
        },
    }
})

export const selectAuth = (state: RootState) => state.auth;
// export const selectBaseURL = (state: RootState) => state.auth.baseURL;
export const { setUser, logout, startImpersonating, stopImpersonating, setOriginalEmail } = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);

// export default authSlice.reducer;