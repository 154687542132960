import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ReactReadMoreReadLess from "react-read-more-read-less"
import Select, { components } from 'react-select'
import { PageTitle } from '../../../../_metronic/layout/core'
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp'
import FilterMenu from '../../../../components/filterMenu/FilterMenu'
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent'
import NoRecordFound from '../../../../components/noRecord/NoRecordFound'
import Pagination from '../../../../components/pagination/Pagination'
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent'
import TableHeading from '../../../../components/tableHeading/TableHeading'
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu'
import TextField from '../../../../components/textFields/TextField'
import { useGetAllUsersLookupQuery, useGetUsersByManagerIdQuery } from '../../../../services/GeneralApi'
import { useGetAllRequestLogsQuery } from '../../../../services/OrgSettingsApi'
import useColumnVisibility from '../../../customHooks/useColumnVisibility'
import labelKey from "../../../localization/label.json"
import { timePeriodOptions } from '../../reportAnalytics/ReportFilterOptions'

const { Option } = components;
type Option = {
    value: string;
    label: string;
};

// Custom Option component to add checkboxes
const CheckboxOption = (props: any) => {
    return (
        <Option {...props} className='d-flex align-items-start gap-2'>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
            />{" "}
            <label>{props.label}</label>
        </Option>
    );
};

const RequestLogs = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'requestlogs' });

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createdDate");
    const [sortOrder, setSortOrder] = useState("desc");
    const [filterByStartDate, setFilterByStartDate] = useState("");
    const [filterByEndDate, setFilterByEndDate] = useState("");
    const [sortedColumn, setSortedColumn] = useState("createdDate");
    const [filterByUserIds, setFilterByUserIds] = useState<string[]>([]);
    const [filterByTimePeriod, setFilterByTimePeriod] = useState('');


    const [selectedUserIds, setSelectedUserIds] = useState<Option[]>([]);

    const [localFilterByStartDate, setLocalFilterByStartDate] = useState("");
    const [localFilterByEndDate, setLocalFilterByEndDate] = useState("");
    const [localFilterByUserIds, setLocalFilterByUserIds] = useState<string[]>([]);
    const [localFilterByTimePeriod, setLocalFilterByTimePeriod] = useState("");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const { data: getUserByManager } = useGetAllUsersLookupQuery('')

    const { data, isFetching:isLoading, refetch } = useGetAllRequestLogsQuery(
        {
            startDate: filterByStartDate,
            endDate: filterByEndDate,
            pageNumber: page,
            sortBy: sortBy,
            sortOrder: sortOrder,
            pageSize: pageSize,
            searchstr: searchStr,
            userIds: filterByUserIds.length > 0 ? filterByUserIds : null,
            timePeriod: filterByTimePeriod

        })


    const userIdsOptions: Option[] = getUserByManager?.result?.map((userByManager: any) => ({
        value: userByManager.id,
        label: userByManager.value,
    }));

    const handleUserIdsChange = (selected: Option[]) => {
        setSelectedUserIds(selected);
        setLocalFilterByUserIds(selected?.map((userByManager) => userByManager.value));
    };
    const handleTimePeriodChange = (selectedOption: any) => {
        setLocalFilterByTimePeriod(selectedOption.value);
        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );

    const handleDateRangeChange = (event: any, picker: any) => {
        const startDateFormatted = moment(picker.startDate).format('MM-DD-YYYY');
        const endDateFormatted = moment(picker.endDate).format('MM-DD-YYYY');

        setLocalFilterByStartDate(startDateFormatted);
        setLocalFilterByEndDate(endDateFormatted);
        setLocalFilterByTimePeriod('')
    };
    const handleCancelDateSelection = (event: any, picker: any) => {
        // Reset the state to empty strings
        setFilterByStartDate("");
        setFilterByEndDate("");
        setLocalFilterByStartDate("");
        setLocalFilterByEndDate("");
    };

   

    const applyFilters = () => {
        setIsApplyingFilters(true);
        setFilterByStartDate(localFilterByStartDate);
        setFilterByEndDate(localFilterByEndDate);
        setFilterByUserIds(localFilterByUserIds)
        setFilterByTimePeriod(localFilterByTimePeriod)
    };
    const resetFilters = () => {
        setFilterByStartDate('');
        setFilterByEndDate('');
        setFilterByUserIds([])
        setFilterByTimePeriod('')

        setLocalFilterByStartDate('');
        setLocalFilterByEndDate('');
        setLocalFilterByUserIds([])
        setLocalFilterByTimePeriod('')

        setSelectedUserIds([])

    }

    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);

    useEffect(() => {
        if (
            searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);
    return (
        <>
            <PageTitle breadcrumbs={[]}>{labelKey.requestLogs}</PageTitle>
            <div className='card' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                <div className='card-header border-0  align-items-center'>
                    <SearchBarComponent
                        className='me-4'
                        placeholder='Request'
                        value={immediateSearchStr}
                        onChange={handleSearchChange}
                    />
                    <FilterMenu
                        onclickApplyFilters={applyFilters}
                        onclickResetFilters={resetFilters}
                        isApplyingFiltersLoading={isApplyingFilters}
                    >
                        <>
                            <div className='mb-5'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <label className='form-label fs-6 fw-bold text-dark'>{labelKey.date}:</label>
                                    <div className='position-relative mb-2'>
                                        <label className='position-absolute text-primary'>Custom Date Range</label>
                                        <DateRangePicker onApply={handleDateRangeChange} onCancel={handleCancelDateSelection}>
                                            <input type="text"
                                                className='h-10px mw-100px opacity-0 cursor-pointer'
                                                value={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : "mm/dd/yyyy"} />
                                        </DateRangePicker>
                                    </div>
                                </div>
                                <Select
                                    // customLabelClick={() => setDateRangeHide(true)}
                                    options={[
                                        // { value: 0, label: 'All' }, // Add the empty option
                                        ...(timePeriodOptions?.map((option: any) => ({
                                            value: option.id,
                                            label: option?.value,
                                        })))
                                    ]}
                                    placeholder={localFilterByStartDate && localFilterByEndDate ? `${localFilterByStartDate} - ${localFilterByEndDate}` : 'Select Date'}
                                    value={localFilterByTimePeriod && localFilterByTimePeriod !== ''
                                        ? {
                                            label: timePeriodOptions?.find((option: any) => option.id === localFilterByTimePeriod)?.value || '',
                                            value: localFilterByTimePeriod,
                                        }
                                        : null
                                    }
                                    onChange={handleTimePeriodChange}
                                />

                            </div>
                            <div className='mb-5'>
                                <label className='form-label fs-6 fw-bold'>{labelKey.users}:</label>
                                <Select
                                    isMulti
                                    options={userIdsOptions}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{ Option: CheckboxOption }}
                                    onChange={(selected) => handleUserIdsChange(selected as Option[])} // Casting to Option[]
                                    value={selectedUserIds} // Directly use selectedDeptId as it is of type Option[]
                                    placeholder='Search User...'
                                    className='filter-valueContainer'
                                />
                            </div>
                        </>
                    </FilterMenu>
                </div>
                <div className='card-body pb-5 pt-0'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('user') && (
                                                <TableHeading
                                                    label={labelKey.user}
                                                    columnId='user'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('exceptionArea') && (
                                                <TableHeading
                                                    label={labelKey.exceptionArea}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='exceptionArea'
                                                />
                                            )}
                                            {hiddenColumns.includes('reqMethodName') && (
                                                <TableHeading
                                                    label={labelKey.reqMethodName}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='reqMethodName'
                                                />
                                            )}
                                            {hiddenColumns.includes('responseCode') && (
                                                <TableHeading
                                                    label={labelKey.responseCode}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='responseCode'
                                                />
                                            )}
                                            {hiddenColumns.includes('date') && (
                                                <TableHeading
                                                    label={labelKey.date}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='date'
                                                />
                                            )}
                                            {hiddenColumns.includes('message') && (
                                                <TableHeading
                                                    label={labelKey.message}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='message'
                                                />
                                            )}

                                            <th className='text-end rounded-end pe-2 border-0'>
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.user}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("user")}
                                                        checked={hiddenColumns.includes('user')}
                                                        fieldClass='mb-4'
                                                    />

                                                    <TextField
                                                        rightLabel={labelKey.exceptionArea}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("exceptionArea")}
                                                        checked={hiddenColumns.includes('exceptionArea')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.reqMethodName}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("reqMethodName")}
                                                        checked={hiddenColumns.includes('reqMethodName')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.responseCode}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("responseCode")}
                                                        checked={hiddenColumns.includes('responseCode')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.date}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("date")}
                                                        checked={hiddenColumns.includes('date')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.message}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("message")}
                                                        checked={hiddenColumns.includes('message')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {/* <td className='ps-5'>{(page - 1) * pageSize + index + 1}</td> */}
                                                                {hiddenColumns.includes('user') && (
                                                                    <td className='ps-5'>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userName.charAt(0)}</div>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.userName}</p>
                                                                                <span className='text-lowercase'>{data?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('exceptionArea') && (
                                                                    <td>{data?.exceptionArea}</td>
                                                                )}
                                                                {hiddenColumns.includes('reqMethodName') && (
                                                                    <td>{data?.reqMethodName}</td>
                                                                )}
                                                                {hiddenColumns.includes('responseCode') && (
                                                                    <td>{data?.responseCode}</td>
                                                                )}
                                                                {hiddenColumns.includes('date') && (
                                                                    <td><DateTimeComp formattedDate={data?.reqDateTime} /> </td>
                                                                )}
                                                                {hiddenColumns.includes('message') && (
                                                                    <td className='w-500px text-wrap'>
                                                                        {data && data.message && <ReactReadMoreReadLess
                                                                            charLimit={60}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {data?.message ? data?.message : "N/A"}
                                                                        </ReactReadMoreReadLess>}
                                                                    </td>
                                                                )}


                                                            </tr>
                                                        ))}
                                                    </>
                                                ) :
                                                    (
                                                        <tr>
                                                            <td colSpan={10}>
                                                                <NoRecordFound />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </>
                                        }
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={10} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                            toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                            ofResult={data?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={data?.result?.totalPages || 0}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestLogs