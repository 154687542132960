import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../../_metronic/helpers';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import moment from 'moment';
import * as Yup from 'yup'
import { useAppSelector } from '../../../../hooks';
import { selectAuth } from '../../../../features/authSlice';
import TextField from '../../../../components/textFields/TextField';
import SelectField from '../../../../components/textFields/SelectField';
import { useAddCreditCardInfoMutation, useGetAllCreditCardCatagoryLookUpQuery, useGetAllCreditCardStatusLookUpQuery, useGetAllCreditCardTypeLookUpQuery, useUpdateCreditCardInfoMutation } from '../../../../services/CreditCardApi';
import Select from 'react-select';

type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddCardModal = ({ show, handleClose, data }: Props) => {
    const { userAccountID } = useAppSelector(selectAuth);
    const [addCard, {data:addData, isLoading, isSuccess, isError, error }] = useAddCreditCardInfoMutation()
    const [updateCard, {data:updateData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateCreditCardInfoMutation()
    const { data: creditCardTypeLookup, refetch: refetchCardTypeLookup } = useGetAllCreditCardTypeLookUpQuery('')
    const { data: creditCardCategoryLookup, refetch: refetchCardCategoryLookup } = useGetAllCreditCardCatagoryLookUpQuery('')
    const { data: creditCardStatusLookup, refetch: refetchCardStatusLookup } = useGetAllCreditCardStatusLookUpQuery('')

    useEffect(() => {
        if (show) {
            refetchCardTypeLookup()
            refetchCardCategoryLookup()
            refetchCardStatusLookup()
        }
    }, [show])

    const initialValues = {
        creditCardId: 0,
        userAccountId: userAccountID,
        cardName: '',
        bankName: '',
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        isDeleted: true,
        createdBy: 0,
        createdDT: '',
        updatedBy: 0,
        updatedDT: '',
        creditCardType: {
            id: 0,
            name: ''
        },
        creditCardCatagory: {
            id: 0,
            name: ''
        },
        creditCardStatus: {
            id: 0,
            name: ''
        }
    }

    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.creditCardId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);
    const validationSchema = Yup.object().shape({
        cardName: Yup.string().required('Card Name is required'),
        // cardNumber: Yup.string()
        //     .required('Card no is required')
        //     .matches(/^x{12}\d{4}[a-zA-Z\d]*$/, 'Last 4 digits are required'),
        cardNumber: Yup.string()
            .required('Card number is required')
            .matches(/^\d{16}$/, 'Card number must be exactly 16 digits'),

        creditCardCatagory: Yup.object().shape({
            id: Yup.number().min(1, 'Ownership is required').required('Ownership is required'),
        }),
        creditCardType: Yup.object().shape({
            id: Yup.number().min(1, 'Card Type is required').required('Card Type is required'),
        }),
        // expiryDate: Yup.string().required('Expiry Date is required'),
        expiryDate: Yup.string().required('Expiry Date is required').matches(
            /^(0[1-9]|1[0-2])\/(20)\d{2}$/,
            'Expiry Date must be in the MM/YYYY format and should be a future date'
        ),
        // .test('expiryDate', 'Expiry Date must be in the future', function (value) {
        //     if (!value) return false; // Let the required validator handle empty values
        //     const [month, year] = value.split('/');
        //     const currentDate = new Date();
        //     const enteredDate = new Date(Number(`20${year}`), Number(month) - 1); // Convert strings to numbers
        //     return enteredDate > currentDate;
        // }),
        creditCardStatus: Yup.object().shape({
            id: Yup.number().min(1, 'Card Status is required').required('Card Status is required'),
        }),

    })
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateCard(values)
            } else {
                addCard(values)
            }
        }
    })
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
          const responseData = (addData as any)?.message;
          const successMessage = responseData;
          toast.success(successMessage);
          handleClose();
          resetForm()
        }
      }, [isSuccess, addData]);
    
      useEffect(() => {
    
        if (updateIsSuccess) {
          const responseData = (updateData as any)?.message;
          const successMessage = responseData;
          toast.success(successMessage);
          handleClose();
        }
      }, [updateIsSuccess, updateData]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateCard}</> : <>{labelKey.addCard}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            cardName: true,
                            cardNumber: true,
                            expiryDate: true,
                            creditCardCatagory: {
                                id: true
                            },
                            creditCardType: {
                                id: true
                            },
                            creditCardStatus: {
                                id: true
                            },
                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.cardName}
                                    required={true}
                                    type='text'
                                    placeholder='Enter card Name'
                                    labelClass={`${formik.touched.cardName && formik.errors.cardName ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('cardName')}
                                    value={formik.values.cardName || ''}
                                />
                                {formik.touched.cardName && formik.errors.cardName && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.cardName}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.ownership}</label>
                                <Select
                                    options={[
                                        { value: '', label: 'Select Ownership' },
                                        ...(creditCardCategoryLookup?.result?.map((ownership: any) => ({
                                            value: ownership.id,
                                            label: ownership.value.trim(),
                                        })) || []),
                                    ]}
                                    className={`${formik.touched.creditCardCatagory?.id && formik.errors.creditCardCatagory?.id ? 'is-invalid' : ''
                                        }`}
                                    placeholder='Select Ownership'
                                    value={
                                        formik.values.creditCardCatagory?.id
                                            ? {
                                                value: formik.values.creditCardCatagory?.id,
                                                label: formik.values.creditCardCatagory?.name || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('creditCardCatagory.id', selectedOption.value);
                                        formik.setFieldValue('creditCardCatagory.name', selectedOption.label);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.creditCardCatagory?.id && formik.errors.creditCardCatagory?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.creditCardCatagory?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 required'>{labelKey.cardno}</label>
                                <ReactInputMask
                                    mask="9999999999999999"
                                    placeholder='xxxx xxxx xxxx xxxx'
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('cardNumber')}
                                    value={formik.values.cardNumber || ''}
                                    className={`form-control form-control-lg form-control-solid default-input ${formik.touched.cardNumber && formik.errors.cardNumber ? 'is-invalid' : ''
                                        }`}
                                />
                                {formik.touched.cardNumber && formik.errors.cardNumber && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.cardNumber}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.cardType}</label>
                                <Select
                                    options={[
                                        { value: '', label: 'Select Card Type' },
                                        ...(creditCardTypeLookup?.result?.map((cardType: any) => ({
                                            value: cardType.id,
                                            label: cardType.value.trim(),
                                        })) || []),
                                    ]}
                                    className={`${formik.touched.creditCardType?.id && formik.errors.creditCardType?.id ? 'is-invalid' : ''
                                        }`}
                                    placeholder='Select Card Type'
                                    value={
                                        formik.values.creditCardType?.id
                                            ? {
                                                value: formik.values.creditCardType?.id,
                                                label: formik.values.creditCardType?.name || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('creditCardType.id', selectedOption.value);
                                        formik.setFieldValue('creditCardType.name', selectedOption.label);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.creditCardType?.id && formik.errors.creditCardType?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.creditCardType?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 required'>{labelKey.validThru}</label>
                                <ReactInputMask
                                    mask="99/9999"
                                    placeholder='MM/YYYY'
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('expiryDate')}
                                    value={formik.values.expiryDate || '__/____'}
                                    className={`form-control form-control-lg form-control-solid default-input ${formik.touched.expiryDate && formik.errors.expiryDate ? 'is-invalid' : ''}`}
                                />
                                {formik.touched.expiryDate && formik.errors.expiryDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.expiryDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 mb-2 required'>{labelKey.cardStatus}</label>
                                <Select
                                    options={[
                                        { value: '', label: 'Select Card Status' },
                                        ...(creditCardStatusLookup?.result?.map((cardStatus: any) => ({
                                            value: cardStatus.id,
                                            label: cardStatus.value.trim(),
                                        })) || []),
                                    ]}
                                    className={`${formik.touched.creditCardStatus?.id && formik.errors.creditCardStatus?.id ? 'is-invalid' : ''
                                        }`}
                                    placeholder='Select Card Status'
                                    value={
                                        formik.values.creditCardStatus?.id
                                            ? {
                                                value: formik.values.creditCardStatus?.id,
                                                label: formik.values.creditCardStatus?.name || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue('creditCardStatus.id', selectedOption.value);
                                        formik.setFieldValue('creditCardStatus.name', selectedOption.label);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.creditCardStatus?.id && formik.errors.creditCardStatus?.id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.creditCardStatus?.id}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}

                            {updateId ?
                                <>
                                    {updateIsLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </> :
                                <>
                                    {isLoading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            {labelKey.pleaseWait}...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </>
                            }


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddCardModal