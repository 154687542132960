import {
    Autocomplete,
    Libraries,
    useJsApiLoader
} from '@react-google-maps/api';
import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaLock, FaTrash, FaUpload } from 'react-icons/fa6';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SelectField from '../../../../../components/textFields/SelectField';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from '../../../../../features/authSlice';
import { useAppSelector } from '../../../../../hooks';
import { useGetRemainingCatagoryBudgetOfUserQuery } from '../../../../../services/BudgetApi';
import { useAllCurrencyLookupQuery, useExpenseCategoryLookupQuery, useGetAllLocationsQuery, useGetAllTripTypeQuery, useGetPaymentMethodLookupQuery } from '../../../../../services/GeneralApi';
import { useGetVendorLookUpQuery } from '../../../../../services/VendorApi';
import { API_END_POINTS } from '../../../../../services/apiEndpoints';
import { baseUrl } from '../../../../../services/baseUrl';
import labelKey from "../../../../localization/label.json";
import AddUpdateVendorModal from '../../../vendorAndCard/vendor/AddUpdateVendorModal';
import BGBlur from '../../../../../components/loadingComponent/BGBlur';
import { KTSVG } from '../../../../../_metronic/helpers';
import SearchSelect from '../../../../../components/textFields/SearchSelect';
import CurrencyComp from '../../../../../components/currencyComponent/CurrencyComp';
import { FaPercentage } from 'react-icons/fa';

type Props = {
    formik: any
    handleImageChangeFirst?: any
    imagePreviewFirst?: any
    fileName: any
    fileIsloading: any
    handleRemoveFile: () => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    orgDetail?: any
    isPdf?: any
    viewOnlyData?: any
}
interface LightboxData {
    imageURL: string;
}
const Step2 = ({ formik, handleImageChangeFirst, imagePreviewFirst, fileName, fileIsloading, handleRemoveFile, fileInputRef, orgDetail, isPdf, viewOnlyData }: Props) => {
    const currentDate = moment().format('YYYY-MM-DD')
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [lightboxData, setLightboxData] = useState<LightboxData>({ imageURL: '' });
    const { roleID, token, locationType, baseURL } = useAppSelector(selectAuth);
    const { data: currency } = useAllCurrencyLookupQuery('')
    const { data: vendor, refetch: vendorRefetch } = useGetVendorLookUpQuery('')
    const { data: getPaymentMethod } = useGetPaymentMethodLookupQuery('')
    const { data: locationsData } = useGetAllLocationsQuery('')
    const { data: tripTypeData, refetch: tripTypeRefetch } = useGetAllTripTypeQuery('')
    const { data: expenseCategory } = useExpenseCategoryLookupQuery({
        roleId: roleID
    })
    const { data: getCategoryBudget, refetch: categoryBudgetRefetch } = useGetRemainingCatagoryBudgetOfUserQuery({
        catagoryId: formik.values?.expenseCategory?.id,
        itemDate: formik.values?.itemDate,
    })

    useEffect(() => {
        if (formik.values.expenseCategory?.id) {
            categoryBudgetRefetch()
        }
    }, [formik.values.expenseCategory?.id])


    const [getMileageByDate, setGetMileageByDate] = useState<any>(null); // Initialize with null
    const [milageRateIsError, setMilageRateIsError] = useState(false); // Initialize error state

    const mileageRefetch = async () => {
        if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                const getExpenseLogResponse = await axios.get(
                    `${baseURL}/api/Expense${API_END_POINTS.getMileagebyDate}?date=${formik.values?.itemDate || ''}`,
                    {
                        headers,
                    }
                );
                const getMileageByDate = getExpenseLogResponse.data;
                setGetMileageByDate(getMileageByDate);
            } catch (error) {
                console.error(`Error fetching data for : `, error);
                setMilageRateIsError(true);
            } finally {
            }
        }
    };
    // const [distanceCal, setDistanceCal] = useState<any>(null); 
    const [distanceError, setDistanceError] = useState(false);
    const [distanceIsLoading, setDistanceIsLoading] = useState(false);
    const [selectedByMouse, setSelectedByMouse] = useState(false);

    // const tripTypeDistanceRefetch = async () => {
    //     if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
    //         setDistanceIsLoading(true)
    //         try {
    //             const headers = {
    //                 Authorization: `Bearer ${token}`,
    //             };;
    //             const getTripDistanceResponse = await axios.get(
    //                 `${baseURL}/api/General${API_END_POINTS.getDistanceByTripType}?source=${formik.values?.startLocation || ''}&destinaiton=${formik.values?.endLocation || ''}&tripTypeid=${formik.values?.tripType.id || ''}`,
    //                 {
    //                     headers,
    //                 }
    //             );
    //             const distanceCal = getTripDistanceResponse.data;
    //             // Use distanceCal instead of distanceInMeters
    //             const distanceInKilometers = +(distanceCal.result / 1000).toFixed(2);
    //             const distanceInMiles = +(distanceCal.result * 0.000621371).toFixed(2);

    //             const ratePerKM = getMileageByDate.result?.ratePerKM;
    //             const ratePerMile = getMileageByDate.result?.ratePerMile;
    //             const distanceResult = ratePerKM ? distanceInKilometers : ratePerMile ? distanceInMiles : 0;
    //             formik.setFieldValue(`milage`, distanceResult);
    //             formik.setFieldValue(`reimbursableDistance`, distanceResult);
    //             setDistanceCal({ ...distanceCal, distanceInKilometers, distanceInMiles, distanceResult });

    //         } catch (error) {
    //             console.error(`Error fetching data: `, error);
    //             setDistanceError(true);

    //             const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
    //             if (errorMessage !== undefined) {
    //                 toast.error(`${errorMessage}`);
    //             }
    //         } finally {
    //             setDistanceIsLoading(false)
    //         }
    //     }
    // };
    const tripTypeDistanceRefetch = async () => {
        if (parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID?.mileage) {
            const { startLocation, endLocation, tripType, itemDate } = formik.values;
            if (!startLocation || !endLocation || !tripType?.id || !itemDate) {
                // toast.error('Please fill in all required fields.');
                return;
            }
            setDistanceIsLoading(true)
            try {
                const headers = {
                    Authorization: `Bearer ${token}`,
                };;
                const getTripDistanceResponse = await axios.get(
                    `${baseURL}/api/General${API_END_POINTS.getDistanceDataByTripType}?source=${formik.values?.startLocation || ''}&destinaiton=${formik.values?.endLocation || ''}&tripTypeid=${formik.values?.tripType.id || ''}&itemDate=${formik.values?.itemDate || ''}`,
                    {
                        headers,
                    }
                );
                const distanceCal = getTripDistanceResponse.data;
                const mileageDistance = (distanceCal?.result?.distance).toFixed(2);
                const reimbursableDistance = (distanceCal?.result?.reimburable).toFixed(2);
                const mileageRate = (distanceCal?.result?.rate).toFixed(2);
                const amount = (distanceCal?.result?.amount).toFixed(2);


                formik.setFieldValue(`milage`, mileageDistance);
                formik.setFieldValue(`reimbursableDistance`, reimbursableDistance);
                formik.setFieldValue(`mileageRate`, mileageRate);
                formik.setFieldValue(`amount`, amount);

                // setDistanceCal({ ...distanceCal, distanceInKilometers, distanceInMiles, distanceResult });

            } catch (error) {
                console.error(`Error fetching data: `, error);
                setDistanceError(true);
                formik.setFieldValue(`milage`, 0);
                formik.setFieldValue(`reimbursableDistance`, 0);
                formik.setFieldValue(`mileageRate`, 0);
                formik.setFieldValue(`amount`, 0);

                const errorMessage = (error as { response?: { data?: { message?: string } } })?.response?.data?.message;
                if (errorMessage !== undefined) {
                    toast.error(`${errorMessage}`);
                }
            } finally {
                setDistanceIsLoading(false)
            }
        }
    };
    // const debounceTimeoutRef = useRef<number | NodeJS.Timeout | null>(null);
    // const debounceDelay = 500; // Set your desired debounce delay in milliseconds
    // const debounceApiCall = () => {
    //     // Clear any existing timeout
    //     if (debounceTimeoutRef.current !== null) {
    //         clearTimeout(debounceTimeoutRef.current as number);
    //     }
    //     // Set a new timeout to make the API call after the debounce delay
    //     debounceTimeoutRef.current = setTimeout(() => {
    //         tripTypeDistanceRefetch();
    //     }, debounceDelay);
    // };

    useEffect(() => {
        if (selectedByMouse) {
            // Call the debounced apiCall function only when selected by mouse
            // debounceApiCall();
            tripTypeDistanceRefetch();
            // Reset the selectedByMouse state for the next interaction
            setSelectedByMouse(false);
        }
    }, [selectedByMouse, formik.values.startLocation, formik.values.endLocation]);


    useEffect(() => {
        if (formik.values?.tripType?.id) {
            tripTypeDistanceRefetch()
        }
    }, [formik.values?.tripType?.id])
    useEffect(() => {
        if (formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID?.mileage || formik.values?.itemDate) {
            mileageRefetch();
        }
    }, [formik.values?.itemDate, formik.values?.expenseCategory?.id])

    const [showAddUpdateVendorModal, setShowAddUpdateVendorModal] = useState(false)
    useEffect(() => {
        if (showAddUpdateVendorModal === false) {
            vendorRefetch()
        }
    }, [showAddUpdateVendorModal])

    const isPdfFile = (filePath: string | undefined) => {
        if (!filePath) return false;
        const fileExtension = filePath.toLowerCase().split('.').pop();
        return fileExtension === 'pdf';
    };
    const openPdfInBrowserOrLightbox = (attachmentFile: string | undefined) => {
        if (attachmentFile) {
            if (isPdfFile(attachmentFile)) {
                // For PDF files, open in the browser
                window.open(attachmentFile, '_blank');
            } else {
                // For image files, open in the Lightbox
                setLightboxData({ imageURL: attachmentFile });
                setLightboxOpen(true);
            }
        }
    };

    // const mileageRate = getMileageByDate?.result?.ratePerKM || getMileageByDate?.result?.ratePerMile || 0;
    // // const mileageAmounts = +(mileageRate * formik.values.reimbursableDistance || 0).toFixed(2);
    // const mileageAmounts = formik.values.reimbursableDistance ? +(mileageRate * formik.values.reimbursableDistance).toFixed(2) : '';

    const libraries: Libraries = useMemo(() => ['places'], []);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: baseUrl.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    // const [distance, setDistance] = useState<number>(0);

    const originRef = useRef<HTMLInputElement>(null);
    const destinationRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, ref: React.RefObject<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (ref.current && ref.current.value !== '') {
                if (ref === originRef) {

                    formik.setFieldValue(`startLocation`, ref.current.value);
                } else if (ref === destinationRef) {
                    formik.setFieldValue(`endLocation`, ref.current.value);
                    // calculateRoute();
                }
            }
        }
    };

    const handleLocationChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        ref: React.RefObject<HTMLInputElement>
    ) => {
        // Update Formik values based on the location type (startLocation or endLocation)
        const updatedLocation = event.target.value;

        if (ref === originRef) {
            formik.setFieldValue(`startLocation`, updatedLocation);
        } else if (ref === destinationRef) {
            formik.setFieldValue(`endLocation`, updatedLocation);
        }
    };
    const originAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const destinationAutocompleteRef = useRef<google.maps.places.Autocomplete>();
    const handleStartPlaceChanged = () => {
        setSelectedByMouse(true);
        // tripTypeDistanceRefetch();
        const place = originAutocompleteRef.current?.getPlace(); // Get the selected place

        if (place && place.geometry && place.geometry.location) {
            const startLocationLat = place.geometry.location.lat();
            const startLocationLng = place.geometry.location.lng();
            formik.setFieldValue(`startLocation`, place?.formatted_address);
            formik.setFieldValue(`startLatLngs.lat`, startLocationLat);
            formik.setFieldValue(`startLatLngs.lng`, startLocationLng);

        }
    };
    const handleEndPlaceChanged = () => {
        setSelectedByMouse(true);
        // tripTypeDistanceRefetch();
        const place = destinationAutocompleteRef.current?.getPlace(); // Get the selected place
        if (place && place.geometry && place.geometry.location) {
            const endLocationLat = place.geometry.location.lat();
            const endLocationLng = place.geometry.location.lng();
            formik.setFieldValue(`endLocation`, place?.formatted_address);
            formik.setFieldValue(`endLatLngs.lat`, endLocationLat);
            formik.setFieldValue(`endLatLngs.lng`, endLocationLng);
        }
    };



    const mileageCol = formik.values?.expenseCategory?.id === constraintConfig.expenseCategoryID.mileage;
    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split('');
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    const handleBlur = (e: any) => {
        const value = e.target.value;
        if (value) {
            const formattedValue = parseFloat(value).toFixed(2);
            formik.setFieldValue('amount', formattedValue);
        }
    };

    // Safeguard totalSplitAmount calculation with better precision handling and fallback values
    // const totalSplitAmount = formik.values.splitLineItems?.reduce((total: number, item: any) => {
    //     const validAmount = parseFloat(item.amount?.toString() || '0');
    //     return total + (isNaN(validAmount) ? 0 : validAmount);
    // }, 0);
    const totalSplitAmount = parseFloat(
        (formik.values.splitLineItems?.reduce((total: number, item: any) => {
          const validAmount = parseFloat(item.amount?.toString() || '0');
          return total + (isNaN(validAmount) ? 0 : validAmount);
        }, 0) || 0).toFixed(2)
      );
      

    // Round the total split amount
    const roundedTotalSplitAmount = Math.round(totalSplitAmount * 100) / 100;

    // Calculate totalAmount from formik and handle fallback
    const totalAmount = parseFloat(formik.values.amount || '0');

    // Calculate remaining amount and round
    const remainingAmount = totalAmount - roundedTotalSplitAmount;
    const roundedRemainingAmount = Math.round(remainingAmount * 100) / 100;

    // Determine class for remaining amount text based on whether it's positive or negative
    const remainingAmountClass = roundedRemainingAmount >= 0 ? 'text-success' : 'text-danger';

    // Format the remaining amount to two decimal places
    const formattedRemainingAmount = roundedRemainingAmount.toFixed(2);


    const handleSplitLocationChange = (selectedOption: any, index: number) => {
        const selectedLocationId = selectedOption?.value;
        const selectedLocationTitle = selectedOption?.label;

        // Check for duplicate locations
        const isDuplicate = formik.values.splitLineItems.some(
            (item: any, i: number) =>
                item.expItemLocation?.id === selectedLocationId && i !== index
        );

        if (isDuplicate) {
            toast.error("This location is already selected. Please choose a different location.");
        } else {
            // Update the form field if no duplicate is found
            formik.setFieldValue(`splitLineItems[${index}].expItemLocation.id`, selectedLocationId || null);
            formik.setFieldValue(`splitLineItems[${index}].expItemLocation.title`, selectedLocationTitle || null);
        }
    };

    // useEffect(() => {
    //     const totalAmount = parseFloat(formik.values.amount || '0');
    //     if (totalAmount > 0) {
    //         formik.values.splitLineItems?.forEach((item: any, index: number) => {
    //             if (item?.percentage !== undefined && item.percentage !== '') {
    //                 // Recalculate amount based on existing percentage
    //                 const newAmount = (parseFloat(item.percentage?.toString()) / 100) * totalAmount;
    //                 formik.setFieldValue(`splitLineItems[${index}].amount`, newAmount.toFixed(3));
    //             } else if (item?.amount !== undefined && item.amount !== '') {
    //                 // Recalculate percentage based on existing amount
    //                 const calculatedPercentage = (parseFloat(item.amount?.toString()) / totalAmount) * 100;
    //                 formik.setFieldValue(`splitLineItems[${index}].percentage`, calculatedPercentage.toFixed(3));
    //             }
    //         });
    //     }
    // }, [formik.values.amount]);

    // Event handler for manual change of split amount
    const handleSplitAmountChange = (index: number, newAmount: number | string) => {
        const totalAmount = parseFloat(formik.values.amount || '0');

        // Update amount
        formik.setFieldValue(`splitLineItems[${index}].amount`, newAmount);

        // Calculate percentage based on the new amount
        const calculatedPercentage = totalAmount ? (parseFloat(newAmount?.toString()) / totalAmount) * 100 : 0;
        formik.setFieldValue(`splitLineItems[${index}].percentage`, calculatedPercentage.toFixed(2));
    };

    // Event handler for manual change of split percentage
    const handleSplitPercentageChange = (index: number, newPercentage: number | string) => {
        const totalAmount = parseFloat(formik.values.amount || '0');

        // Update percentage
        formik.setFieldValue(`splitLineItems[${index}].percentage`, newPercentage);

        // Calculate amount based on the new percentage
        const calculatedAmount = totalAmount ? (parseFloat(newPercentage?.toString()) / 100) * totalAmount : 0;
        formik.setFieldValue(`splitLineItems[${index}].amount`, calculatedAmount.toFixed(2));
    };

    return (
        <>
            <AddUpdateVendorModal show={showAddUpdateVendorModal}
                handleClose={() => setShowAddUpdateVendorModal(false)} />

            {fileIsloading &&
                <BGBlur />
            }
            <div className='current' data-kt-stepper-element='content'>
                <div className="row m-0 px-4">
                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : getCategoryBudget?.result === -1 ? 'col-md-6 col-lg-6' : 'col-md-6 col-lg-4 col-xl-3'}  mb-5`}>
                        <SearchSelect
                            label={labelKey.category}
                            required
                            options={expenseCategory?.result?.map((option: any) => ({
                                label: option?.value,
                                value: option?.id
                            }))}
                            value={
                                formik.values.expenseCategory?.id
                                    ? {
                                        value: formik.values.expenseCategory?.id,
                                        label: formik.values.expenseCategory?.title || '',
                                    }
                                    : null
                            }
                            onChange={(selectedOption: any) => {
                                formik.setFieldValue(`expenseCategory.id`, selectedOption?.value || null);
                                formik.setFieldValue(`expenseCategory.title`, selectedOption?.label || null);
                            }}
                            placeholder='Select Category'
                            disabled={viewOnlyData}
                        />
                    </div>
                    {getCategoryBudget?.result === -1 ? '' :
                        <div className={`${mileageCol ? 'col-md-6 col-lg-4 col-xl-4' : 'col-md-6 col-lg-4 col-xl-3'}`}>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>{labelKey.remainingCategoryBudget} {viewOnlyData === true && <FaLock className='fs-7 ms-2' />}</label>
                            <p className={`fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid  ${viewOnlyData === true ? 'bg-secondary' : null}`} style={{ border: '1px solid rgb(240, 240, 240)' }}>${getCategoryBudget?.result ? getCategoryBudget?.result : "0"}</p>
                        </div>
                    }
                    {parseInt(formik.values?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                        <div className={`${getCategoryBudget?.result === -1 ? 'col-md-6' : 'col-lg-4 col-xl-6 '} mb-5`}>
                            <div className='d-flex align-items-baseline'>
                                <label className={`d-flex align-items-center fs-5 fw-bold mb-2 ${formik.values.amount <= orgDetail?.result?.receiptReqMinAmnt ? null : 'required'}`}>
                                    {labelKey.receiptDocument}
                                </label>
                                {viewOnlyData === true && <FaLock className='fs-7 ms-2 position-relative' />}
                            </div>

                            <div className='d-flex align-items-center gap-2 w-100'>
                                {!fileIsloading &&
                                    // <div className='d-flex align-items-center gap-4 mt-4'>
                                    //     <p className='mb-0 fs-5 text-muted'>{uploadImgMessage}</p>
                                    //     <div className="spinner-border spinner-border-sm" role="status">
                                    //         <span className="sr-only">{labelKey.loading}...</span>
                                    //     </div>
                                    // </div> 
                                    // :
                                    <div className='position-relative w-100 default-input rounded'>
                                        <input
                                            type="file"
                                            id="file"
                                            name="file"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            ref={fileInputRef}
                                            onChange={handleImageChangeFirst}
                                            className='w-100 opacity-0 position-absolute h-40px cursor-pointer'
                                            disabled={viewOnlyData ||
                                                formik.values?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator
                                            }
                                        />
                                        <div className={`form-control-lg form-control-solid form-control ${viewOnlyData === true || formik.values?.debitOrCreditIndicator === constraintConfig.debitOrCreditIndicator.creditIndicator ? 'bg-secondary' : null}`}  >
                                            <div className='d-flex align-items-center gap-2'>
                                                <FaUpload />
                                                {fileName ? (
                                                    <div className='d-flex align-items-center gap-2 w-100'>
                                                        <span className='fw-bold fs-5 mb-0'>{fileName}</span>
                                                        <span className='ms-auto' onClick={handleRemoveFile} style={{ zIndex: 13 }}>
                                                            <FaTrash className='ms-4 text-danger cursor-pointer' />
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <p className='fw-bold fs-5 mb-0'>{labelKey.uploadYourFile}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {formik ?
                                    <>
                                        {formik.values?.attachmentFile && (
                                            <div className="mt-2 cursor-pointer">
                                                <LazyLoadImage
                                                    alt='rcp'
                                                    effect="blur"
                                                    src={formik.values?.attachmentFile.endsWith('.pdf') ? 'media/svg/files/pdf.svg' : formik.values?.attachmentFile}
                                                    style={{ maxWidth: '100%', maxHeight: '40px' }}
                                                    onClick={() => openPdfInBrowserOrLightbox(formik.values?.attachmentFile)}
                                                />
                                            </div>
                                        )}
                                    </> :
                                    <>

                                        {!fileIsloading && <>
                                            {imagePreviewFirst && (
                                                <img
                                                    className='cursor-pointer'
                                                    src={isPdf ? 'media/svg/files/pdf.svg' : imagePreviewFirst}
                                                    alt=''
                                                    style={{ maxWidth: '100%', maxHeight: '40px' }}
                                                    onClick={() => openPdfInBrowserOrLightbox(imagePreviewFirst)}
                                                />
                                            )}</>}
                                    </>}

                                {lightboxOpen && (
                                    <Lightbox
                                        mainSrc={lightboxData.imageURL}
                                        onCloseRequest={() => setLightboxOpen(false)}
                                        imageCaption="Attachment"
                                        enableZoom={true}
                                        imagePadding={50}
                                    />
                                )}
                            </div>
                        </div>
                    }
                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                        <TextField
                            label={labelKey.date}
                            required={true}
                            type='date'
                            max={currentDate}
                            {...(formik.values.itemDate && formik.getFieldProps('itemDate'))}
                            value={formik.values.itemDate ? moment(formik.values.itemDate).format('YYYY-MM-DD') : ''}
                            onChange={(e) => {
                                const selectedDate = e.target.value;
                                const formattedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : null;
                                formik.setFieldValue('itemDate', formattedDate);
                            }}
                            disabled={viewOnlyData ||
                                formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard
                            }
                        />
                    </div>
                    <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                        {/* <SelectField
                            label={labelKey.currency}
                            required={true}
                            {...formik.getFieldProps(`currency.id`)}
                            value={formik?.values?.currency?.id || ''}
                            disabled={viewOnlyData}
                        >
                            <option value="">{labelKey.selectCurrency}</option>
                            {currency?.result.map((option: any, index: any) => (
                                <option key={option.id} value={option.id}>{option?.value}</option>
                            ))}
                        </SelectField> */}
                        <SearchSelect
                            label={labelKey.currency}
                            required={true}
                            value={
                                formik.values.currency?.id
                                    ? {
                                        value: formik.values.currency.id,
                                        label: formik.values.currency.title || 'USD',
                                    }
                                    : null
                            }
                            onChange={(selectedOption: any) => {
                                formik.setFieldValue(`currency.id`, selectedOption.value);
                                formik.setFieldValue(`currency.title`, selectedOption.label);
                            }}
                            options={
                                currency?.result.map((option: any) => ({
                                    value: option.id,
                                    label: option.value,
                                    key: option.id,
                                }))
                            }
                            placeholder={labelKey.selectCurrency}
                            disabled={viewOnlyData || formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                        />

                    </div>
                    <div className='col-md-6 col-lg-4 mb-5'>
                        {/* <SelectField
                            label={labelKey.paymentMethod}
                            required={true}
                            {...formik.getFieldProps(`paymentMethod.id`)}
                            value={formik?.values?.paymentMethod?.id || ''}
                            selectClass={`${parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage && 'bg-secondary'}`}
                            disabled={parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ||
                                parseInt(formik.values?.paymentMethod?.id) === constraintConfig.paymentMethod.corporateCreditCard || viewOnlyData
                            }
                        >
                            <option value="">{labelKey.selectPayment}</option>
                            {parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                                <>
                                    {getPaymentMethod?.result.map((option: any, index: any) => {
                                        if (option.id === 2 || option.id === 3) {
                                            return null; // Skip rendering the option for "Administrator" with id equal to 1
                                        }
                                        return <option key={index} value={option.id}>{option.value}</option>;
                                    })}
                                </>
                                :
                                <>
                                    {getPaymentMethod?.result.map((option: any, index: any) => {
                                        if (formik.values?.paymentMethod?.id !== constraintConfig.paymentMethod.corporateCreditCard) {
                                            if (option.id === 3) {
                                                return null;
                                            }
                                        }

                                        return <option key={option.id} value={option.id}>{option?.value}</option>
                                    })}
                                </>
                            }
                        </SelectField> */}
                        <SearchSelect
                            label={labelKey.paymentMethod}
                            required={true}
                            value={
                                formik.values.paymentMethod?.id
                                    ? {
                                        value: formik.values.paymentMethod.id,
                                        label: formik.values.paymentMethod.title || 'Cash',
                                    }
                                    : null
                            }
                            onChange={(selectedOption: any) => {
                                formik.setFieldValue(`paymentMethod.id`, selectedOption.value);
                                formik.setFieldValue(`paymentMethod.title`, selectedOption.label);
                            }}
                            options={
                                parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage
                                    ? getPaymentMethod?.result
                                        .filter((option: any) => option.id !== 2 && option.id !== 3) // Exclude options with id 2 or 3
                                        .map((option: any) => ({
                                            value: option.id,
                                            label: option.value,
                                            key: option.id,
                                        }))
                                    : getPaymentMethod?.result
                                        .filter((option: any) => formik.values.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard || option.id !== 3) // Exclude option with id 3 unless it's corporate credit card
                                        .map((option: any) => ({
                                            value: option.id,
                                            label: option.value,
                                            key: option.id,
                                        }))
                            }
                            placeholder={labelKey.selectPayment}
                            selectClass={`${parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage && 'bg-secondary'}`}
                            disabled={parseInt(formik.values.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ||
                                parseInt(formik.values.paymentMethod?.id) === constraintConfig.paymentMethod.corporateCreditCard ||
                                viewOnlyData
                            }
                        />

                    </div>
                    {formik.values.isSplitLineItem !== true &&
                        <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6 col-lg-4'}  mb-5`}>
                            <SearchSelect
                                label={labelKey.chargeLocation}
                                required
                                options={(locationsData?.result || []).map((option: any, index: any) => ({
                                    value: option.id,
                                    label: option?.value,
                                    key: option.id,
                                }))}
                                value={
                                    formik.values.expItemLocation?.id
                                        ? {
                                            value: formik.values.expItemLocation?.id,
                                            label: formik.values.expItemLocation?.title || '',
                                        }
                                        : null
                                }
                                onChange={(selectedOption: any) => {
                                    formik.setFieldValue(`expItemLocation.id`, selectedOption?.value || null);
                                    formik.setFieldValue(`expItemLocation.title`, selectedOption?.label || null);
                                }}
                                disabled={viewOnlyData}
                            />

                        </div>
                    }
                    {parseInt(formik.values?.expenseCategory?.id) === constraintConfig.expenseCategoryID.mileage ?
                        <>
                            <div className={`${mileageCol ? 'col-lg-4 col-md-6' : 'col-md-6'} mb-5`}>
                                {/* <SelectField
                                    label={labelKey.tripType}
                                    required={true}
                                    {...formik.getFieldProps(`tripType.id`)}
                                    disabled={viewOnlyData}
                                >
                                    <option value={0}>Select Trip Type</option>
                                    {tripTypeData?.result.map((option: any, index: any) => (
                                        <option key={option.id} value={option.id}>{option?.value}</option>
                                    ))}
                                </SelectField> */}
                                <SearchSelect
                                    label={labelKey.tripType}
                                    required={true}
                                    value={
                                        formik.values.tripType?.id
                                            ? {
                                                value: formik.values.tripType.id,
                                                label: formik.values.tripType.title || 'One Way',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue(`tripType.id`, selectedOption.value);
                                        formik.setFieldValue(`tripType.title`, selectedOption.label);
                                    }}
                                    options={
                                        tripTypeData?.result.map((option: any) => ({
                                            value: option.id,
                                            label: option.value,
                                            key: option.id,
                                        }))
                                    }
                                    placeholder="Select Trip Type"
                                    disabled={viewOnlyData}
                                />

                            </div>
                            {isLoaded && (
                                <>
                                    <div className={`${locationType === 1 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                        <div className="row">
                                            <div className='col-md-6 mb-5'>
                                                <label className='fs-5 fw-bold mb-2 required'>
                                                    {labelKey.startLocation}
                                                </label>
                                                {viewOnlyData === true && <FaLock className='fs-7 ms-1' />}
                                                <Autocomplete
                                                    onLoad={(autocomplete) => {
                                                        originAutocompleteRef.current = autocomplete;
                                                        autocomplete.addListener('place_changed', handleStartPlaceChanged);
                                                    }}
                                                // onPlaceChanged={() => {
                                                //     // calculateRoute();
                                                //     tripTypeDistanceRefetch();
                                                // }}
                                                >
                                                    <input type='text'
                                                        className='form-control form-control-lg form-control-solid default-input'
                                                        placeholder='Origin'
                                                        ref={originRef}
                                                        // onKeyDown={handleKeyDown}
                                                        onKeyDown={(event) => handleKeyDown(event, originRef)}
                                                        onChange={(event) => handleLocationChange(event, originRef)}
                                                        value={formik.values?.startLocation || ''}
                                                        disabled={viewOnlyData}
                                                    />
                                                </Autocomplete>
                                            </div>
                                            <div className='col-md-6'>
                                                <label className='fs-5 fw-bold mb-2 required'>
                                                    {labelKey.endLocation}
                                                </label>
                                                {viewOnlyData === true && <FaLock className='fs-7 ms-1' />}
                                                <div className='d-flex gap-2'>
                                                    <Autocomplete className='w-100'
                                                        onLoad={(autocomplete) => {
                                                            destinationAutocompleteRef.current = autocomplete;
                                                            autocomplete.addListener('place_changed', handleEndPlaceChanged);
                                                        }}
                                                    // onPlaceChanged={() => {
                                                    //     tripTypeDistanceRefetch();
                                                    // }}
                                                    >
                                                        <input
                                                            type='text' className='form-control form-control-lg form-control-solid default-input'
                                                            placeholder='Destination'
                                                            ref={destinationRef}
                                                            // onKeyDown={handleKeyDown}
                                                            onKeyDown={(event) => handleKeyDown(event, destinationRef)}
                                                            onChange={(event) => handleLocationChange(event, destinationRef)}
                                                            value={formik.values?.endLocation || ''}
                                                            disabled={viewOnlyData}
                                                        />
                                                    </Autocomplete>
                                                    {/* <Tooltip id="calculateDistance" place="top" />
                                                    <div className='btn btn-primary' onClick={tripTypeDistanceRefetch}
                                                        data-tooltip-id="calculateDistance" data-tooltip-content="Calculate Distance"
                                                    >
                                                        <i className='fa fa-location-arrow'></i>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className={`${locationType === 2 ? 'd-block' : 'd-none'} col-lg-8 mb-5`}>
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <label className='fs-5 fw-bold mb-2 required'>
                                            {labelKey.startLocation}
                                        </label>
                                        <Select
                                            options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                value: option.id,
                                                label: option?.value,
                                                key: option.id,
                                            }))}
                                            placeholder={
                                                formik.values?.startLocation
                                                    ? formik.values?.startLocation
                                                    : 'Select Origin'
                                            }
                                            value={formik.values?.startLocation}
                                            onChange={(selectedOption: any) => {
                                                setSelectedByMouse(true);
                                                formik.setFieldValue(`startLocation`, selectedOption?.label || '');
                                            }}
                                            isDisabled={viewOnlyData}
                                        />

                                    </div>
                                    <div className='col-lg-6'>
                                        <label className='fs-5 fw-bold mb-2 required'>
                                            {labelKey.endLocation}
                                        </label>
                                        <div className='d-flex gap-2 w-100'>
                                            <Select
                                                options={(locationsData?.result || []).map((option: any, index: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                    key: option.id,
                                                }))}
                                                placeholder={
                                                    formik.values.endLocation
                                                        ? formik.values?.endLocation
                                                        : 'Select Destination'
                                                }
                                                getOptionLabel={(option) => option?.label || ''}
                                                value={formik.values?.endLocation}
                                                onChange={(selectedOption: any) => {
                                                    setSelectedByMouse(true);
                                                    formik.setFieldValue(`endLocation`, selectedOption?.label || '');
                                                }}
                                                className='w-100'
                                                isDisabled={viewOnlyData}

                                            />
                                            {/* <div className='btn btn-primary'
                                                onClick={() => tripTypeDistanceRefetch()}
                                                data-tooltip-id="calculateDistance" data-tooltip-content="Calculate Distance"
                                            >
                                                <i className='fa fa-location-arrow'></i>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-2 col-md-3 mb-5'>
                                <div className='position-relative'>
                                    {distanceIsLoading &&
                                        <div className="spinner-border spinner-border-sm position-absolute me-5" style={{ right: 0, marginTop: '36px' }} role="status">
                                            <span className="sr-only">{labelKey.loading}...</span>
                                        </div>}
                                </div>
                                <TextField
                                    label={labelKey.distance}
                                    required={true}
                                    type='number'
                                    placeholder='Enter Distance'
                                    min={0}
                                    // value={distance.toString() || formik.values.milage}
                                    // value={distanceCal?.distanceResult}
                                    readOnly={true}
                                    {...formik.getFieldProps(`milage`)}
                                    disabled={viewOnlyData}

                                />
                            </div>
                            <div className='col-lg-2 col-md-3 mb-5'>
                                <TextField
                                    label={labelKey.reimbursableDistance}
                                    type='number'
                                    readOnly={true}
                                    {...formik.getFieldProps(`reimbursableDistance`)}
                                    disabled={viewOnlyData}
                                />
                            </div>
                            <div className='col-lg-2 col-md-3 mb-5'>
                                <TextField
                                    label={getMileageByDate?.result?.ratePerKM ? "Rate Per KM" : "Rate Per MI"}
                                    type='text'
                                    // value={getMileageByDate?.result?.ratePerKM ? getMileageByDate?.result?.ratePerKM : getMileageByDate?.result?.ratePerMile}
                                    {...formik.getFieldProps(`mileageRate`)}
                                    value={formik.values.mileageRate || 0}
                                    readOnly={true}
                                    disabled={viewOnlyData}
                                />
                            </div>
                            <div className='col-lg-2 col-md-3 mb-5'>
                                <TextField
                                    label={labelKey.amount}
                                    required={true}
                                    type='number'
                                    {...formik.getFieldProps(`amount`)}
                                    // value={mileageAmounts?.toString() || ''}
                                    readOnly={true}
                                    disabled={viewOnlyData || formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard}
                                />
                            </div>

                        </>
                        :
                        <div className='col-md-6 col-lg-4 mb-5'>
                            <TextField
                                label={labelKey.amount}
                                required={true}
                                type='number'
                                placeholder='Enter Amount'
                                min={0}
                                {...formik.getFieldProps(`amount`)}
                                value={formik.values?.amount || ''}
                                onBlur={handleBlur}
                                disabled={formik.values.recordSourceId === constraintConfig.recordSourceId.BankFeed || viewOnlyData ||
                                    formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard
                                }
                            />
                        </div>
                    }
                    {parseInt(formik.values?.expenseCategory?.id) !== constraintConfig.expenseCategoryID.mileage &&
                        <>
                            <div className='col-md-6 col-lg-4 mb-5'>

                                {/* <div className='d-flex align-items-center justify-content-between'>
                                    <label className='fs-5 fw-bold mb-2 required'>
                                        {labelKey.vendor}
                                    </label>
                                    {viewOnlyData == false &&
                                        <span className='text-primary cursor-pointer'
                                            onClick={() => setShowAddUpdateVendorModal(true)}
                                        >{labelKey.addNew}</span>
                                    }
                                </div> */}
                                <SearchSelect
                                    label={labelKey.vendor}
                                    customLabel={labelKey.addNew}
                                    customLabelClick={() => setShowAddUpdateVendorModal(true)}
                                    customLabelClass={`cursor-pointer ${viewOnlyData || formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard ? 'd-none' : 'd-block'}`}
                                    required
                                    options={vendor?.result?.map((option: any) => ({
                                        label: option?.value,
                                        value: option?.id
                                    }))}
                                    value={
                                        formik.values.vendor?.vendorId
                                            ? {
                                                value: formik.values.vendor?.vendorId,
                                                label: formik.values.vendor?.name || '',
                                            }
                                            : null
                                    }
                                    onChange={(selectedOption: any) => {
                                        formik.setFieldValue(`vendor.vendorId`, selectedOption?.value || null);
                                        formik.setFieldValue(`vendor.name`, selectedOption?.label || null);
                                    }}
                                    placeholder='Select Vendor'
                                    disabled={viewOnlyData ||
                                        (
                                            formik.values?.paymentMethod?.id === constraintConfig.paymentMethod.corporateCreditCard &&
                                            formik.values.vendor?.vendorId !== 1
                                        )
                                    }
                                />
                            </div>
                        </>}




                    <div className='col-md-12'>
                        <div>
                            <TextAreaField
                                label={labelKey.description}
                                placeholder='Enter Expense Description'
                                {...formik.getFieldProps(`itemDetail`)}
                                value={formik?.values?.itemDetail || ''}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    handleTextAreaChange(e);
                                }}
                                disabled={viewOnlyData}
                            />
                            <p className="text-muted text-end mb-0">Characters: ({wordCount}/500)</p>
                        </div>
                    </div>
                    <div className='col-md-12 col-lg-12 col-xl-8 mb-5'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <label className='form-label fs-5 fw-bold'>
                                    Split Amount to Locations
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        role="switch"
                                        id={`flexSwitchCheckChecked`}
                                        {...formik.getFieldProps('isSplitLineItem')}
                                        checked={formik.values.isSplitLineItem}
                                        disabled={viewOnlyData}
                                        onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            formik.setFieldValue(`isSplitLineItem`, isChecked);

                                            if (isChecked) {
                                                const currentSplitLineItems = formik.values?.splitLineItems || [];
                                                if (currentSplitLineItems.length === 1) {
                                                    formik.setFieldValue(`splitLineItems`, [
                                                        ...currentSplitLineItems,
                                                        { splitLineItemId: 0, expItemLocation: { id: 0, title: '' }, amount: '' },
                                                    ]);
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {formik.values.isSplitLineItem === true &&
                                <div className='pe-10'>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <p className='mb-0 fs-4'><CurrencyComp amount={totalSplitAmount.toString()} />  of </p>
                                        <p className='mb-0 fs-4'> <CurrencyComp amount={formik.values.amount} />  </p>
                                    </div>
                                    <p className={`${remainingAmountClass} fs-4 mb-0`}>
                                        <CurrencyComp amount={formattedRemainingAmount} />
                                        &nbsp; {roundedRemainingAmount >= 0 ? 'left' : 'Over'}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-xl-4"></div>
                    {formik.values.isSplitLineItem === true &&
                        <div className='col-md-12 col-lg-12 col-xl-8'>

                            {formik.values?.splitLineItems?.map((item: any, index: any) => (
                                <div key={index} className="row">
                                    <div className='col-md-6 col-lg-6 col-xl-6'>
                                        <div>
                                            <SearchSelect
                                                label={labelKey.splitLocation}
                                                required
                                                labelClass='mt-5'
                                                options={(locationsData?.result || []).map((option: any) => ({
                                                    value: option.id,
                                                    label: option?.value,
                                                    key: option.id,
                                                }))}
                                                value={
                                                    item.expItemLocation?.id
                                                        ? {
                                                            value: item.expItemLocation.id,
                                                            label: item.expItemLocation.title || '',
                                                        }
                                                        : null
                                                }
                                                selectClass='w-100'
                                                // onChange={(selectedOption: any) => {
                                                //     formik.setFieldValue(`splitLineItems[${index}].expItemLocation.id`, selectedOption?.value || null);
                                                //     formik.setFieldValue(`splitLineItems[${index}].expItemLocation.title`, selectedOption?.label || null);
                                                // }}
                                                onChange={(selectedOption: any) => handleSplitLocationChange(selectedOption, index)}

                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <div className='d-flex gap-2 align-items-center w-100'>
                                            <TextField
                                                label={labelKey.splitAmount}
                                                required
                                                labelClass='mt-5'
                                                type='number'
                                                placeholder='Enter Amount'
                                                min={0}
                                                {...formik.getFieldProps(`splitLineItems[${index}].amount`)}
                                                value={formik.values?.splitLineItems[index].amount || ''}
                                                // value={formik.values?.splitLineItems[index]?.amount 
                                                //     ? Number(formik.values.splitLineItems[index].amount).toFixed(2) 
                                                //     : ''
                                                // }                                                
                                                onChange={(e) => handleSplitAmountChange(index, e.target.value)}
                                                disabled={viewOnlyData}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <div className='d-flex gap-2 align-items-center w-100 position-relative'>
                                            <span className='position-absolute mt-13 ps-3 pe-2 h-35px d-flex align-items-center justify-content-center' style={{ borderRight: '1px solid #a1a5b7' }}>
                                                <FaPercentage className='h-20px w-20px text-muted' />
                                            </span>
                                            <TextField
                                                label={labelKey.splitAmountWithPer}
                                                required
                                                labelClass='mt-5'
                                                type='number'
                                                placeholder='Enter Percentage'
                                                fieldClass='ps-15'
                                                min={0}
                                                max={100}
                                                {...formik.getFieldProps(`splitLineItems[${index}].percentage`)}
                                                value={formik.values.splitLineItems[index].percentage || ''}
                                                onChange={(e) => handleSplitPercentageChange(index, e.target.value)}
                                                disabled={viewOnlyData}
                                            />
                                            {viewOnlyData === false &&
                                                <KTSVG path='/media/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-3 text-hover-danger mt-12' svgClassName='svg-danger cursor-pointer'
                                                    onClick={() => {
                                                        const updatedSplitLineItems = formik.values.splitLineItems.filter((_: any, i: any) => i !== index);
                                                        formik.setFieldValue('splitLineItems', updatedSplitLineItems);
                                                        if (updatedSplitLineItems.length === 1) {
                                                            formik.setFieldValue(`isSplitLineItem`, false);
                                                        }
                                                    }}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {viewOnlyData === false &&
                                <div className='d-flex justify-content-end w-100'>
                                    <button
                                        type="button"
                                        className='btn btn-sm text-primary ps-0 pe-10'
                                        onClick={() => {
                                            const currentSplitLineItems = Array.isArray(formik.values.splitLineItems)
                                                ? formik.values.splitLineItems
                                                : []; // Ensure it's an array

                                            formik.setFieldValue('splitLineItems', [
                                                ...currentSplitLineItems,
                                                {
                                                    splitLineItemId: 0,
                                                    expenseItemId: 0,
                                                    expItemLocation: { id: 0, title: '' },
                                                    amount: '',
                                                },
                                            ]);
                                        }}
                                    >
                                        Add More
                                    </button>
                                </div>}

                        </div>}

                </div>
            </div>
        </>
    )
}

export default Step2