import type {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";
import { API_END_POINTS } from './apiEndpoints';
import { baseUrl } from './baseUrl';
import { selectAuth } from '../features/authSlice';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl.MAIN_BASE_URL,
})

type ExtraOptions = {
    headers?: Record<string, string>;
    // Define other optional properties here if needed
};

const dynamicBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    ExtraOptions
> = async (args, api, extraOptions) => {
    const orgBaseUrl = selectAuth(api.getState() as RootState).baseURL;
    // gracefully handle scenarios where data to generate the URL is missing
    if (!orgBaseUrl) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: 'dynamic route not found',
            },
        }
    }

    const urlEnd = typeof args === 'string' ? args : args.url

    // construct a dynamically generated portion of the url
    const adjustedUrl = `${orgBaseUrl}/api/Budget${urlEnd}`
    const adjustedArgs = typeof args === 'string' ? { url: adjustedUrl } : { ...args, url: adjustedUrl };
    // Get the token from localStorage
    const token = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user")!).token
        : null;
    // Set headers if token is available
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    // Merge headers with existing headers
    const mergedHeaders = { ...headers, ...extraOptions?.headers };
    // provide the amended url and other params to the raw base query
    return rawBaseQuery({ ...adjustedArgs, headers: mergedHeaders }, api, extraOptions)

}

export const BudgetApi = createApi({
    reducerPath: 'BudgetApi',
    baseQuery:dynamicBaseQuery,
    // baseQuery: fetchBaseQuery({
    //     baseUrl: baseUrl.BASE_URL + '/api/Budget/',
    //     prepareHeaders: (headers) => {
    //         // Get the token from localStorage
    //         const token = localStorage.getItem("user")
    //             ? JSON.parse(localStorage.getItem("user")!).token
    //             : null;
    //         if (token) {
    //             headers.set('Authorization', `Bearer ${token}`);
    //         }
    //         return headers;
    //     },
    // }),
    endpoints: (builder) => ({
        createBudget: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.createBudget,
                    method: "POST",
                    body,
                }
            },
        }),
        updateUserBudget: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateUserBudget,
                    method: "PUT",
                    body,
                }
            },
        }),
        managerTeamBudget: builder.query({
            query: ({ managerId, pageNumber, pageSize, searchstr }) => {
                return {
                    url: `${API_END_POINTS.managerTeamBudget}?managerId=${managerId}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchstr=${searchstr}`,
                    method: "GET",
                }
            },
        }),
        getBudgetHistory: builder.query({
            query: ({ userAccountId }) => {
                return {
                    url: `${API_END_POINTS.budgetHistory}?userAccountId=${userAccountId}`,
                    method: "GET",
                }
            },
        }),
        getLatestBudget: builder.query({
            query: ({ userAccountId, expenseDate }) => {
                return {
                    url: `${API_END_POINTS.latestBudget}?userAccountId=${userAccountId}&expenseDate=${expenseDate}`,
                    method: "GET",
                }
            },
        }),
        getRemaningBudget: builder.query({
            query: ({ userAccountId, expenseDate }) => {
                return {
                    url: `${API_END_POINTS.remaningBudget}?userAccountId=${userAccountId}&expenseDate=${expenseDate}`,
                    method: "GET",
                }
            },
        }),
        getBudgetSubHistory: builder.query({
            query: ({ budgetId, }) => {
                return {
                    url: `${API_END_POINTS.budgetSubHistory}?budgetId=${budgetId}`,
                    method: "GET",
                }
            },
        }),
        createCatagoryBudgetAllocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.createCatagoryBudgetAllocation,
                    method: "POST",
                    body,
                }
            },
        }),
        updateCatagoryBudgetAllocation: builder.mutation({
            query: (body) => {
                return {
                    url: API_END_POINTS.updateCatagoryBudgetAllocation,
                    method: "PUT",
                    body,
                }
            },
        }),
        getCatagoryBudgetAllocation: builder.query({
            query: ({ managerId, pageNumber, pageSize, searchstr }) => {
                return {
                    url: `${API_END_POINTS.getCatagoryBudgetAllocation}?pageNumber=${pageNumber}&pageSize=${pageSize}&searchstr=${searchstr}`,
                    method: "GET",
                }
            },
        }),
        activeOrInactiveCatagoryBudgetStatus: builder.mutation({
            query: ({ catagoryBudgetId, status }) => {
                return {
                    url: `${API_END_POINTS.activeOrInactiveCatagoryBudgetStatus}?catagoryBudgetId=${catagoryBudgetId}&status=${status}`,
                    method: "PUT",
                }
            },
        }),
        getRemainingCatagoryBudgetOfUser: builder.query({
            query: (params) => {
                return {
                    url: `${API_END_POINTS.getRemainingCatagoryBudgetOfUser}`,
                    method: "GET",
                    params
                }
            },
        }),
    }),
})

export const {
    useCreateBudgetMutation,
    useManagerTeamBudgetQuery,
    useGetBudgetHistoryQuery,
    useGetLatestBudgetQuery,
    useGetRemaningBudgetQuery,
    useGetBudgetSubHistoryQuery,
    useUpdateUserBudgetMutation,
    useCreateCatagoryBudgetAllocationMutation,
    useUpdateCatagoryBudgetAllocationMutation,
    useGetCatagoryBudgetAllocationQuery,
    useActiveOrInactiveCatagoryBudgetStatusMutation,
    useGetRemainingCatagoryBudgetOfUserQuery,
    useLazyGetRemainingCatagoryBudgetOfUserQuery
} = BudgetApi