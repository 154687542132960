import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { KTSVG } from '../../../../_metronic/helpers';
import labelKey from "../../../localization/label.json";
import tosterKey from "../../../localization/toster.json";
import TextField from '../../../../components/textFields/TextField';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import { useCreateVendorMutation, useUpdateVendorMutation } from '../../../../services/VendorApi';


type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const AddUpdateVendorModal = ({ show, handleClose, data }: Props) => {

    const [addVendor, {data:addData, isLoading, isSuccess, isError, error }] = useCreateVendorMutation()
    const [updateVendor, {data:updateData, isLoading: updateIsLoading, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError }] = useUpdateVendorMutation()


    const initialValues = {
        vendorId: 0,
        name: '',
        disc: '',
        address: '',
        active: true,
        email: '',
        phone: '',
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim() // Remove leading and trailing spaces
            .required('Name is required')
            .test(
                'is-not-empty-or-spaces',
                'Name must not be empty or consist of only spaces',
                (value) => {
                    return value?.trim() !== ''; // Check if the trimmed value is not empty
                }
            ),
    });


    const [updateId, setUpdateId] = useState(0);
    const [formValues, setFormValues] = useState(initialValues);
    // Store form values when the modal is closed
    const handleModalClose = () => {
        setFormValues(formik.values);
        handleClose();
    };
    useEffect(() => {
        // Restore form values when the modal reopens
        if (show && !data && Object.keys(formValues).length !== 0) {
            formik.setValues(formValues);
        }
        // Update form values based on data received
        if (data) {
            setUpdateId(data?.vendorId);
            formik.setValues({ ...data });
        } else if (!show) {
            // Reset form values only when closing and it's not an update action
            setUpdateId(0);
            formik.resetForm();
        }
    }, [show, data]);

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (updateId) {
                updateVendor(values)
            } else {
                addVendor(values)
            }
        }
    })
    const { resetForm } = formik;


    useEffect(() => {
        if (isSuccess) {
          const responseData = (addData as any)?.message;
          const successMessage = responseData;
          toast.success(successMessage);
          handleClose();
          resetForm()
        }
      }, [isSuccess, addData]);
    
      useEffect(() => {
    
        if (updateIsSuccess) {
          const responseData = (updateData as any)?.message;
          const successMessage = responseData;
          toast.success(successMessage);
          handleClose();
        }
      }, [updateIsSuccess, updateData]);

    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);
    useEffect(() => {
        if (updateIsError && updateError) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in updateError && updateError.data && typeof updateError.data === 'object' && 'message' in updateError.data) {
                errorMessage = (updateError.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [updateIsError, updateError]);
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleModalClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{updateId ? <>{labelKey.updateVendor}</> : <>{labelKey.addVendor}</>}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        formik.setTouched({
                            name: true,
                        }, true); // Set touched for all fields to trigger validation display
                    }}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">

                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6 required'>{labelKey.name}</label>
                                <input
                                    type='text'
                                    autoComplete='off'
                                    className={`form-control form-control-lg form-control-solid default-input ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''
                                        }`}
                                    placeholder='Enter Name'
                                    {...formik.getFieldProps('name')}
                                    value={formik.values.name || ''}
                                    onKeyPress={(event) => {
                                        const regex = /^[A-Za-z\s]+$/;
                                        const key = event.key;
                                        if (!regex.test(key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className='invalid-feedback'>{formik.errors.name}</div>
                                )}
                            </div>
                            
                            <div className='col-md-6 mb-5'>
                                <label className='form-label fw-bold text-dark fs-6'>{labelKey.phone}</label>
                                <ReactInputMask
                                    mask="(999) 999-9999"
                                    placeholder='Cell Phone'
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('phone')}
                                    value={formik.values.phone || ''}
                                    className="form-control form-control-lg form-control-solid default-input"
                                />
                            </div>
                            <div className='col-md-12 mb-5'>
                                <TextField
                                    label={labelKey.email}
                                    type='email'
                                    placeholder='Enter Email'
                                    {...formik.getFieldProps('email')}
                                    value={formik.values.email || ''}
                                />
                            </div>

                            <div className='col-xl-12 mb-5'>
                                <TextField
                                    label={labelKey.address}
                                    type='text'
                                    placeholder='Enter Address'
                                    {...formik.getFieldProps('address')}
                                    value={formik.values.address || ''}
                                />
                            </div>
                            <div className='col-xl-12 mb-5'>
                                <TextAreaField
                                    label={labelKey.description}
                                    placeholder='Enter Description'
                                    {...formik.getFieldProps('disc')}
                                    value={formik.values.disc || ''}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault(); // Prevent default button behavior
                                formik.handleSubmit(); // Manually handle the form submission
                                formik.setTouched({
                                    name: true,
                                }, true); // Set touched for all fields to trigger validation display
                            }}
                            // type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >
                            {!isLoading && !updateIsLoading && <span className='indicator-label'>{updateId ? <>{labelKey.update}</> : <>{labelKey.submit}</>}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                            {updateIsLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}


                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AddUpdateVendorModal