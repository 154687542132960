import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import DateComp from '../../../components/dateComponent/DateComp'
import Pagination from '../../../components/pagination/Pagination'
import SearchBarComponent from '../../../components/searchBar/SearchBarComponent'
import { constraintConfig } from '../../../constraintConfig'
import { selectAuth } from '../../../features/authSlice'
import { useAppSelector } from '../../../hooks'
import { useDepartmentsLookupQuery, useGetAllDesignationWithoutRoleQuery, useGetAllOrganizationLookUpQuery, useLazyGetPermissionsListQuery } from '../../../services/GeneralApi'
import { useGetOrganizationAllRolesQuery } from '../../../services/OrganizationApi'
import { useGetAllUsersQuery, useLazyGetUserAccountDetailQuery } from '../../../services/authApi'
import labelKey from "../../localization/label.json"
import ImpersonateUserModal from './ImpersonateUserModal'
import UserActiveConfirmation from './UserActiveConfirmation'
import UserDetailModal from './UserDetailModal'
import { UpdateUserModal } from './update-user-stepper/UpdateUserModal'
// import AddUserModal from './AddUserModal'
import { Spinner } from 'react-bootstrap'
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent'
import NoRecordFound from '../../../components/noRecord/NoRecordFound'
import TableHeading from '../../../components/tableHeading/TableHeading'
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu'
import TextField from '../../../components/textFields/TextField'
import useColumnVisibility from '../../customHooks/useColumnVisibility'
import { AddUserModal } from './create-user-stepper/AddUserModal'
import FilterMenu from '../../../components/filterMenu/FilterMenu'
import DateTimeComp from '../../../components/dateComponent/DateTimeComp'
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp'
import { toast } from 'react-toastify'
import { IoIosRepeat } from 'react-icons/io'
import { log } from 'console'


const UserPage = ({ tabChange }: any) => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'expenseUsers' });
    const location = useLocation();
    const { roleID } = useAppSelector(selectAuth);
    const [showAddUserModal, setShowAddUserModal] = useState(false)
    const { data: getAllOrganizationLookUp } = useGetAllOrganizationLookUpQuery('');
    const { data: designationLookUp } = useGetAllDesignationWithoutRoleQuery('');
    const { data: deparmentLookUp } = useDepartmentsLookupQuery('');
    const { data: roleLookUp } = useGetOrganizationAllRolesQuery('');
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [orgId, setOrgId] = useState(0);
    const [filterByDepartment, setFilterByDepartment] = useState('');
    const [filterByDesignation, setFilterByDesignation] = useState('');
    const [filterByRoleId, setFilterByRoleId] = useState("0");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const [isChecked, setIsChecked] = useState(false)

    const [localOrgId, setLocalOrgId] = useState(0);
    const [localFilterByDepartment, setLocalFilterByDepartment] = useState('');
    const [localFilterByDesignation, setLocalFilterByDesignation] = useState('');
    const [localFilterByRoleId, setLocalFilterByRoleId] = useState("0");
    const [isApplyingFilters, setIsApplyingFilters] = useState(false);

    const [impersonateUserModal, setImpersonateUserModal] = useState(false);
    const [impersonateData, setImpersonateData] = useState({});
    const impersonateHandler = () => {
        setImpersonateUserModal((prev) => !prev);
    };


    const [userDetailByIdTrigger, { data: userDetailById }] = useLazyGetUserAccountDetailQuery({})
    const [getPermissionsListTrigger, { data: getPermissionsList }] = useLazyGetPermissionsListQuery({})


    const { data: getAllUsersData,
        isFetching: getAllUsersIsLoading,
        isSuccess,
        refetch } = useGetAllUsersQuery({
            pageNumber: page,
            pageSize: pageSize,
            sortBy: sortBy,
            sortOrder: sortOrder,
            searchStr: searchStr,
            orgId: orgId,
            filterByDepartment: filterByDepartment,
            filterByDesignation: filterByDesignation,
            filterByRoleId: filterByRoleId,
            filterByStatus: filterByStatus
        });
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPage(1); // Reset page number to 1
    };

    const tableRef = useRef(null);
    const [showUserDetailModal, setShowUserDetailModal] = useState(false)
    const [userDetailData, setuserDetailData] = useState({});
    const userDetailHandler = () => {
        setShowUserDetailModal((prev) => !prev);
    };

    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false)
    const [editData, setEditData] = useState({});
    const editUserHandler = () => {
        setShowUpdateUserModal((prev) => !prev);
    };
    const [showConfirmationActiveUserModal, setShowConfirmationActiveUserModal] = useState(false)
    const expenseConfirmationModalHandler = () => {
        setShowConfirmationActiveUserModal((prev) => !prev);
    };

    // pagination
    const handlePageClick = (e: any) => {
        setPage(e.selected + 1);
    };

    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };

    //per page record
    const handlePageRecords = (e: any) => {
        setPage(1); // Reset the page to 1
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state

        delayedSearch(value); // Update debounced search state
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    // const handleOrgChange = (selectedOption: any) => {
    //     setOrgId(selectedOption.value);
    // };
    // const handleDeparmentChange = (selectedOption: any) => {
    //     setFilterByDepartment(selectedOption.value);
    // };
    // const handleDesignationChange = (selectedOption: any) => {
    //     setFilterByDesignation(selectedOption.value);
    // };
    // const handleRoleChange = (selectedOption: any) => {
    //     setFilterByRoleId(selectedOption.value);
    // };
    const handleOrgChange = (selectedOption: any) => {
        setLocalOrgId(selectedOption.value);
    };

    const handleDeparmentChange = (selectedOption: any) => {
        setLocalFilterByDepartment(selectedOption.value);
        // const departmentName = selectedOption.value.split(' - ')[1] || selectedOption.value;
        // setLocalFilterByDepartment(departmentName);
    };

    const handleDesignationChange = (selectedOption: any) => {
        setLocalFilterByDesignation(selectedOption.value);
    };

    const handleRoleChange = (selectedOption: any) => {
        setLocalFilterByRoleId(selectedOption.value);
    };
    const debouncedRefetch = debounce(() => {
        refetch().finally(() => {
            setIsApplyingFilters(false);
        });
    }, 300);
    useEffect(() => {
        if (isApplyingFilters) {
            debouncedRefetch();
        }
    }, [isApplyingFilters]);
    const applyFilters = () => {
        setIsApplyingFilters(true);
        setOrgId(localOrgId);
        setFilterByDepartment(localFilterByDepartment);
        setFilterByDesignation(localFilterByDesignation);
        setFilterByRoleId(localFilterByRoleId);
    };
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);
    useEffect(() => {
        if (tabChange === 'first') {
            refetch();
        }
    }, [tabChange]);
    const [pageTitle, setPageTitle] = useState('User List');
    useEffect(() => {
        // Check the current route and set the selected value accordingly
        if (location.pathname === '/expense-users/managers') {
            setFilterByRoleId('4');
            setPageTitle('All Managers');
        }
        else if (location.pathname === '/active/managers') {
            setFilterByRoleId('4');
            setFilterByStatus('active')
            setPageTitle('Active Manager');
        }
        else if (location.pathname === '/all/expense-users') {
            setFilterByRoleId('5');
            setPageTitle('All Users');
        }
        else if (location.pathname === '/active/expense-users') {
            setFilterByRoleId('5');
            setFilterByStatus('active')
            setPageTitle('Active Users');
        }
        else {
            setFilterByRoleId('0')
            setPageTitle('User List');
        }
    }, [location.pathname]);

    const [prevModalState, setPrevModalState] = useState({
        addUserModal: false,
        updateUserModal: false,
        confirmationActiveUserModal: false,
    });

    // Update previous modal state whenever the modals change
    useEffect(() => {
        setPrevModalState({
            addUserModal: showAddUserModal,
            updateUserModal: showUpdateUserModal,
            confirmationActiveUserModal: showConfirmationActiveUserModal,
        });
    }, [showAddUserModal, showUpdateUserModal, showConfirmationActiveUserModal]);

    // Check for modal closing and trigger refetch
    useEffect(() => {
        if (
            (prevModalState.addUserModal && !showAddUserModal) ||
            (prevModalState.updateUserModal && !showUpdateUserModal) ||
            (prevModalState.confirmationActiveUserModal && !showConfirmationActiveUserModal)
        ) {
            refetch();
        }
    }, [
        showAddUserModal,
        showUpdateUserModal,
        showConfirmationActiveUserModal,
        prevModalState.addUserModal,
        prevModalState.updateUserModal,
        prevModalState.confirmationActiveUserModal,
        refetch,
    ]);

    const resetFilters = () => {
        setOrgId(0)
        // setFilterByRoleId("0")
        setFilterByDepartment("")
        setFilterByDesignation("")
        setLocalOrgId(0);
        setLocalFilterByDepartment('');
        setLocalFilterByDesignation('');
        // setLocalFilterByRoleId("0");
    }

    // Tab state and handler
    const [activeTab, setActiveTab] = useState('0');
    const handleTabChange = (status: string) => {
        setActiveTab(status);
        setFilterByRoleId(status);
        setLocalFilterByRoleId(status);
        setPage(1)
        setPageSize(10)
    };
    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success('Data Refresh successfully!');
                }
            });
    };
    return (
        <>
            <ImpersonateUserModal show={impersonateUserModal} handleClose={() => setImpersonateUserModal(false)} data={impersonateData} />
            <UpdateUserModal show={showUpdateUserModal} handleClose={() => setShowUpdateUserModal(false)}
                // data={editData}
                data={userDetailById?.result} />
            <UserDetailModal show={showUserDetailModal} handleClose={() => setShowUserDetailModal(false)} data={userDetailData} />
            <AddUserModal
                show={showAddUserModal}
                handleClose={() => setShowAddUserModal(false)}
                data={getPermissionsList} />
            <UserActiveConfirmation
                show={showConfirmationActiveUserModal}
                handleClose={() => setShowConfirmationActiveUserModal(false)}
                data={editData}
                isChecked={isChecked} />

            <PageTitle breadcrumbs={[]}>
                {roleID === constraintConfig.roleID.role1 ?
                    "Admin Users List" :
                    pageTitle}
            </PageTitle>

            <div className='card mb-5'>
                <div className='card-header border-0 align-items-center'>
                    <div className='d-flex gap-2 align-items-center'>
                        <div className='btn-group overflow-auto' style={{ minWidth: "max-content", height: 'fit-content' }}>
                            {(roleLookUp?.result || [])
                                .filter((option: any) => roleID === constraintConfig.roleID.role1 || option.id >= 2)
                                .map((options: any) => (
                                    <button
                                        key={options.id}
                                        type='button'
                                        className={`btn btn-sm custom-tabs ${activeTab === options.id ? 'btn-primary' : 'btn-light-primary'}`}
                                        onClick={() => handleTabChange(options.id)}
                                    >
                                        {options.value}
                                    </button>
                                ))}

                            <button
                                type='button'
                                className={`btn btn-sm ${activeTab === '0' ? 'btn-primary' : 'btn-light-primary'}`}
                                onClick={() => handleTabChange('0')}
                            >
                                {labelKey.all}
                            </button>
                        </div>
                        <Tooltip id="refreshData" place="top" />
                        <div
                            data-tooltip-id="refreshData" data-tooltip-content='Refresh Data'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-2' onClick={handleRefresh}>
                            <IoIosRepeat className='h-20px w-20px' />
                        </div>
                    </div>

                    <div className='card-toolbar'>
                        <div className='d-flex justify-content-end align-items-center gap-3' data-kt-user-table-toolbar='base'>
                            <SearchBarComponent
                                placeholder='User'
                                value={immediateSearchStr}
                                onChange={handleSearchChange}
                            />
                            <FilterMenu
                                onclickApplyFilters={applyFilters}
                                onclickResetFilters={resetFilters}
                                isApplyingFiltersLoading={isApplyingFilters}
                            >
                                <>
                                    {(roleID === constraintConfig.roleID.role1) && (
                                        <>
                                            <div className='mb-5'>
                                                <label className='form-label fs-5 fw-bold'>{labelKey.organization}</label>
                                                <Select
                                                    options={[
                                                        ...(getAllOrganizationLookUp?.result || []).map((option: any) => ({
                                                            value: option.id,
                                                            label: option?.value,
                                                        })),
                                                    ]}
                                                    placeholder={
                                                        localOrgId
                                                            ? roleLookUp?.result.find((option: any) => option.id === localOrgId)?.label
                                                            : 'Select Organization'
                                                    }
                                                    value={localOrgId}
                                                    onChange={handleOrgChange}
                                                />
                                            </div>
                                        </>
                                    )}
                                    {/* <div className='mb-5'>
                                        <label className='form-label fs-5 fw-bold'>{labelKey.role}</label>
                                        <Select
                                            options={(roleLookUp?.result || [])
                                                .filter((option: any) => roleID === constraintConfig.roleID.role1 || option.id >= 2)
                                                .map((option: any) => ({
                                                    value: option.id,
                                                    label: option.value,
                                                }))
                                            }
                                            placeholder={
                                                localFilterByRoleId
                                                    ? roleLookUp?.result.find((option: any) => option.id === localFilterByRoleId)?.value
                                                    : 'Select Role'
                                            }
                                            value={localFilterByRoleId}
                                            onChange={handleRoleChange}
                                        />
                                    </div> */}

                                    <div className='mb-5'>
                                        <label className='form-label fs-5 fw-bold'>{labelKey.department}</label>
                                        <Select
                                            options={[
                                                { value: '', label: 'All' }, // Add the empty option
                                                ...(deparmentLookUp?.result || []).map((option: any) => {
                                                    const label = option.value.split(' - ')[1] || option.value;
                                                    return {
                                                        value: option.value,
                                                        label: option.value,
                                                    };
                                                }),
                                            ]}
                                            placeholder={`${localFilterByDepartment ? `${localFilterByDepartment}` : 'Select Department'}`}
                                            value={localFilterByDepartment}
                                            onChange={handleDeparmentChange}
                                        />
                                    </div>
                                    <div className='mb-5'>
                                        <label className='form-label fs-5 fw-bold'>{labelKey.designation}</label>
                                        <Select
                                            options={[
                                                // { value: '', label: 'All' }, // Add the empty option
                                                ...(designationLookUp?.result || []).map((option: any) => ({
                                                    value: option.value,
                                                    label: option?.value,
                                                })),
                                            ]}
                                            placeholder={`${localFilterByDesignation ? `${localFilterByDesignation}` : 'Select Designation'}`}
                                            value={localFilterByDesignation}
                                            onChange={handleDesignationChange}
                                        />
                                    </div>
                                </>
                            </FilterMenu>

                            {/* <DownloadTableExcel
                                filename="users table"
                                sheet="users"
                                currentTableRef={tableRef.current}
                            >
                                <button type='button' className='btn btn-sm btn-light-primary me-3' >
                                    <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
                                    Export
                                </button>
                            </DownloadTableExcel> */}

                            <button type='button' className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setShowAddUserModal(true)
                                    getPermissionsListTrigger({})
                                }}>
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                Add Expense User
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className="col-md-12">
                        <div className='table-responsive table-height'>
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4' ref={tableRef}>
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        {hiddenColumns.includes('sr') && (
                                            <TableHeading
                                                label={labelKey.sr}
                                                columnId='sr'
                                                className='min-w-40px ps-4'
                                            />
                                        )}
                                        {hiddenColumns.includes('firstName') && (
                                            <TableHeading
                                                label={labelKey.name}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='firstName'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('userOrg') && (
                                            <TableHeading
                                                label={labelKey.organization}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='userOrg'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('ssId') && (
                                            <TableHeading
                                                label={labelKey.ssId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='ssId'
                                                className='min-w-120px'
                                            />
                                        )}

                                        {hiddenColumns.includes('employeeId') && (
                                            <TableHeading
                                                label={labelKey.employeeId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='employeeId'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('bcEmployeeId') && (
                                            <TableHeading
                                                label={labelKey.bcEmployeeId}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='bcEmployeeId'
                                                className='min-w-120px'
                                            />
                                        )}

                                        {hiddenColumns.includes('userRole') && (
                                            <TableHeading
                                                label={labelKey.role}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='userRole'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('manager') && (
                                            <TableHeading
                                                label={labelKey.manager}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='manager'
                                            />
                                        )}
                                        {hiddenColumns.includes('mgrApproveMaxLimit') && (
                                            <TableHeading
                                                label={labelKey.managerMaxLimit}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='mgrApproveMaxLimit'
                                            />
                                        )}
                                        {hiddenColumns.includes('location') && (
                                            <TableHeading
                                                label={labelKey.location}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='location'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('department') && (
                                            <TableHeading
                                                label={labelKey.department}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='department'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {/* {hiddenColumns.includes('division') && (
                                            <TableHeading
                                                label={labelKey.division}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='division'
                                                className='min-w-120px'
                                            />
                                        )} */}
                                        {hiddenColumns.includes('designation') && (
                                            <TableHeading
                                                label={labelKey.designation}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='designation'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {/* {hiddenColumns.includes('cellphone') && (
                                            <TableHeading
                                                label={labelKey.cellPhone}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='cellphone'
                                                className='min-w-140px'
                                            />
                                        )} */}
                                        {hiddenColumns.includes('officeLocation') && (
                                            <TableHeading
                                                label={labelKey.officeLocation}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='officeLocation'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('active') && (
                                            <TableHeading
                                                label={labelKey.status}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='active'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('createDT') && (
                                            <TableHeading
                                                label={labelKey.createdDate}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='createDT'
                                                className='min-w-120px'
                                            />
                                        )}
                                        {hiddenColumns.includes('lastLoginDt') && (
                                            <TableHeading
                                                label={labelKey.lastLogin}
                                                sortedColumn={sortedColumn}
                                                sortOrder={sortOrder}
                                                handleSort={handleSort}
                                                columnId='lastLoginDt'
                                                className='min-w-120px'
                                            />
                                        )}
                                        <th className='text-end rounded-end pe-2 border-0'>
                                            {hiddenColumns.includes('actions') && (
                                                <>
                                                    {labelKey.actions}
                                                </>
                                            )}
                                            <TableSettingMenu>
                                                <TextField
                                                    rightLabel={labelKey.sr}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("sr")}
                                                    checked={hiddenColumns.includes('sr')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.name}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("firstName")}
                                                    checked={hiddenColumns.includes('firstName')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.organization}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("userOrg")}
                                                    checked={hiddenColumns.includes('userOrg')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.ssId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("ssId")}
                                                    checked={hiddenColumns.includes('ssId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.employeeId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("employeeId")}
                                                    checked={hiddenColumns.includes('employeeId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.bcEmployeeId}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("bcEmployeeId")}
                                                    checked={hiddenColumns.includes('bcEmployeeId')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.role}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("userRole")}
                                                    checked={hiddenColumns.includes('userRole')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.manager}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("manager")}
                                                    checked={hiddenColumns.includes('manager')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.managerMaxLimit}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("mgrApproveMaxLimit")}
                                                    checked={hiddenColumns.includes('mgrApproveMaxLimit')}
                                                    fieldClass='mb-4'
                                                />
                                                {/* <TextField
                                                    rightLabel={labelKey.location}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("location")}
                                                    checked={hiddenColumns.includes('location')}
                                                    fieldClass='mb-4'
                                                /> */}
                                                <TextField
                                                    rightLabel={labelKey.department}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("department")}
                                                    checked={hiddenColumns.includes('department')}
                                                    fieldClass='mb-4'
                                                />
                                                {/* <TextField
                                                    rightLabel={labelKey.division}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("division")}
                                                    checked={hiddenColumns.includes('division')}
                                                    fieldClass='mb-4'
                                                /> */}
                                                <TextField
                                                    rightLabel={labelKey.designation}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("designation")}
                                                    checked={hiddenColumns.includes('designation')}
                                                    fieldClass='mb-4'
                                                />
                                                {/* <TextField
                                                    rightLabel={labelKey.cellPhone}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("cellphone")}
                                                    checked={hiddenColumns.includes('cellphone')}
                                                    fieldClass='mb-4'
                                                /> */}
                                                <TextField
                                                    rightLabel={labelKey.officeLocation}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("officeLocation")}
                                                    checked={hiddenColumns.includes('officeLocation')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.active}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("active")}
                                                    checked={hiddenColumns.includes('active')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.createdDate}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("createDT")}
                                                    checked={hiddenColumns.includes('createDT')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.lastLogin}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("lastLoginDt")}
                                                    checked={hiddenColumns.includes('lastLoginDt')}
                                                    fieldClass='mb-4'
                                                />
                                                <TextField
                                                    rightLabel={labelKey.actions}
                                                    type="checkbox"
                                                    onChange={() => handleToggleColumn("actions")}
                                                    checked={hiddenColumns.includes('actions')}
                                                    fieldClass='mb-4'
                                                />
                                            </TableSettingMenu>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {!getAllUsersIsLoading &&
                                        <>
                                            {getAllUsersData?.result?.data?.length > 0 ? (
                                                <>
                                                    {getAllUsersData?.result?.data?.map((data: any, index: any) => (
                                                        <tr key={index}>
                                                            {hiddenColumns.includes('sr') && (
                                                                <td className='ps-4'>
                                                                    {(page - 1) * pageSize + index + 1}
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('firstName') && (
                                                                <td>
                                                                    {((roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) ? (
                                                                        <Link to={`/user-profile/expense-items/${data.userAccountID}`}>
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                    {data?.imageUrl ? <img src={data?.imageUrl} alt="" /> :
                                                                                        <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.firstName.charAt(0)}</div>
                                                                                    }
                                                                                </div>
                                                                                <div className="d-flex flex-column">
                                                                                    <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.firstName} {data?.lastName}</p>
                                                                                    <span className='text-lowercase'>{data?.email}</span>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    ) : (
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                                <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.firstName.charAt(0)}</div>
                                                                            </div>
                                                                            <div className="d-flex flex-column">
                                                                                <span className="text-gray-800 fw-bold mb-1 text-capitalize">{data?.firstName} {data?.middleName} {data?.lastName}</span>
                                                                                <span className='text-lowercase'>{data?.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('userOrg') && (
                                                                <td>{data?.userOrg?.userInOrgName ? data?.userOrg?.userInOrgName : "_"}</td>
                                                            )}
                                                            {hiddenColumns.includes('ssId') && (
                                                                <td>{data?.userAccountID}</td>
                                                            )}
                                                            {hiddenColumns.includes('employeeId') && (
                                                                <td>{data?.employeeId}</td>
                                                            )}
                                                            {hiddenColumns.includes('bcEmployeeId') && (
                                                                <td>{data?.bcEmployeeId}</td>
                                                            )}
                                                            {hiddenColumns.includes('userRole') && (
                                                                <td>{data?.userRole?.roleName}</td>
                                                            )}
                                                            {hiddenColumns.includes('manager') && (
                                                                <td>{data?.managerName}</td>
                                                            )}
                                                            {hiddenColumns.includes('mgrApproveMaxLimit') && (
                                                                <td><CurrencyComp amount={data?.mgrApproveMaxLimit} /></td>
                                                            )}
                                                            {hiddenColumns.includes('location') && (
                                                                <td>{data?.location?.value}</td>
                                                            )}
                                                            {hiddenColumns.includes('department') && (
                                                                <td>{data?.department?.title ? data?.department?.title : "N/A"}</td>
                                                            )}
                                                            {/* {hiddenColumns.includes('division') && (
                                                                <td>{data?.division?.title ? data?.division?.title : "N/A"}</td>
                                                            )} */}
                                                            {hiddenColumns.includes('designation') && (
                                                                <td>{data?.designation?.title ? data?.designation?.title : "N/A"}</td>
                                                            )}
                                                            {/* {hiddenColumns.includes('cellphone') && (
                                                                <td>{data?.cellPhone ? data?.cellPhone : "N/A"}</td>
                                                            )} */}
                                                            {hiddenColumns.includes('officeLocation') && (
                                                                <td>{data?.location?.value}</td>
                                                            )}
                                                            {hiddenColumns.includes('active') && (
                                                                <td>
                                                                    <div className='d-flex gap-2'>
                                                                        {data?.active === true ? (
                                                                            <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span>
                                                                        ) : (
                                                                            <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>
                                                                        )}
                                                                        <div className="form-check form-switch">
                                                                            <input
                                                                                className="form-check-input cursor-pointer"
                                                                                type="checkbox"
                                                                                role="switch"
                                                                                id={`flexSwitchCheckChecked-${data?.userAccountID}`}
                                                                                checked={data?.active}
                                                                                onChange={(e) => {
                                                                                    setIsChecked(e.target.checked);
                                                                                    setEditData(data);
                                                                                    expenseConfirmationModalHandler();
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.userAccountID}`}></label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                            {hiddenColumns.includes('createDT') && (
                                                                <td><DateTimeComp formattedDate={data?.createDT} /></td>
                                                            )}
                                                            {hiddenColumns.includes('lastLoginDt') && (
                                                                <td><DateTimeComp formattedDate={data?.lastLoginDt} /></td>
                                                            )}
                                                            {hiddenColumns.includes('actions') && (
                                                                <td>
                                                                    <div className='d-flex align-items-center justify-content-end gap-2' >

                                                                        {roleID === constraintConfig.roleID.role2 &&
                                                                            <>
                                                                                <Tooltip id="impersonate" place="bottom" />
                                                                                <span className="badge badge-light-primary fs-7 fw-semibold"
                                                                                    onClick={() => {
                                                                                        setImpersonateData(data);
                                                                                        impersonateHandler();
                                                                                    }}
                                                                                >{labelKey.impersonateUser}</span>
                                                                            </>}
                                                                        <Tooltip id="update-user" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="update-user" data-tooltip-content='Update User'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                userDetailByIdTrigger({ id: data?.userAccountID })
                                                                                setEditData(data);
                                                                                editUserHandler();
                                                                            }}
                                                                        >
                                                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                        </div>
                                                                        <Tooltip id="user-detail" place="bottom" />
                                                                        <div
                                                                            data-tooltip-id="user-detail" data-tooltip-content='User Details'
                                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                            onClick={() => {
                                                                                setuserDetailData(data?.userAccountID);
                                                                                userDetailHandler();
                                                                            }} >
                                                                            <KTSVG
                                                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                                                className='svg-icon-3'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={15}>
                                                        <NoRecordFound />
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    }

                                    {getAllUsersIsLoading && (
                                        <tr>
                                            <td colSpan={15} className="text-center">
                                                <LoadingComponent />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalResult={getAllUsersData?.result?.totalRecords === 0 ? 0 : ((getAllUsersData?.result?.pageNumber - 1) * getAllUsersData?.result?.pageSize) + 1}
                            toResult={getAllUsersData?.result?.totalRecords === 0 ? 0 : Math.min(getAllUsersData?.result?.pageNumber * getAllUsersData?.result?.pageSize, getAllUsersData?.result?.totalRecords)}
                            ofResult={getAllUsersData?.result?.totalRecords}
                            onChange={handlePageSizeChange}
                            pageSize={pageSize}
                            onPageChange={handlePageClick}
                            pageCount={getAllUsersData?.result?.totalPages || 0}
                            currentPage={page}
                        />
                    </div>
                </div>
            </div>


        </>
    )
}

export default UserPage